<template>
  <div id="office" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
    <div class="office__wall">
      <div class="office__rooms">
        <div
            v-for="index in roomsPerRow"
            :key="index"
            class="office__room"
        >
          <template v-for="category in categories">
            <div class="office__room-interior"
                 @click.self="openEvent(category, null)"
                 :key="category.name"
                 v-if="parseInt(category.position) === index">
              <div class="office__room-interior-content">
                <div class="office__room-interior-header">
                  <span>{{ category.name }}</span>
                  <span v-if="category.meeting_room" style="font-size: 17px">Besprechungszimmer</span>
                </div>
                <div
                    v-for="table in category.tables"
                    :key="table.position"
                    class="office__room-interior-table"
                    @click="openEvent(category, table)"
                >
                  <span class="office__room-interior-table-header">
                    <strong style="font-size: 16px">{{ table.name }}</strong> - {{ table.seats }} {{ parseInt(table.seats) > 1 ? "Plätze" : "Platz" }}
                  </span>
                  <template v-if="filterEvents(category, table).length > 0">
                    <span
                        v-for="event in filterEvents(category, table)"
                        class="office__room-display-events"
                    >{{ userName(event) }}: {{ title(event) }}
                    </span>
                  </template>
                  <template v-else>
                    <span class="office__room-display-events">Frei</span>
                  </template>
                </div>
              </div>
              <div class="office__room-interior-action">
                <v-btn x-small @click.stop="openOverview(category)">{{ checkEvents(category.name) > 4 ? 'Alle Termine' : 'Übersicht' }}</v-btn>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="office__floor">Gang</div>
      <div class="office__rooms">
        <div
            v-for="index in roomsPerRow"
            :key="index"
            class="office__room"
        >
          <template v-for="category in categories">
            <div class="office__room-interior"
                 :key="category.name"
                 @click.self="openEvent(category, null)"
                 v-if="parseInt(category.position) === index + roomsPerRow"
            >
              <div class="office__room-interior-content">
                <div class="office__room-interior-header">
                  <span>{{ category.name }}</span>
                  <span v-if="category.meeting_room" style="font-size: 17px">Besprechungszimmer</span>
                </div>
                <div
                    v-for="table in category.tables"
                    :key="table.position"
                    class="office__room-interior-table"
                    @click="openEvent(category, table)"
                >
                  <span class="office__room-interior-table-header">
                    <strong style="font-size: 16px">{{ table.name }}</strong> - {{ table.seats }} {{ parseInt(table.seats) > 1 ? "Plätze" : "Platz" }}
                  </span>
                  <template v-if="filterEvents(category, table).length > 0">
                    <span
                        v-for="event in filterEvents(category, table)"
                        class="office__room-display-events"
                    >{{ userName(event) }}: {{ title(event) }}
                    </span>
                  </template>
                  <template v-else>
                    <span class="office__room-display-events">Frei</span>
                  </template>
                </div>
              </div>
              <div class="office__room-interior-action">
                <v-btn x-small @click.stop="openOverview(category)">{{ checkEvents(category.name) > 4 ? 'Alle Termine' : 'Übersicht' }}</v-btn>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <RoomOverviewDialog
        :open.sync="openDialog"
        :room="selectedRoom"
        :events="events"
        @editEvent="editEvent"
        @deleteEvent="deleteEvent"
    />
  </div>
</template>

<script>
import RoomOverviewDialog from "@/components/dialogs/booking/RoomOverviewDialog";
import displayEvent from "@/mixins/displayEvent";
export default {
  name: "Office",
  components: {RoomOverviewDialog},
  mixins: [displayEvent],
  data: () => ({
    selectedRoom: {},
    openDialog: false
  }),
  props: {
    categories: Array,
    events: Array,
    location: Object
  },
  computed: {
    roomsPerRow() {
      return parseInt(this.location.rooms_per_row);
    },
  },
  methods: {
    filterEvents(category, table) {
      return this.events.filter(e => e.category === category.name && e.tableid === table.name).slice(0, this.sliceRange(category));
    },
    sliceRange(category) {
      if (category.tables.length >= 3) {
        return 1;
      } else if (category.tables.length === 2) {
        return 2;
      } else {
        return 3;
      }
    },
    openEvent(category, table) {
      const table_id = table ? table.name : "";
      this.$emit("openEvent", { category: category.name, table: table_id });
    },
    checkEvents(category) {
      return this.events.filter(e => e.category === category).length;
    },
    openOverview(category) {
      this.selectedRoom = category;
      this.openDialog = true;
    },
    editEvent(event) {
      this.$emit("editEvent", { event: event});
    },
    deleteEvent(event) {
      this.$emit("deleteEvent", event);
    }
  }
}
</script>

<style lang="scss">
#office {
  display: flex;
  padding: 0.5rem;
  background-color: inherit;
  min-width: 1850px;

  &.theme--light {
    background-color: white;
  }

  .office__wall {
    width: 100%;
    height: 500px;
    border: #e0e0e0 1px solid;
  }
}

.office__rooms {
  height: 225px;
  display: flex;
  flex-direction: row;

  .office__room {
    height: 225px;
    display: flex;
    flex: 1 1 100%;
    cursor: pointer;
    overflow: hidden;

    &:not(:last-child) {
      border-right: #e0e0e0 1px solid;
    }
  }
}

.office__floor {
  height: 50px;
  width: 100%;
  border-top: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
  text-align: center;
  padding-top: 10px;
}

.office__room-interior {
  padding: 0.5rem;
  width: 100%;

  span {
    white-space: pre;
    text-overflow: ellipsis;
  }

  span:after {
    content: "\A";
    white-space: pre;
  }

  .office__room-interior-table {
    padding-top: 0.25rem;

    .office__room-interior-table-header {
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .office__room-interior-content {
    height: 185px;

    .office__room-interior-header {
      font-size: 18px;
      text-align: center;
      font-weight: bolder;

      span {
        display: block;
      }
    }
  }

  .office__room-interior-action {
    display: flex;
    justify-content: center;
    padding-top: 0.25rem
  }
}

.office__room-display-events {
  font-size: 14px;
}
</style>