/*
 * Hat vorerst keine Verwendung mehr
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
};

const getters = {
};

const actions = {
	newLocationCMS({ rootState, dispatch, commit }, street)
	{
		const body = {
			token: rootState.auth.token,
			title: rootState.auth.loggedInUser.city,
			street: street,
		};

		if (rootState["offline/connected"])
		{
			fetch(apiBaseUrl + "/newLocation",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then(error =>
			{
				if (error.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (error.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
				else if (error.status === 200)
				{
					dispatch("request/changeProfile", null, { root: true });
				}
			}).catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Neuer Standort konnten nicht angelegt werden",
					color: "error"
				}, { root: true });
			});
		}
		else
		{
			rootState["offline/fetchQueue_New_Location"] = body;
		}
	},

	deleteLocationCMS: ({ state, rootState, dispatch, commit }, location) =>
	{
		const body = {
			token: rootState.auth.token,
			gsid: location
		};

		if (rootState["offline/connected"])
		{
			fetch(apiBaseUrl + "/deleteLocation",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then(error =>
			{
				if (error.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (error.status === 500)
				{
					rootState.auth.loggedInUser = rootState.auth.bLoggedInUser;
					dispatch('error/error500', null, { root: true });
				}
			}).catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Standort konnten nicht gelöscht werden",
					color: "error"
				}, { root: true });
			});
		}
		else
		{
			rootState["offline/fetchQueue_Delete_Location"] = body;
		}
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
