<template>
  <v-row justify="center" align="center" no-gutters>
    <v-card @keydown.enter="login"
            width="500">
      <v-card-text style="padding-bottom: 0">
        <v-form v-model="valid" lazy-validation>
          <v-text-field
              v-model="user.login"
              label="Login"
              name="login"
              prepend-icon="mdi-account"
              type="text"
              :color="$vuetify.theme.dark ? 'secondary' : ''"
              :error-messages="errorMessages.mail"
          />

          <v-text-field
              v-model="user.password"
              id="password"
              label="Passwort"
              name="password"
              prepend-icon="mdi-lock"
              :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPw ? 'text' : 'password'"
              @click:append="showPw = !showPw"
              :color="$vuetify.theme.dark ? 'secondary' : ''"
              :error-messages="errorMessages.pw"
          />
        </v-form>
      </v-card-text>
      <v-card-actions style="padding: 16px">
        <v-checkbox
            v-model="user.stayLoggedIn"
            label="Angemeldet bleiben"
            color="secondary"
            class="login__info-checkbox"
            hide-details
        ></v-checkbox>
        <v-spacer/>
        <span
            v-if="this.countFails > 0 && this.countFails < 3"
            class="login__info-trials"
        >Noch {{ 3 - this.countFails }} Versuche</span>
        <v-btn @click="login"
               :disabled="!$store.state.offline.connected || loginBlocked"
               color="secondary">{{ loginBlocked && countDown > 0 ? countDown : "Login" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  data()
  {
    return {
      user: {
        login: '',
        password: '',
        stayLoggedIn: false
      },
      showPw: false,
      valid: true,
      countFails: 0,
      errorMessages: {
        mail: [],
        pw: [],
      },
      loginBlocked: false,
      countDown: 0
    }
  },
  methods: {
    login()
    {
      this.loginBlocked = true;
      if (this.user.login.trim() !== '' && this.user.password.trim() !== '')
      {
        const loginInfo = {
          loginAlias: this.user.login,
          pw: this.user.password,
          exp: !this.user.stayLoggedIn,
          mobile: 'ontouchstart' in window
        };

        this.$store.dispatch("auth/login", loginInfo).then(check => {
          if (check === "success") {
            //Reset
            this.valid = true;
            this.errorMessages.mail = [];
            this.errorMessages.pw = [];
            this.user.password = "";
            this.user.login = "";
            this.user.stayLoggedIn = false;
            this.loginBlocked = false;
            this.countFails = 0;

            //Push
            this.$router.push("/");

          } else if (check === "failed") {
            this.valid = false;
            this.errorMessages.mail = "Mail falsch";
            this.errorMessages.pw = "Passwort falsch";
            this.countFails++;

            if (this.countFails > 2) {
              this.block(5 * (this.countFails - 2));
            } else {
              this.loginBlocked = false;
            }
          } else {
            alert("Datenbank oder Server reagiert nicht");
          }
        });
      }
      else
      {
        this.valid = false;
        this.errorMessages.mail = "Mail darf nicht leer sein";
        this.errorMessages.pw = "Passwort darf nicht leer sein";
        this.loginBlocked = false;
      }

    },
    block(errorTime)
    {
      this.countDown = errorTime;

      const countdown = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          clearInterval(countdown);
          this.loginBlocked = false;
        }
      }, 1000);
    },
  }
}
</script>

<style lang="scss" scoped>
.login__info-trials {
  padding-right: 0.5rem;
  color: #cf6679;
}

.login__info-checkbox {
  margin: 0;
  padding: 0;
}
</style>