const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	notifyAllow: JSON.parse(localStorage.getItem("checkedNoti")),
	notifyBday: JSON.parse(localStorage.getItem("checkedGeburtstag")),
	notifyEvent: JSON.parse(localStorage.getItem("checkedEvents")),
	notifyStatus: JSON.parse(localStorage.getItem("checkedStatus")),
	notifyWebPush: JSON.parse(localStorage.getItem("subscribePush")),
	notifyQueue: [],
};

const getters = {
};

const actions = {
	notifyMe: ({ state, commit, rootState }, pUser) =>
	{
		//Benachrichtigung, falls die Bell gestzt wurde
		const status_gsid = rootState.dataManagement.status.find(e => e.id === pUser.status_id[0]).gsid;
		if (state.notifyQueue.some(c => c.id === pUser.id) &&
			(status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.283.de" || status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.285.de"))
		{
			if (Notification.permission === "granted")
			{
				state.notifyQueue.forEach((user) =>
				{
					if (!state.notifyWebPush)
					{
						try
						{
							new Notification("Erreichbar!", {body: user.name + ' ist wieder erreichbar!'});
						} catch (error)
						{
							navigator.serviceWorker.ready.then((reg) =>
							{
								reg.showNotification("Erreichbar!", {body: user.name + ' ist wieder erreichbar!'});
							})
						}
					}

					//User wieder aus Queue löschen
					commit("REMOVE_ELEMENT", user);
				});
			}
		}
	},
	addNotification: ({ rootState, commit }, data) => {
		fetch(apiBaseUrl + "/addNotification",
			{
				method: "POST",
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
			.then(res => {
				if (res.status === 200) {
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Sie werden benachrichtigt, sobald " + data.user.title + " online ist",
						color: "success"
					}, { root: true });
				}
				else {
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Benachrichtung konnte nicht hinzugefügt werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Benachrichtung konnte nicht hinzugefügt werden",
					color: "error"
				}, { root: true });
			});
	}
};

const mutations = {
	REMOVE_ELEMENT(state, payload) {
		const index = state.notifyQueue.findIndex(u => u.id === payload.id);
		state.notifyQueue.splice(index, 1);
	},
	UPDATE_NOTIFICATIONS(state, payload) {
		if (!state.notifyAllow) {
			state.notifyBday = false;
			state.notifyEvent = false;
			state.notifyStatus = false;
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
