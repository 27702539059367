<template>
  <v-dialog v-model="openDialog" :fullscreen="$vuetify.breakpoint.xsOnly" width="450">
    <v-card>
      <v-card-title>
        <span class="headline">Raumübersicht</span>
        <v-spacer />
        <v-btn icon @click="openDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-list class="bookingOverview__list">
          <template v-for="table in room.tables">
            <v-subheader class="bookingOverview__subheader-item" :key="table.position">{{ table.name }}</v-subheader>
            <template v-if="filterEvents(room, table).length > 0">
              <v-list-item
                  v-for="(event, index) in filterEvents(room, table)"
                  :key="event.gsid"
                  class="roomOverview__list-item"
              >
                {{ userName(event) }}: {{ title(event) }}

                <v-tooltip bottom v-if="checkEnd(event)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="margin-left: 1rem"
                        x-small
                        outlined
                        v-on="on"
                    >Bis</v-btn>
                  </template>
                  <span>{{ eventEnd(event) }}</span>
                </v-tooltip>

                <v-row no-gutters justify="end" v-if="event.uid[0] === $store.state.auth.loggedInUser.id">
                  <v-btn icon @click="editEvent(event)">
                    <v-icon
                        small
                        class="booking__sidebar-news-icon"
                    >mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteEvent(event)">
                    <v-icon
                        small
                        class="booking__sidebar-news-icon"
                    >mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item class="roomOverview__list-item">Frei</v-list-item>
            </template>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import displayEvent from "@/mixins/displayEvent";

export default {
  name: "RoomOverviewDialog",
  mixins: [displayEvent],
  props: {
    open: Boolean,
    room: Object,
    events: Array
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    }
  },
  methods: {
    filterEvents(category, table) {
      return this.events.filter(e => e.category === category.name && e.tableid === table.name);
    },
    editEvent(event) {
      this.$emit("editEvent", event)
    },
    deleteEvent(event) {
      this.$emit("deleteEvent", event)
    }
  }
}
</script>

<style lang="scss">
.roomOverview__list-item {
  height: 32px;
}
</style>