//Core
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

//Store
import store from './store'

//Routing
import router from './router'

//Style
import vuetify from './plugins/vuetify';
import {VueMasonryPlugin} from 'vue-masonry';

Vue.config.productionTip = false;

Vue.use(VueMasonryPlugin);

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App),
    created()
    {
        window.addEventListener('offline', () =>
        {
            store.dispatch("offline/toggleConnection", false);
        });

        window.addEventListener('online', () =>
        {
            store.dispatch('offline/toggleConnection', true);
        });
    }
}).$mount('#app');
