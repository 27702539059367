<template>
  <v-dialog v-model="openDialog" :fullscreen="$vuetify.breakpoint.xsOnly" width="850">
    <v-card>
      <v-card-title>
        <span class="headline">Terminübersicht</span>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <h5 class="bookingOverview__header">Räume</h5>
            <v-list class="bookingOverview__list">
              <template v-for="data in sortedEventsRoom">
                <v-subheader class="bookingOverview__subheader-item" :key="data.date">{{ data.date }}</v-subheader>
                <v-list-item
                    v-for="event in data.events"
                    :key="event.gsid"
                    class="bookingOverview__list-item"
                >
                  {{ event.category }}: {{ eventTime(event) }}


                  <v-tooltip bottom v-if="checkEnd(event)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          style="margin-left: 1rem"
                          x-small
                          outlined
                          v-on="on"
                      >Bis</v-btn>
                    </template>
                    <span>{{ eventEnd(event) }}</span>
                  </v-tooltip>

                  <v-row no-gutters justify="end">
                    <v-btn icon @click="editEvent(event)">
                      <v-icon
                          small
                          class="booking__sidebar-news-icon"
                      >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteEvent(event)">
                      <v-icon
                          small
                          class="booking__sidebar-news-icon"
                      >mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col sm="12" md="6">
            <h5 class="bookingOverview__header">Parkplätze</h5>
            <v-list class="bookingOverview__list">
              <template v-for="data in sortedEventsParking">
                <v-subheader class="bookingOverview__subheader-item" :key="data.date">{{ data.date }}</v-subheader>
                <v-list-item
                    v-for="event in data.events"
                    :key="event.gsid"
                    class="bookingOverview__list-item"
                >
                  {{ event.category }}: {{ eventTime(event) }}
                  <v-tooltip bottom v-if="checkEnd(event)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          style="margin-left: 1rem"
                          x-small
                          outlined
                          v-on="on"
                      >Bis</v-btn>
                    </template>
                    <span>{{ eventEnd(event) }}</span>
                  </v-tooltip>
                  <v-row no-gutters justify="end">
                    <v-btn icon @click="editEvent(event)">
                      <v-icon
                          small
                          class="booking__sidebar-news-icon"
                      >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteEvent(event)">
                      <v-icon
                          small
                          class="booking__sidebar-news-icon"
                      >mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BookingOverviewDialog",
  props: {
    events: Array,
    categoriesRoom: Array,
    categoriesParking: Array
  },
  data: () => ({
    openDialog: false
  }),
  computed: {
    fixedCategories() {
      return this.categoriesRoom.map(category => category.name);
    },
    fixedParking() {
      return this.categoriesParking.map(category => category.name);
    },
    sortedEventsRoom() {
      const ev = this.events
          .filter(x => this.fixedCategories.includes(x.category))
          .sort((a, b) => {
            return new Date(a.start) - new Date(b.start);
          });

      const data = ev.reduce((r, e) => {
        const date = new Date(e.start).toLocaleDateString();

        if (!r[date])
        {
          r[date] = { date, events: [e] };
        }
        else
        {
          r[date].events.push(e);
        }

        return r;
      }, {});

      return Object.values(data).reverse();
    },
    sortedEventsParking() {
      const ev = this.events
          .filter(x => this.fixedParking.includes(x.category))
          .sort((a, b) => {
            return new Date(a.start) - new Date(b.start);
          });

      const data = ev.reduce((r, e) => {
        const date = new Date(e.start).toLocaleDateString();

        if (!r[date])
        {
          r[date] = { date, events: [e] };
        }
        else
        {
          r[date].events.push(e);
        }

        return r;
      }, {});

      return Object.values(data).reverse();
    }
  },
  methods: {
    open() {
      this.openDialog = true;
    },
    close() {
      this.openDialog = false;
    },
    editEvent(event) {
      this.$emit("editEvent", { event: event })
    },
    deleteEvent(event) {
      this.$emit("deleteEvent", event)
    },
    eventTime(event) {
      const start = new Date(event.start);
      const startHours = (start.getHours() < 10 ? '0' : '') + start.getHours();
      const startMinutes = (start.getMinutes() < 10 ? '0' : '') + start.getMinutes();

      const end = new Date(event.end);
      const endHours = (end.getHours() < 10 ? '0' : '') + end.getHours();
      const endMinutes = (end.getMinutes() < 10 ? '0' : '') + end.getMinutes();

      return `${startHours}:${startMinutes} Uhr - ${endHours}:${endMinutes} Uhr`
    },
    checkEnd(event) {
      const start = new Date(event.start).toLocaleDateString();
      const end = new Date(event.end).toLocaleDateString();

      return start !== end;
    },
    eventEnd(event) {
      return new Date(event.end).toLocaleDateString();
    }
  }
}
</script>

<style lang="scss">
.bookingOverview__list-item {
  line-height: 30px;
}

.bookingOverview__subheader-item {
  padding-top: 0.5rem;
  line-height: 32px;
}

.bookingOverview__header {
  padding: 0 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 30px;
}

.bookingOverview__list {
  padding-top: 0;
}
</style>
