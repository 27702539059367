<template>
  <div id="booking__calendar">
    <v-calendar
        ref="calendarContent"
        type="category"
        category-show-all
        :categories="fixedCategories"
        category-hide-dynamic
        first-interval="6"
        interval-count="16"
        :events="events"
        :event-color="getEventColor"
        :event-ripple="false"
        :now="today"
        v-model="focusContent"
        @change="updateRange"
        @mousedown:event="editEvent"
        @mousedown:time-category="startTime"
        @mousemove:time-category="mouseMove"
        @mouseup:time-category="endDrag"
        @mouseleave.native="cancelDrag"
    >
      <template v-slot:category="{ category }">
        <div class="v-calendar-category__category booking__content-calendar-header">
          <span style="font-weight: 500">{{ category }}</span>
          <span style="font-size: 12px" v-if="seats(category)">{{ seats(category) }}x {{ seats(category) < 2 ? "Platz" : "Plätze" }}</span>
        </div>
      </template>
      <template v-slot:event="{ event, timed }">
        <div
            class="v-event-draggable"
            :class="event.uid[0] === loggedInUser.id ? 'v-event-actions' : ''"
            v-ripple="{ class: 'v-event-ripple' }"
        >
          <strong>{{ event.title }} - {{ event.tableid }}</strong>
          <v-btn
              v-if="event.uid[0] === loggedInUser.id"
              icon
              @mousedown.native.stop="deleteEvent(event)"
              class="v-event-delete">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <br/>
          {{ eventTimeStart(event) }}
          <br/>
          {{ eventTimeEnd(event) }}
        </div>
        <div
            v-if="timed && event.uid[0] === loggedInUser.id"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
        ></div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import checkEvents from "@/mixins/checkEvents";

export default {
  name: "BookingCalendar",
  mixins: [ checkEvents ],
  props: {
    selectedLocation: Number,
    categories: Array,
    events: Array,
    office: {
      type: Boolean,
      default: () => false
    },
    focus: String
  },
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    backUpEvent: null,
    preventEvent: false
  }),
  computed: {
    //https://github.com/vuetifyjs/vuetify/pull/12518
    fixedCategories() {
      return this.categories.map(category => category.name);
    },
    focusContent: {
      get() {
        return this.focus;
      },
      set(value) {
        this.$emit("update:focus", value)
      }
    },
    locations() {
      return this.$store.getters["dataManagement/locations"];
    },
    loggedInUser() {
      return this.$store.getters["auth/loggedInUser"];
    }
  },
  methods: {
    deleteEvent(event) {
      this.$emit('deleteEvent', event);
    },
    seats(ca) {
      if (this.office) {
        let seats = 0;

        this.categories[this.fixedCategories.indexOf(ca)].tables.forEach(e => {
          seats += parseInt(e.seats);
        });

        return seats;
      }
      return false;
    },
    updateRange ({ start }) {
      this.$emit("setCalendarFocus", start.date);
      this.focusContent = start.date;
    },
    editEvent(event) {
      this.preventEvent = true;
      if (event.event.uid[0] === this.$store.state.auth.loggedInUser.id) {
        this.$emit("editEvent", event);
      }
    },
    startDrag ({ event, timed }) {
      if (event && timed && event.uid[0] === this.loggedInUser.id) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
        this.backUpEvent = JSON.parse(JSON.stringify(event));
      }
    },
    startTime (tms) {
      const mouse = this.toTime(tms);
      if (!this.preventEvent) {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          title: this.loggedInUser.title,
          uid: [this.loggedInUser.id],
          location: [this.locations[this.selectedLocation].id],
          start: this.createStart,
          end: this.createStart,
          timed: true,
          category: tms.category,
        };

        this.$store.commit("dataManagement/ADD_LOCATION_EVENT", {
          id: this.locations[this.selectedLocation].id,
          event: this.createEvent
        });
      }
    },
    extendBottom (event) {
      if (event.uid[0] === this.loggedInUser.id) {
        this.createEvent = event;
        this.createStart = event.start;
        this.extendOriginal = event.end;
        this.backUpEvent = JSON.parse(JSON.stringify(event));
      }
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag () {
      if (this.createEvent) {

        if (this.createEvent.gsid) {
          const updateEvent = {
            gsid: this.createEvent.gsid,
            location_id: this.createEvent.location[0],
            start: this.createEvent.start,
            end: this.createEvent.end,
            category: this.createEvent.category,
            tableid: this.createEvent.tableid
          };

          if (this.checkDuplicated(updateEvent, this.selectedLocation, this.tables.length === 0)) {
            this.$store.dispatch("booking/updateEvent", updateEvent);
          } else {
            this.resetEvent();
          }
        } else {
          this.$store.commit("dataManagement/DELETE_PSEUDO_LOCATION_EVENT", {
            id: this.createEvent.location[0],
            event: this.createEvent
          });

          this.$emit("openEvent", this.createEvent);
        }

      } else if (this.dragEvent) {
        const updateEvent = {
          gsid: this.dragEvent.gsid,
          location_id: this.dragEvent.location[0],
          start: this.dragEvent.start,
          end: this.dragEvent.end,
          category: this.dragEvent.category,
          tableid: this.dragEvent.tableid
        };

        if (this.checkDuplicated(updateEvent, this.selectedLocation, this.tables.length === 0)) {
          this.$store.dispatch("booking/updateEvent", updateEvent);
        } else {
          this.resetEvent();
        }
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.backUpEvent = null;
      this.preventEvent = false;
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.locations[this.selectedLocation].events.indexOf(this.createEvent)
          if (i !== -1) {
            this.locations[this.selectedLocation].events.splice(i, 1)
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
      this.backUpEvent = null;
    },
    resetEvent() {
      const resetEvent = {
        gsid: this.backUpEvent.gsid,
        location_id: this.backUpEvent.location[0],
        start: this.backUpEvent.start,
        end: this.backUpEvent.end,
        category: this.backUpEvent.category,
        tableid: this.backUpEvent.tableid
      };

      this.$store.commit("dataManagement/UPDATE_LOCATION_EVENT", resetEvent);
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor(event) {
      const user = this.$store.getters["dataManagement/users"].find(e => e.id === event.uid[0]);
      if (user) {
        return user.color_id;
      }

      return "#420";
    },
    eventTimeStart(event) {
      const start = new Date(event.start);

      const startHours = (start.getHours() < 10 ? '0' : '') + start.getHours();
      const startMinutes = (start.getMinutes() < 10 ? '0' : '') + start.getMinutes();

      return `${startHours}:${startMinutes} Uhr`
    },
    eventTimeEnd(event) {
      const end = new Date(event.end);

      const endHours = (end.getHours() < 10 ? '0' : '') + end.getHours();
      const endMinutes = (end.getMinutes() < 10 ? '0' : '') + end.getMinutes();

      return `- ${endHours}:${endMinutes} Uhr`
    },
    tables(event) {
      const tables = this.categories.find(e => e.name === event).tables;
      return tables ? tables : [];
    }
  },
}
</script>

<style lang="scss">
#booking__calendar {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 1515px;

  .v-calendar {
    width: 100%;

    &.v-calendar-daily .v-calendar-daily__head {
      margin: 0;

      .v-calendar-daily_head-day .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
        display: none;
      }
    }

    &.v-calendar-daily .v-calendar-daily__body .v-calendar-daily__scroll-area {
      overflow: hidden;
    }
  }
}

.booking__content-calendar-header {
  display: flex;
  flex-direction: column;
}

.v-event-draggable {
  padding: 6px;
  height: 100%;
  cursor: default;

  .v-event-ripple {
    opacity: 0;
  }

  &.v-event-actions {
    cursor: pointer;

    .v-event-ripple {
      opacity: 1;
    }
  }
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-event-delete {
  float: right;
}

//Table fix
.theme--dark.v-calendar-daily .v-calendar-daily__intervals-body {
  border-bottom: 1px solid #9e9e9e;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  border-bottom: 1px solid #e0e0e0;
}
</style>
