const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
};

const getters = {

};

const actions = {
	//Fetch Status des Users oder falls keine Internetverbindung besteht, wird er in eine Queue hinzugefügt
	changeInfo: ({ rootState, dispatch, commit }, user) =>
	{
		const object = {
			token: rootState.auth.token,
			id: user.id,
			gsid: user.gsid,
			pName: user.title,
			status_id: user.status_id,
			status_number: user.status_number,
			dates: user.dates,
			changedBy: {
				name: rootState.auth.loggedInUser.title,
				id: rootState.auth.loggedInUser.id
			},
		};

		fetch(apiBaseUrl + "/status",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			}).then(error =>
		{
			if (error.status === 401)
			{
				dispatch('error/save_retry_request', { url: 'status', data: object }, { root: true });
				dispatch('auth/reLogin', null, { root: true });
			}
		}).catch(error =>
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Status konnten nicht geändert werden",
				color: "error"
			}, { root: true });
		});
	},

	//Fetch Infotext des Users oder falls keine Internetverbindung besteht, wird er in eine Queue hinzugefügt
	changeInfoText: ({ rootState, dispatch, commit }, user) =>
	{
		const object = {
			token: rootState.auth.token,
			id: user.id,
			gsid: user.gsid,
			info: user.info,
			changedBy: {
				name: rootState.auth.loggedInUser.title,
				id: rootState.auth.loggedInUser.id
			},
		};

		if (rootState.offline.connected)
		{
			fetch(apiBaseUrl + "/info",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object)
				})
				.then(error =>
				{
					if (error.status === 401)
					{
						dispatch('error/save_retry_request', { url: 'info', data: object }, { root: true });
						dispatch('auth/reLogin', null, { root: true });
					}
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Infotext konnten nicht geändert werden",
						color: "error"
					}, { root: true });
				});
		}
		else
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Keine Internetverbindung, der Infotext wird aktualisiert sobald eine Verbindung besteht",
				color: "info"
			}, { root: true });

			if (rootState.offline.fetchQueueText.some(user => user.id === object.id))
			{
				const index = rootState.offline.fetchQueueText.findIndex(user => user.id === object.id);
				rootState.offline.fetchQueueText.splice(index, 1);
				rootState.offline.fetchQueueText.push(object);
			}
			else
			{
				rootState.offline.fetchQueueText.push(object);
			}
		}
	},
	changeProfile: ({ rootState, dispatch, commit }, userProfileImage) =>
	{
		let object = {
			token: rootState.auth.token,
			gsid: rootState.auth.loggedInUser.gsid,
			birthday: rootState.auth.loggedInUser.birthday,
			location: rootState.auth.loggedInUser.location,
			slack: rootState.auth.loggedInUser.slack,
			skype: rootState.auth.loggedInUser.skype,
			status_number: rootState.auth.loggedInUser.status_number,
			number_ids: rootState.auth.loggedInUser.number_ids,
			color_id: rootState.auth.loggedInUser.color_id
		};

		if (userProfileImage !== undefined)
		{
			object.image = userProfileImage;
		}

		if (rootState.offline.connected)
		{
			fetch(apiBaseUrl + "/userProfileChanged",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object)
				}).then(error =>
			{
				if (error.status === 401)
				{
					dispatch('error/save_retry_request', { url: 'userProfileChanged', data: object }, { root: true });
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (error.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
				else if (error.status === 200)
				{
					rootState.auth.bLoggedInUser = JSON.parse(JSON.stringify(rootState.auth.loggedInUser));
				}
			}).catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Profil konnten nicht aktualisiert werden",
					color: "error"
				}, { root: true });
			});
		}
		else
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Keine Internetverbindung, das Profil wird aktualisiert sobald eine Verbindung besteht",
				color: "info"
			}, { root: true });

			rootState.offline.fetchQueueProfile = object;
		}
	},
	getImageCMS: ({ rootState, dispatch, commit }, filename) =>
	{
		return new Promise(resolve => {
			const body = {
				token: rootState.auth.token,
				name: filename
			}

			fetch(apiBaseUrl + "/getProfileImage",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				})
				.then(res =>
				{
					if (res.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
					}
					else if (res.status === 200)
					{
						return res.json();
					}
					else
					{
						commit("snackbar/OPEN_SNACKBAR", {
							text: "Profilbild konnten nicht abgerufen werden",
							color: "error"
						}, { root: true });
					}
				})
				.then(data => {
					if (data && data !== "")
					{
						resolve('data:image/jpg;base64,' + data);
					}
					else
					{
						resolve("");
					}
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Profilbild konnten nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});
		});
	},
	setGroupTimer: ({ rootState, dispatch, commit }, data) => {
		fetch(apiBaseUrl + "/setGroupTimer",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(error =>
		{
			if (error.status === 401)
			{
				dispatch('error/save_retry_request', { url: 'setGroupTimer', data: data }, { root: true });
				dispatch('auth/reLogin', null, { root: true });
			}
			else if (error.status === 500)
			{
				dispatch('error/error500', null, { root: true });
			}
		}).catch(error =>
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Abteilungs-Timer konnten nicht gesetzt werden",
				color: "error"
			}, { root: true });
		});
	},
	editPassword: ({ rootState, dispatch, commit }, data) => {
		return new Promise(resolve => {
			fetch(apiBaseUrl + "/editP",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				})
				.then((result) => result.json())
				.then((json) =>
				{
					if (json === "Fehler")
					{
						resolve("wp");
					}
					else if (json === "Ok")
					{
						resolve("success");
					}
					else
					{
						dispatch("error/error500", null, { root: true });
						resolve("failed");
					}
				}).catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Passwort konnten nicht geändert werden",
					color: "error"
				}, { root: true });
				resolve("failed");
			});
		})
	}
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
