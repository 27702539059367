import router from "@/router";
import Vue from "vue";

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	loginAlias: null,
	bLoggedInUser: null,
	loggedInUser: null,
	pImage: "",
	token: localStorage.getItem("Token"),
	status: "initial"
};

const getters = {
	loggedInUser: state => state.loggedInUser,
};

const actions = {
	login: ({ state, dispatch, rootState, commit }, data) =>
	{
		return new Promise(resolve => {
			let log = {};
			if (data) {
				log = { loginAlias: data.loginAlias, pw: data.pw, exp: data.exp, mobile: data.mobile };
			} else {
				log.token = state.token;
			}

			fetch(apiBaseUrl + "/login",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(log)
				})
				.then(res =>
				{
					if (res.status === 200) {
						return res.json();
					} else if (res.status === 404) {
						return 404;
					} else if (res.status === 500) {
						return 500;
					}
				})
				.then(res =>
				{
					if (res === 500) {
						commit("SET_STATUS", "failed");
						resolve("dbf");
					}
					else if (res === 404) {
						commit("SET_STATUS", "noAuth");
						commit("LOGOUT_SUCCESS");
						resolve("failed");
					}
					else {
						//Eingeloggter User
						commit("SET_STATUS", "auth");
						commit("LOGIN_SUCCESS", res[0]);

						//Web Push
						if (rootState.notification.notifyWebPush && rootState.notification.notifyAllow)
						{
							if (rootState.subscribe.webPush_ServerKey_Public === null)
							{
								dispatch('subscribe/getSubscribeKey', null, { root: true }).then((res) =>
								{
									if (res === "done")
									{
										dispatch('subscribe/subscribePush', null, { root: true });
									}
								})
							}
							else
							{
								dispatch('subscribe/subscribePush', null, { root: true });
							}
						}

						//Profilbild
						if (state.loggedInUser.image && state.loggedInUser.image !== "0") {
							dispatch('request/getImageCMS', state.loggedInUser.image.filename, { root: true }).then(image => {
								commit("SET_PROFILE_IMAGE", image);
							});
						}

						//Webhook
						dispatch('webhook/getWebhook', null, { root: true });

						//Social
						dispatch('social/getTeamSlackID', null, { root: true });

						//Rest
						dispatch('stream/setupStream', null, { root: true });
						dispatch('changelog/getChangelog', null, { root: true });
						dispatch('changelog/openChangelog', null, { root: true });
						dispatch('error/retry_request', null, { root: true });
						commit("SET_STATUS", "success");
						resolve("success");
					}
				})
				.catch(error =>
				{
					console.log(error);
					commit("SET_STATUS", "error");
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Der InOut-Server antwortet nicht, bitte kontaktieren Sie ihren Administrator.",
						color: "error"
					}, { root: true });
				});
		})
	},

	logout: ({ state, rootState, dispatch, commit }) => {
		let log = { token: state.token };
		fetch(apiBaseUrl + "/deleteToken",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(log)
			})
			.then(res =>
			{
				if (res.status === 200 || res.status === 401)
				{
					commit("LOGOUT_SUCCESS");
					commit("SET_STATUS", "noAuth");
					dispatch('stream/closeStream', null, { root: true });

					//Übersicht löschen
					rootState.dataManagement.users = [];
					rootState.dataManagement.groups = [];
					rootState.dataManagement.locations = [];
					rootState.offline.fetchQueueText = [];
					rootState.offline.fetchQueueGroup = [];
				}
				else
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Interner Serverfehler - Abmeldung fehlgeschlagen",
						color: "error"
					}, { root: true });
				}
			})
			.catch(error =>
			{
				console.log(error);
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Der InOut-Server antwortet nicht, bitte kontaktieren Sie ihren Administrator.",
					color: "error"
				}, { root: true });
			});
	},

	reLogin: ({ commit }) =>
	{
		commit("LOGOUT_SUCCESS");
	},
};

const mutations = {
	LOGIN_SUCCESS(state, payload) {
		state.token = payload.token;
		localStorage.setItem("Token", state.token);
		delete payload.token;

		state.loggedInUser = payload;
		state.bLoggedInUser = JSON.parse(JSON.stringify(state.loggedInUser));
	},
	LOGOUT_SUCCESS(state, payload) {
		state.loggedInUser = null;
		state.bLoggedInUser = null;
		state.pImage = '';
		state.token = '';
		localStorage.removeItem("Token");
		if (router.currentRoute.path !== "/login") {
			router.push("/login");
		}
	},
	SET_PROFILE_IMAGE(state, payload) {
		state.pImage = payload;
	},
	SET_STATUS(state, payload) {
		state.status = payload
	},
	BACKUP_PROFILE(state, payload) {
		state.loggedInUser = JSON.parse(JSON.stringify(state.bLoggedInUser));
	},
	UPDATE_PROFILE_NUMBERS(state, payload) {
		if (state.loggedInUser.number_ids) {
			state.loggedInUser.number_ids.push(payload);
		} else {
			Vue.set(state.loggedInUser, "number_ids", []);
			state.loggedInUser.number_ids.push(payload);
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
