<template>
  <div id="api">
    <h5 class="api__header">API</h5>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" lg="4">
        <div>
          <v-text-field
              @focusout="apiKeyHint = ''"
              :label="apiDeactivated ? 'Die API ist Serverseitig deaktiviert' : 'Api-Key'"
              single-line
              v-model="apiKey"
              :color="$vuetify.theme.dark ? 'secondary' : ''"
              readonly
              aria-readonly="true"
              id="api-code"
              :disabled="apiDeactivated"
              :hint="apiKeyHint">
            <template v-slot:append v-if="apiKey === ''">
              <v-fade-transition leave-absolute>
                <v-progress-circular
                    v-if="loadingApiKey"
                    size="24"
                    color="secondary"
                    indeterminate
                ></v-progress-circular>
                <v-btn icon @click="getApiKey" v-else
                       :disabled="apiDeactivated">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
            <template v-slot:append v-else>
              <v-btn icon @click="copyApiKey">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="text-right">
            <v-btn class="ma-2" tile outlined
                   :disabled="apiDeactivated"
                   @click="deleteApiKey">
              <v-icon left>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" style="padding-left: 1rem">
        <h4 style="text-decoration: underline">Wie funktioniert die API?</h4>
        <div class="api__info-content">
          <span class="api__info-subHeader">Status/Infotext abrufen:</span>
          <span class="api__info-subHeader-request">GET-Request</span>
          <span>{URL}/api/{Api-Key}/status</span>
          <span>{URL}/api/{Api-Key}/statusText</span>
        </div>
        <div class="api__info-content">
          <span class="api__info-subHeader">Status/Infotext setzen:</span>
          <span class="api__info-subHeader-request">GET-Request</span>
          <span>{URL}/api/{Api-Key}/status/{Status}</span>
          <span>{URL}/api/{Api-Key}/status/{Status}?dates={Daten}</span>
          <span>{URL}/api/{Api-Key}/statusText/{Text}</span>
        </div>
        <div class="api__info-content">
          <span class="api__info-subHeader">Status/Infotext löschen:</span>
          <span class="api__info-subHeader-request">DELETE-Request</span>
          <span>{URL}/api/{Api-Key}/status</span>
          <span>{URL}/api/{Api-Key}/statusText</span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4">
        <h4 style="text-decoration: underline">Formatierung</h4>
        <div class="api__info-content">
          <span class="api__info-subHeader">Status:</span>
          <span>In oder sdevphpweb_sixsixcmsphp73paul.c.283.de</span>
          <span>Out oder sdevphpweb_sixsixcmsphp73paul.c.284.de</span>
          <span>HO oder sdevphpweb_sixsixcmsphp73paul.c.285.de</span>
          <span>Meeting oder sdevphpweb_sixsixcmsphp73paul.c.286.de</span>
          <span>Mittag oder sdevphpweb_sixsixcmsphp73paul.c.287.de</span>
          <span>Urlaub oder sdevphpweb_sixsixcmsphp73paul.c.288.de</span>
          <span>Krank oder sdevphpweb_sixsixcmsphp73paul.c.289.de</span>
          <span>Pause oder sdevphpweb_sixsixcmsphp73paul.c.290.de</span>
          <span>NS oder sdevphpweb_sixsixcmsphp73paul.c.291.de</span>
        </div>
        <div class="api__info-content">
          <span  class="api__info-subHeader">Datum:</span>
          <span>["YYYY-MM-DD", "YYYY-MM-DD"]</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Api",
  data: () => ({
    //Api-Key
    apiKey: '',
    loadingApiKey: false,
    apiKeyHint: '',
    apiDeactivated: false,
  }),
  methods: {
    getApiKey()
    {
      this.loadingApiKey = true;
      this.$store.dispatch("api/getApiKey").then(key =>
      {
        if (!key)
        {
          this.apiKeyHint = 'Ein Fehler ist aufgetreten';
          this.loadingApiKey = false;
        }
        else if (key === "deactivated")
        {
          this.apiKey = '';
          this.apiDeactivated = true;
          this.loadingApiKey = false;
        }
        else
        {
          this.apiKey = key;
          this.loadingApiKey = false;
        }
      });
    },

    deleteApiKey()
    {
      this.$store.dispatch("api/deleteApiKey").then(success =>
      {
        if (success)
        {
          this.apiKey = '';
          this.apiKeyHint = 'ApiKey erfolgreich gelöscht';
        }
        else
        {
          this.apiKeyHint = 'ApiKey konnte nicht gelöscht werden';
        }
      });
    },

    copyApiKey()
    {
      let apiCode = document.querySelector('#api-code');
      apiCode.select();

      try
      {
        document.execCommand('copy');
        this.apiKeyHint = 'In die Zwischenablage kopiert';
      } catch (e)
      {
        this.apiKeyHint = 'Kopieren fehlgeschlagen'
      }
    },
  }
}
</script>

<style lang="scss">
#api {
  padding-top: 1rem;

  .api__header {
    font-weight: 700;
    font-size: 24px;
  }
}

.api__info-content {
  span {
    display: block;
  }
}
.api__info-subHeader {
  font-weight: 600;
  margin-top: 0.5rem;
}

.api__info-subHeader-request {
  font-size: 12px;
}
</style>