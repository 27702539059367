/**
 * Module for Booking
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {

};

const getters = {

};

const actions = {
	getEvents: ({ commit, rootState }, location) => {
		const object = {
			token: rootState.auth.token,
			location: [location.toString()],
		};

		fetch(apiBaseUrl + "/events",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			})
			.then(res =>
			{
				if (res.status === 200)
				{
					return res.json();
				}
				else
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Buchungen konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				}
			})
			.then(data => {

				data.forEach(e => {
					e.start = parseInt(e.start);
					e.end = parseInt(e.end);
				});

				commit("dataManagement/SET_LOCATION_EVENTS", { id: location, events: data }, { root: true })
			})
			.catch(err =>
			{
				console.log(err);
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Buchungen konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});
	},
	setEvent: ({ rootState, commit }, payload) => {
		const object = {
			token: rootState.auth.token,
			event: payload.event
		};

		fetch(apiBaseUrl + "/addEvent",
			{
				method: 'POST',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			})
			.then(res =>
			{
				if (res.status !== 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Buchung konnte nicht durchgeführt werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(err =>
			{
				console.log(err);
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Buchung konnte nicht durchgeführt werden",
					color: "error"
				}, { root: true });
			});
	},
	updateEvent: ({ rootState, commit }, event) => {
		const body = {
			token: rootState.auth.token,
			event: event
		};

		fetch(apiBaseUrl + "/updateEvent",
			{
				method: 'POST',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status !== 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Buchung konnte nicht aktualisiert werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(err =>
			{
				console.log(err);
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Buchung konnte nicht aktualisiert werden",
					color: "error"
				}, { root: true });
			});
	},
	deleteEvent: ({ rootState, commit }, event) => {
		const body = {
			token: rootState.auth.token,
			event: event
		};

		fetch(apiBaseUrl + "/deleteEvent",
			{
				method: 'POST',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Buchung wurde gelöscht",
						color: "success"
					}, { root: true });
				}
				else
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Buchung konnte nicht gelöscht werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(err =>
			{
				console.log(err);
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Buchung konnte nicht gelöscht werden",
					color: "error"
				}, { root: true });
			});
	}
};

const mutations = {

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
