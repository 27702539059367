/**
 * Module for session view
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {

};

const getters = {

};

const actions = {
	getApiKey: ({ rootState, commit }) => {
		return new Promise(resolve => {
			const object = {
				token: rootState.auth.token,
				gsid: rootState.auth.loggedInUser.gsid
			};

			fetch(apiBaseUrl + "/getApiKey",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object)
				})
				.then(res =>
				{
					if (res.status === 200)
					{
						return res.json();
					}
					else if (res.status === 423)
					{
						resolve("deactivated")
					}
					else
					{
						commit("snackbar/OPEN_SNACKBAR", {
							text: "API-Key konnte nicht abgerufen werden",
							color: "error"
						}, { root: true });
						resolve(false);
					}
				})
				.then(json =>
				{
					resolve(json);
				})
				.catch(err =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "API-Key konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
					resolve(false);
				});
		});
	},
	deleteApiKey: ({ rootState, commit }) => {
		return new Promise(resolve => {
			const object = {
				token: rootState.auth.token,
				gsid: rootState.auth.loggedInUser.gsid
			};

			fetch(apiBaseUrl + "/deleteApiKey",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object)
				})
				.then(res =>
				{
					if (res.status === 200)
					{
						resolve(true);
					}
					else
					{
						commit("snackbar/OPEN_SNACKBAR", {
							text: "API-Key konnte nicht gelöscht werden",
							color: "error"
						}, { root: true });
						resolve(false);
					}
				})
				.catch(err =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "API-Key konnte nicht gelöscht werden",
						color: "error"
					}, { root: true });
					resolve(false);
				});
		});
	}
};

const mutations = {

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
