<template>
  <v-dialog v-model="openDialog" max-width="450" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>
        Passwort ändern
      </v-card-title>
      <v-divider/>
      <v-divider/>
      <v-card-text>
        <v-form lazy-validation v-model="validPw" ref="formPw">
          <v-row>
            <v-col cols="12">
              <v-text-field label="Altes Passwort*"
                            v-model="oldP"
                            :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required]"
                            :type="showPw ? 'text' : 'password'"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            @click:append="showPw = !showPw"
                            :error-messages="errorPw"
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
              <v-text-field label="Neues Passwort*"
                            v-model="newP"
                            :append-icon="showPwN ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="showPwN ? 'text' : 'password'"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            @click:append="showPwN = !showPwN"
                            :error-messages="error_new_Pw"
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
              <v-text-field label="Passwort wiederholen*"
                            v-model="newP_re"
                            :append-icon="showPwN_re ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="showPwN_re ? 'text' : 'password'"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            @click:append="showPwN_re = !showPwN_re"
                            :error-messages="error_new_Pw_re"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile outlined text @click="createNewPw" :disabled="!validPw">Speichern</v-btn>
        <v-btn tile outlined text @click="closeDialog">Abbruch</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditPasswordDialog",
  props: {
    open: Boolean
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 5 || 'Min. 5 Zeichen'
    },
    newPwDialog: false,
    newP: '',
    oldP: '',
    showPw: false,
    showPwN: false,
    errorPw: '',
    validPw: false,
    newP_re: '',
    showPwN_re: false,
    error_new_Pw: '',
    error_new_Pw_re: '',
  }),
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    }
  },
  methods: {
    createNewPw()
    {
      if (this.$refs.formPw.validate() && this.newP_re === this.newP)
      {
        const object = {
          gsid: this.$store.state.auth.loggedInUser.gsid,
          token: this.$store.state.auth.token,
          pw: this.oldP,
          password: this.newP
        };

        this.$store.dispatch("request/editPassword", object).then(check =>
        {
          if (check === "success")
          {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              text: "Passwort wurde erfolgreich geändert",
              color: "success"
            });
            this.closeDialog();
          }
          else if (check === "wp")
          {
            this.errorPw = "Falsches Passwort";
            this.$refs.formPw.resetValidation();
          }
          else
          {
            this.errorPw = "Fehler aufgetreten";
          }
        });
      }
      else if (this.newP_re !== this.newP)
      {
        this.error_new_Pw = "Passwörter stimmen nicht überein";
        this.error_new_Pw_re = "Passwörter stimmen nicht überein";
        this.$refs.formPw.resetValidation();
      }
    },
    closeDialog()
    {
      this.$refs.formPw.resetValidation();
      this.errorPw = "";
      this.error_new_Pw = "";
      this.error_new_Pw_re = "";

      this.newP = "";
      this.oldP = "";
      this.newP_re = "";

      this.showPw = false;
      this.showPwN = false;

      this.openDialog = false;
    },
  }
}
</script>

<style scoped>

</style>