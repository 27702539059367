const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	evtSource: null,
};

const getters = {
};

const actions = {
	setupStream({ state, rootState, dispatch, commit })
	{
		try
		{
			state.evtSource = new EventSource(apiBaseUrl + "/stream?token=" + rootState.auth.token);
		} catch (e)
		{
			console.log(e);
		}

		state.evtSource.addEventListener("status", e =>
		{
			const info = JSON.parse(e.data);
			const user = rootState.dataManagement.users[rootState.dataManagement.users.findIndex(c => c.id === info.id || c.gsid === info.gsid)];

			user.status_id = info.status_id;
			user.dates = info.dates;
			user.status_number = info.status_number;

			dispatch("db/saveUser", user, { root: true });
			dispatch("notification/notifyMe", user, { root: true });

			let i = rootState.dataManagement.status.find(e => e.id === user.status_id[0]);
			//Benachrichtigung bei Statusänderung
			if (rootState.notification.notifyStatus
				&& info.changedBy.name !== rootState.auth.loggedInUser.title
				&& user.gsid === rootState.auth.loggedInUser.gsid
				&& rootState.notification.notifyAllow
				&& !rootState.notification.notifyWebPush
				&& Notification.permission === "granted")
			{
				try
				{
					new Notification("Status geändert!", { body: info.changedBy.name + ' hat deinen Status auf ' + i.name + ' gesetzt' });
				} catch (error)
				{
					navigator.serviceWorker.ready.then((reg) =>
					{
						reg.showNotification("Status geändert!", {body: info.changedBy.name + ' hat deinen Status auf ' + i.name + ' gesetzt'});
					})
				}
			}

			if (user.gsid === rootState.auth.loggedInUser.gsid && rootState.protocol.record)
			{
				let p = {info: "Status auf '" + i.name + "' geändert", name: info.changedBy.name};
				dispatch('protocol/addToProtocol', p, { root: true });
			}
		});

		state.evtSource.addEventListener("info", e =>
		{
			const info = JSON.parse(e.data);
			const user = rootState.dataManagement.users[rootState.dataManagement.users.findIndex(c => c.id === info.id)];
			user.info = info.info;
			dispatch("db/saveUser", user, { root: true });

			if (user.gsid === rootState.auth.loggedInUser.gsid && rootState.protocol.record)
			{
				let p;
				if (user.info === "")
				{
					p = {info: "Infotext gelöscht", name: info.changedBy.name}
				}
				else
				{
					p = {info: "Infotext auf '" + user.info + "' geändert", name: info.changedBy.name}
				}

				dispatch('protocol/addToProtocol', p, { root: true });
			}

		});

		state.evtSource.addEventListener("getGroupTimer", e =>
		{
			const info = JSON.parse(e.data);
			const user = rootState.dataManagement.users[rootState.dataManagement.users.findIndex(c => c.id === info.id)];

			user.status_id = info.status_id;
			dispatch("db/saveUser", user, { root: true });
			dispatch("notification/notifyMe", user, { root: true });

			let i = rootState.dataManagement.status.find(e => e.id === user.status_id[0]);
			if (rootState.notification.notifyStatus
				&& info.changedBy.name !== rootState.auth.loggedInUser.title
				&& user.gsid === rootState.auth.loggedInUser.gsid
				&& rootState.notification.notifyAllow
				&& !rootState.notification.notifyWebPush
				&& Notification.permission === "granted")
			{
				try
				{
					new Notification("Status geändert!", {body: info.changedBy.name + ' hat deinen Status auf ' + i.title + ' gesetzt'});
				} catch (error)
				{
					navigator.serviceWorker.ready.then((reg) =>
					{
						reg.showNotification("Status geändert!", {body: info.changedBy.name + ' hat deinen Status auf ' + i.title + ' gesetzt'});
					})
				}
			}

			if (user.gsid === rootState.auth.loggedInUser.gsid && rootState.protocol.record)
			{
				let p = {info: "Status auf '" + i.title + "' geändert", name: info.changedBy.name};
				dispatch('protocol/addToProtocol', p, { root: true });
			}
		});

		state.evtSource.addEventListener("userProfileChanged", e =>
		{
			const info = JSON.parse(e.data);
			const user = rootState.dataManagement.users[rootState.dataManagement.users.findIndex(c => c.gsid === info.gsid)];

			user.slack = info.slack;
			user.skype = info.skype;
			user.image = info.image;
			user.birthday = info.birthday;
			user.location = info.location;
			user.number_ids = info.number_ids;
			user.color_id = info.color_id;

			if (user.gsid === rootState.auth.loggedInUser.gsid)
			{
				rootState.auth.loggedInUser = user;
				rootState.auth.bLoggedInUser = JSON.parse(JSON.stringify(rootState.auth.loggedInUser));
			}

			dispatch("db/saveUser", user, { root: true });
		});

		state.evtSource.addEventListener("newLocation", e =>
		{
			const location = JSON.parse(e.data);
			location.show = true;
			//dispatch("db/saveLocation", location);
			rootState.dataManagement.locations.push(location);
		});

		state.evtSource.addEventListener("deleteLocation", e =>
		{
			const info = JSON.parse(e.data);
			let index = rootState.dataManagement.locations.findIndex(loc => loc.gsid === info.gsid);
			rootState.dataManagement.locations.splice(index, 1);

			//dispatch("db/deleteLocation", info.gsid);
		});

		state.evtSource.addEventListener("addEvent", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/ADD_LOCATION_EVENT",
				{
					id: data.location[0],
					event: data
				}, { root: true });
		});

		state.evtSource.addEventListener("updateEvent", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/UPDATE_LOCATION_EVENT", data, { root: true });
		});

		state.evtSource.addEventListener("deleteEvent", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/DELETE_LOCATION_EVENT",
				{
					id: data.location[0],
					event: data
				}, { root: true });
		});

		state.evtSource.addEventListener("addNumber", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/ADD_NUMBER", data, { root: true });
		});

		state.evtSource.addEventListener("updateNumber", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/UPDATE_NUMBER", data, { root: true });
		});

		state.evtSource.addEventListener("deleteNumber", e =>
		{
			const data = JSON.parse(e.data);

			commit("dataManagement/DELETE_NUMBER", data, { root: true });
		});

		state.evtSource.onopen = (e) =>
		{
			dispatch('dataManagement/getData', null, { root: true });
		}

		state.evtSource.addEventListener('close', function ()
		{
			commit("CLOSE_STREAM");
		}, false);
	},

	closeStream: ( { commit }) =>
	{
		commit("CLOSE_STREAM");
	},
};

const mutations = {
	CLOSE_STREAM(state) {
		state.evtSource.close();
		state.evtSource = null;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
