import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from '@/store/modules/snackbar';
import offline from '@/store/modules/offline';
import location from '@/store/modules/location';
import changelog from '@/store/modules/changelog';
import protocol from '@/store/modules/protocol';
import stream from '@/store/modules/stream';
import session from '@/store/modules/session';
import subscribe from '@/store/modules/subscribe';
import db from "@/store/modules/db";
import dataManagement from "@/store/modules/dataManagement";
import notification from "@/store/modules/notification";
import webhook from "@/store/modules/webhook";
import request from "@/store/modules/request";
import layout from "@/store/modules/layout";
import auth from "@/store/modules/auth";
import social from "@/store/modules/social";
import error from "@/store/modules/error";
import api from "@/store/modules/api";
import booking from "@/store/modules/booking";
import number from "@/store/modules/number";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		offline,
		snackbar,
		location,
		changelog,
		protocol,
		stream,
		session,
		subscribe,
		db,
		dataManagement,
		notification,
		webhook,
		request,
		layout,
		auth,
		social,
		error,
		api,
		booking,
		number
	}
})