<template>
  <div id="dashboard">
    <Sidebar @opened="active = $event">
      <SideGroup :active="active" />
    </Sidebar>
    <Home />
  </div>
</template>

<script>
import Home from "@/components/templates/inout/Home"
import SideGroup from "@/components/templates/inout/SidebarInOut";
import Sidebar from "@/components/layout/Sidebar";

export default {
  name: "Dashboard",
  data: () => ({
    active: false
  }),
  components: {
    Sidebar,
    Home,
    SideGroup
  }
}
</script>

<style>
#dashboard {
  width: 100%;
  min-height: 100%;
}
</style>