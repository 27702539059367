<template>
  <div id="inout__sidebar" :style="style">
    <div id="inout__sidebar_content">
      <v-card
          no-body
          min-width="352"
          class="inout__sidebar-card"
      >
        <v-list subheader style="padding: 0 8px 8px">
          <v-subheader
              role="listitem"
              class="inout__sidebar-list-header"
          >Filter</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group :value="$store.state.layout.show">
            <v-list-item
                v-for="item in filter"
                :key="item.number"
                @click="changeDash(item.number)"
                role="listitem"
                class="inout__sidebar-list-item inout__sidebar-list-item-filter"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
          v-if="$store.state.layout.showUrlaub"
          no-body
          min-width="352"
          class="inout__sidebar-card"
      >
        <v-list subheader rounded disabled>
          <v-subheader
              role="listitem"
              class="inout__sidebar-list-header"
          >Urlaub ({{ filterVacation.length }})</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item
                v-for="user in filterVacation"
                :key="user.gsid"
                role="listitem"
                class="inout__sidebar-list-item"
            >
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col cols="5" v-if="!$store.state.layout.showFullNames">
                    <span class="sidebarInOut__info-name">{{ user.title }}</span>
                  </v-col>
                  <v-col cols="5" v-else>
                    <span class="sidebarInOut__info-name">{{ user.name }} {{ user.surname }}</span>
                  </v-col>
                  <v-col cols="3">{{ user.dates[0].split('-').reverse().join('.') }}</v-col>
                  <v-col cols="1"><b style="padding-left: 0.25rem">&rarr;</b></v-col>
                  <v-col cols="3">{{ user.dates[1].split('-').reverse().join('.') }}</v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
          v-if="$store.state.layout.showKrank"
          no-body
          min-width="352"
          class="inout__sidebar-card"
      >
        <v-list subheader rounded disabled>
          <v-subheader
              role="listitem"
              class="inout__sidebar-list-header"
          >Krank ({{ filterSick.length }})</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item
                v-for="user in filterSick"
                :key="user.gsid"
                role="listitem"
                class="inout__sidebar-list-item"
            >
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col cols="5" v-if="!$store.state.layout.showFullNames">
                    <span class="sidebarInOut__info-name">{{ user.title }}</span>
                  </v-col>
                  <v-col cols="5" v-else>
                    <span class="sidebarInOut__info-name">{{ user.name }} {{ user.surname }}</span>
                  </v-col>
                  <v-col cols="3">{{ user.dates[0].split('-').reverse().join('.') }}</v-col>
                  <v-col cols="1"><b style="padding-left: 0.25rem">&rarr;</b></v-col>
                  <v-col cols="3">{{ user.dates[1].split('-').reverse().join('.') }}</v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
          v-if="$store.state.layout.showGeburtstag"
          no-body
          min-width="352"
          class="inout__sidebar-card"
      >
        <v-list subheader rounded disabled>
          <v-subheader
              role="listitem"
              class="inout__sidebar-list-header"
          >Geburtstage</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item
                v-for="user in filterGeb"
                :key="user.gsid"
                role="listitem"
                class="inout__sidebar-list-item"
            >
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col cols="9" v-if="!$store.state.layout.showFullNames">
                    <span class="sidebarInOut__info-name">{{ user.title }}</span>
                  </v-col>
                  <v-col cols="9" v-else>
                    <span class="sidebarInOut__info-name">{{ user.name }} {{ user.surname }}</span>
                  </v-col>
                  <v-col cols="3" class="text-right">{{ user.birthday.split('-').reverse().join('.').slice(0, 6) }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
          v-if="$store.state.layout.showNews && $store.state.layout.showNews !== null"
          no-body
          min-width="352"
          class="inout__sidebar-card"
      >
        <v-list subheader>
          <v-subheader role="listitem">Newsticker
            <template>
              <v-spacer/>
              <v-btn icon @click="reverseNews = !reverseNews">
                <v-icon size="20">mdi-swap-vertical</v-icon>
              </v-btn>
            </template>
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item
                v-for="(news, id) in filterNews" v-bind:key="id.id"
                role="listitem"
                inactive
                style="cursor: pointer"
                @click="currentArticle = news; openNews()">
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col>{{ news.title }}</v-col>
                  <v-col cols="12" style="font-size: small">
                    {{ news.datetime.slice(0, 10).split('-').reverse().join('.') }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item inactive v-if="!(how_many_news > $store.getters['dataManagement/news'].length)">
              <v-list-item-content class="text-center">
                <v-btn @click="how_many_news = how_many_news + 5">
                  Mehr anzeigen
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
    <NewsDialog :open.sync="openNewsDialog" :currentArticle="currentArticle" />
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/inout/NewsDialog";
import sidebarPosition from "@/mixins/sidebarPosition";

export default {
  name: "SidebarInOut",
  components: { NewsDialog },
  mixins: [sidebarPosition],
  props: {
    active: Boolean
  },
  data() {
    return {
      today: new Date().toISOString().substr(5, 5),
      currentArticle: null,
      openArticle: false,
      how_many_news: 5,
      reverseNews: false,
      filter: [
        { name: 'Abteilungen', number: 0 },
        { name: 'Standorte', number: 1 },
        { name: 'Gruppen', number: 2 },
      ],
      lastValue: 0,
      openNewsDialog: false
    }
  },
  computed: {
    filterVacation()
    {
      return this.$store.getters["dataManagement/users"]
          .filter(user => user.status_id[0] === this.statusId("sdevphpweb_sixsixcmsphp73paul.c.288.de") &&
          (user.name.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.surname.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.title.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.number.replace(/ /g, '').includes(this.$store.state.layout.searchText) ||
              user.city.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase())))
          .sort((a, b) =>
          {
            return new Date(a.dates[1]) - new Date(b.dates[1]);
          });
    },
    filterSick()
    {
      return this.$store.getters["dataManagement/users"]
          .filter(user => user.status_id[0] === this.statusId("sdevphpweb_sixsixcmsphp73paul.c.289.de") &&
          (user.name.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.surname.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.title.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              user.number.replace(/ /g, '').includes(this.$store.state.layout.searchText) ||
              user.city.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase())))
          .sort((a, b) =>
          {
            return new Date(a.dates[1]) - new Date(b.dates[1]);
          });
    },
    filterGeb()
    {
      let li = this.$store.getters["dataManagement/users"].filter(c => c.birthday !== '' && c.birthday !== null &&
          (c.name.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              c.surname.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              c.title.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
              c.number.replace(/ /g, '').includes(this.$store.state.layout.searchText) ||
              c.city.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase())));

      li.sort((a, b) =>
      {

        if (a.birthday.slice(5, 7) < b.birthday.slice(5, 7))
        {
          return -1;
        }
        if (a.birthday.slice(5, 7) > b.birthday.slice(5, 7))
        {
          return 1;
        }
        if (a.birthday.slice(5, 7) === b.birthday.slice(5, 7) && a.birthday.slice(8, 10) < b.birthday.slice(8, 10))
        {
          return -1;
        }
        if (a.birthday.slice(5, 7) === b.birthday.slice(5, 7) && a.birthday.slice(8, 10) > b.birthday.slice(8, 10))
        {
          return 1;
        }
        return 0
      });

      return li.filter(c => c.birthday.slice(5, 10) >= this.today).concat(li.filter(c => c.birthday.slice(5, 10) < this.today)).slice(0, 5);
    },
    filterNews()
    {
      let li = this.$store.getters["dataManagement/news"];

      li.sort((a, b) =>
      {
        if (a.datetime > b.datetime)
        {
          return -1;
        }
        if (a.datetime < b.datetime)
        {
          return 1;
        }
        return 0;
      });

      return this.reverseNews ? li.slice(0, this.how_many_news).reverse() : li.slice(0, this.how_many_news);
    },
    style() {
      if (this.active) {
        return this.sidebarStyle(document.getElementById("inout__sidebar_content"));
      }
    }
  },
  methods: {
    openNews()
    {
      this.openArticle = true;
    },
    changeDash(number)
    {
      this.$store.state.layout.show = number;
      localStorage.setItem('show', number);
    },
    statusId(gsid) {
      return this.$store.getters["dataManagement/status"].find(e => e.gsid === gsid).id;
    }
  },
};
</script>

<style lang="scss">
#inout__sidebar {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  transition: all 0.6s ease-in;
  padding-top: var(--offset-sidebar-content, 10px);

  .inout__sidebar-card {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }
}

.inout__sidebar-list-item {
  padding: 0 0.25rem;
}

.inout__sidebar-list-item-filter {
  height: 44px;
}

.inout__sidebar-list-header {
  padding: 0 0.25rem;
}

.sidebarInOut__info-name {
  text-overflow: ellipsis;
}
</style>

