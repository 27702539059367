<template>
  <v-card width="375" v-if="filterUser.length !== 0 || $store.state.layout.searchText === ''">
    <v-list subheader avatar rounded>
      <v-subheader>{{ groupHeader.title }} {{ $store.state.layout.groupAction ? " - " + filterUser.length : "" }}
        <template
            v-if="$store.state.layout.groupAction && (groupHeader.custom === false || groupHeader.custom === undefined)">
          <v-spacer/>
          <v-btn icon
                 v-if="groupHeader.mail !== '' && groupHeader.mail !== undefined"
                 :href="`mailto:${groupHeader.mail}`"
                 target="_blank"
                 aria-label="Mail an gesamte Abteilung">
            <v-icon size="20">mdi-email</v-icon>
          </v-btn>
          <v-btn icon @click="openGroupActions = true"
                 aria-label="Menü der Abteilung">
            <v-icon size="20">mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
      </v-subheader>
      <v-divider style="padding-bottom: 0.5em"></v-divider>
      <UserItem
          v-for="user in filterUser"
          :key="user.gsid"
          :user="user"
          :group-id="groupHeader.gsid"
      />
    </v-list>
    <GroupActionDialog :open.sync="openGroupActions" :group="groupHeader" />
  </v-card>
</template>

<script>
import UserItem from "@/components/templates/inout/UserItem";
import GroupActionDialog from "@/components/dialogs/inout/GroupActionDialog";

export default {
  name: "GroupTemplate",
  data: () => ({
    openGroupActions: false
  }),
  components: { GroupActionDialog, UserItem },
  props: {
    groupHeader: {
      type: Object
    },
  },
  watch: {
    filterUser()
    {
      this.$emit("updateDom");
    }
  },
  computed: {
    filterUser()
    {
      return this.$store.getters["dataManagement/users"]
          .filter(user =>
              (user.divisions.some(division => division === this.groupHeader.id) || user.location[0] === this.groupHeader.id) &&
              (user.name.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
                  user.surname.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase()) ||
                  user.title.toUpperCase().includes(this.$store.state.layout.searchText.toUpperCase())))
          .sort((a, b) => a.title.localeCompare(b.title));
    }
  }
};
</script>