<template>
  <v-dialog v-model="$store.state.layout.displayAbout" max-width="525px">
    <v-card class="elevation-12">
      <v-tabs
          v-model="tabs"
          background-color="primary"
          centered
          grow
          dark
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          Basis
        </v-tab>
        <v-tab>
          Technisch
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="text-center pt-1">
                <p>SixIO Version {{ $store.state["changelog/packageVersion"] }}</p>
                <p>Six Offene Systeme GmbH</p>
                <p>Am Wallgraben 99<br>D-70565 Stuttgart</p>
                <a href="https://www.six.de"
                   target="_blank"
                   :style="$vuetify.theme.dark ? 'color: #CF6679' : ''">https://www.six.de</a>
                <p>Copyright © Six Offene Systeme GmbH, 2020. All rights reserved.</p>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row class="text-left">
                <v-col cols="6">
                  <p class="headline">Server</p>
                  <p>Linux</p>
                  <p>Server-Version: </p>
                  <p>Nodejs: 12.16.1 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/nodejs/node/blob/master/LICENSE">Lizenz</a></p>
                  <p>Expressjs: 4.17.1 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/expressjs/express/blob/master/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Node-Fetch: 2.6.0 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/node-fetch/node-fetch/blob/master/LICENSE.md">MIT
                    Lizenz</a></p>
                  <p>Web-Push: 3.4.4 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/web-push-libs/web-push/blob/master/LICENSE">MPL2.0
                    Lizenz</a></p>
                </v-col>
                <v-col cols="6">
                  <p class="headline">Client</p>
                  <p>Vue: 2.6.10 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/vuejs/vue/blob/dev/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Vuex: 3.4.0 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/vuejs/vuex/blob/dev/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Vuetify: 2.3.2 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/vuetifyjs/vuetify/blob/master/LICENSE.md">MIT
                    Lizenz</a></p>
                  <p>Register-SW: 1.7.1 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/yyx990803/register-service-worker/blob/master/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Core-js: 3.6.5 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/zloirock/core-js/blob/master/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Vue-Masonry: 0.11.8 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/shershen08/vue-masonry/blob/master/LICENSE">MIT
                    Lizenz</a></p>
                  <p>Vue.Draggable: 2.23.2 - <a
                      :style="$vuetify.theme.dark ? 'color: #CF6679' : ''"
                      href="https://github.com/SortableJS/Vue.Draggable/blob/master/LICENSE">MIT
                    Lizenz</a></p>
                </v-col>
                <v-col cols="6">
                  <p class="headline">Datenbank</p>
                  <p>SixCMS: 11.1.0</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: 'About',
        data()
        {
            return {
                tabs: null,
            }
        }
    }
</script>