import { render, staticRenderFns } from "./SidebarInOut.vue?vue&type=template&id=4ce68597&"
import script from "./SidebarInOut.vue?vue&type=script&lang=js&"
export * from "./SidebarInOut.vue?vue&type=script&lang=js&"
import style0 from "./SidebarInOut.vue?vue&type=style&index=0&id=4ce68597&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports