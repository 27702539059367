/**
 * Module for session view
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	sessions: [],
};

const getters = {
	sessions: state => state.sessions,
};

const actions = {
	getSessions: ({ rootState, commit, dispatch }) =>
	{
		let object = { token: rootState.auth.token, gsid: rootState.auth.loggedInUser.gsid };
		fetch(apiBaseUrl + "/getSessions",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			})
			.then(res =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 200)
				{
					return res.json();
				}
			})
			.then(data =>
			{
				commit('ADD_SESSIONS', data);
			})
			.catch(error => {
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Sessions konnten nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});
	},
	killSession: ({ rootState, dispatch, commit }, token) => {
		const body = {
			token: rootState.auth.token,
			deleteToken: token
		};

		fetch(apiBaseUrl + "/deleteToken",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 200)
				{
					commit("REMOVE_SESSION", token);
				}
				else if (res.status === 401)
				{
					dispatch('auth/reLogin');
				}
				else
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Sessions konnten nicht geschlossen werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Sessions konnten nicht geschlossen werden",
					color: "error"
				}, { root: true });
			})
	}
};

const mutations = {
	ADD_SESSIONS(state, payload)
	{
		state.sessions = payload;
	},
	REMOVE_SESSION(state, payload) {
		const index = state.sessions.findIndex(e => e.token === payload);
		state.sessions.splice(index, 1);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
