<template>
  <v-dialog v-model="$store.state.layout.displaySessions" width="950" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>
        <span class="headline">Aktive Sessions</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="refreshSessions">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="$store.state.layout.displaySessions = false" v-if="$vuetify.breakpoint.xsOnly">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="width: 40px">#</th>
            <th class="text-left">Key</th>
            <th class="text-left">Session geöffnet</th>
            <th class="text-left">Session schließt</th>
            <th class="text-left" style="width: 100px">Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(session, id) in $store.getters['session/sessions']" :key="id">
            <td>
              <v-icon v-if="session.mobile" :color="session.token === $store.state.auth.token ? 'green' : ''">mdi-cellphone</v-icon>
              <v-icon v-else :color="session.token === $store.state.auth.token ? 'green' : ''">mdi-monitor</v-icon>
            </td>
            <td>{{ session.token }}</td>
            <td>{{ formatTime(session.created) }}</td>
            <td>{{ expTime(session.created, session.expire) }}</td>
            <td>
              <v-row>
                <v-btn icon disabled>
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
                <v-btn icon :disabled="session.token === $store.state.auth.token" @click="killSession(session.token)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>

<script>
	export default {
		name: "Sessions",
		data: () => ({
			timeFormat: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' },
		}),
		computed: {

		},
		methods: {
			formatTime(time)
			{
				const date = new Date(time);
				return date.toLocaleDateString('de-DE', this.timeFormat);
			},

			expTime(time, expire)
			{
				if (expire)
				{
					const date = new Date(time);
					date.setDate(date.getDate() + 1);

					return date.toLocaleDateString('de-DE', this.timeFormat);
				}
				else
				{
					return '-';
				}
			},

			refreshSessions()
			{
				this.$store.dispatch('session/getSessions');
			},

			killSession(token)
			{
				this.$store.dispatch("session/killSession", token);
			}
		},
	}
</script>

<style scoped>

</style>