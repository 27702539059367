const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	changelog: [],
	packageVersion: process.env.VERSION || '0',
	displayChangelog: false,
	showChangelog: JSON.parse(localStorage.getItem("showChangelog")),
};

const getters = {
	changelog: state => state.changelog,
};

const actions = {
	getChangelog: ({ rootState, dispatch, commit }) => {
		let object = {
			token: rootState.auth.token
		};

		fetch(apiBaseUrl+ '/getChangelog',
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			})
			.then(data =>
			{
				if (data.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (data.status === 200)
				{
					return data.json();
				}
			})
			.then(res =>
			{
				commit('ADD_CHANGELOG', res);
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Changelog konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});
	},

	openChangelog: ({ state }) => {
		if (state.showChangelog === null)
		{
			state.showChangelog = true;
			localStorage.setItem("showChangelog", true);
		}

		if (localStorage.getItem("versionChangelog"))
		{
			if (JSON.parse(localStorage.getItem("versionChangelog")) !== state.packageVersion && state.showChangelog)
			{
				state.displayChangelog = true;
				localStorage.setItem("versionChangelog", JSON.stringify(state.packageVersion));
			}
		}
		else
		{
			if (state.showChangelog)
			{
				state.displayChangelog = true;
				localStorage.setItem("versionChangelog", JSON.stringify(state.packageVersion));
			}
		}
	},
};

const mutations = {
	ADD_CHANGELOG(state, payload)
	{
		state.changelog = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
