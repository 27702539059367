<template>
  <v-dialog v-model="openDialog" max-width="300px">
    <v-card class="mx-auto">
      <v-list shaped>
        <v-list-item-group multiple v-model="selectedUser" v-for="(data, id) in custom_users" :key="id">
          <v-list-item-title>
            <h3 style="padding-left: 10px">{{ data.group }}</h3>
          </v-list-item-title>
          <template v-for="(user, i) in data.users">
            <v-list-item
                :key="`item-${i}`"
                :value="user"
                active-class="secondary--text text--accent-4"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-content
                    @click.stop="toggle, addUsersToGroup(user)">
                  <v-list-item-title
                      v-if="!$store.state.layout.showFullNames"
                      v-text="user.title"></v-list-item-title>
                  <v-list-item-title
                      v-else
                      class="text-truncate"
                  >{{ user.name }} {{ user.surname }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                      :true-value="user"
                      color="secondary"
                      @click.stop="toggle, addUsersToGroup(user)"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserDialog",
  props: {
    open: Boolean,
    selectedGroup: [Object, String]
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    },
    custom_users()
    {
      let data = this.$store.getters["dataManagement/users"].reduce((r, e) =>
      {
        const group = this.$store.state.layout.showFullNames ? e.name[0] : e.title[0];

        if (!r[group])
        {
          r[group] = {group, users: [e]};
        }
        else
        {
          r[group].users.push(e);
        }

        return r;
      }, {});

      return Object.values(data).sort((a, b) => a.group.localeCompare(b.group));
    },
    selectedUser() {
      return this.$store.getters["dataManagement/users"].filter(
          c => c.divisions.indexOf(this.selectedGroup) > -1
      );
    },
  },
  methods: {
    addUsersToGroup(user) {
      this.$emit("addUser", user);
    }
  }
}
</script>

<style scoped>

</style>