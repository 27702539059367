<template>
  <v-list-item
      class="userItem"
      role="listitem"
      inactive
      :id="groupId + '_' + user.id">

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-list-item-avatar
            :color="userStatus"
            @click="clickedID = clickedID === null ? user.id : null"
            size="25"
            v-on="on"
        >
          <v-icon size="15"
                  v-if="checkBirthday">
            mdi-gift-outline
          </v-icon>
        </v-list-item-avatar>
      </template>
      <span>{{ selectedStatus }} {{ event ? " - " + event : "" }}</span>
    </v-tooltip>

    <v-list-item-content id="user__list-content">
      <template v-if="clickedID !== user.id">
        <v-hover v-slot="{ hover }" open-delay="1000" close-delay="500">
          <v-row no-gutters
                 align="center"
                 justify="center"
                 >
            <v-col :cols="$store.state.layout.showFullNames ? 7 : 5"
                   style="cursor: pointer;"
                   @click="openCard = true">
              <v-row
                  no-gutters
                  :style="checkSubTitle ?
                    '' : 'margin-top: 0.25rem;'"
              >
                <v-col :cols="$store.state.layout.showFullNames ? '3' : '4'"
                       class="text-truncate body-2">
                  {{ speedDial }}
                </v-col>
                <v-col v-if="!$store.state.layout.showFullNames"
                       cols="8"
                       class="body-2">
                  {{ user.title }}
                </v-col>
                <v-col v-else cols="8"
                       :class="user.dates[1] !== 'body-2' ? 'text-truncate body-2' : ''">
                  {{ user.name }}
                  {{ user.surname }}
                </v-col>
                <v-col v-if="user.dates[1] !== ''" style="font-size: 0.725rem" cols="12">
                  Zurück am {{ returnDate }}
                </v-col>
                <v-col
                    v-else-if="user.number_ids && $store.state.layout.showNumber === 2 || ($store.state.layout.showNumber === 1 && statusNumber.split('-')[1] === undefined)"
                    style="font-size: 0.725rem"
                    class="text-truncate" cols="12">
                  {{
                    statusNumber.replace(/ /g, '&#8198;').replace('+49', '0').replace(',', '').replace('/', '')
                  }}
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="$store.state.layout.showFullNames ? 5 : 7" @mouseleave="showOverflowText = null">
              <v-text-field
                  v-if="showOverflowText !== user.id"
                  v-model="user.info"
                  :color="$vuetify.theme.dark ? 'secondary' : ''"
                  :id="groupId + '_' + user.id + '_infoText'"
                  hide-details
                  :ref="'infoText_' + user.id"
                  class="user__infoText"
                  @focusout="blockShowOverflowText = false"
                  @change="updateInfo()"
                  @mouseover="blockShowOverflowText ? null : checkOverflowText()"
                  aria-label="Benutzerinformation"
              >
              </v-text-field>
              <span v-else style="z-index: 10"
                    @click="focusText()"
              >{{ user.info }}</span>
            </v-col>
            <UserDetailCard v-if="hover" :user="user" />
          </v-row>
        </v-hover>
      </template>

      <template v-else>
        <v-row no-gutters>
          <v-col v-for="status in filterStatus" :key="status.gsid">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar
                    :color="status.color"
                    size="25"
                    @click="selectStatus(status.id)"
                    v-on="on"
                >
                </v-avatar>
              </template>
              <span>{{ status.title }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-list-item-content>
    <DatesDialog
        :user="user"
        :open.sync="openDatesDialog"
        @loadBackUp="loadBackUpUser"
    />
  </v-list-item>
</template>

<script>
import UserDetailCard from "@/components/templates/inout/UserDetailCard";
import DatesDialog from "@/components/dialogs/inout/DatesDialog";
import displayEvent from "@/mixins/displayEvent";

export default {
  name: "UserItem",
  components: { DatesDialog, UserDetailCard },
  mixins: [displayEvent],
  data: () => ({
    userBackUp: null,
    detailId: null,
    blockShowOverflowText: false,
    showOverflowText: null,
    clickedID: null,
    openCard: false,
    openDatesDialog: false
  }),
  props: {
    user: Object,
    groupId: [Number, String]
  },
  computed: {
    status() {
      return this.$store.getters["dataManagement/status"];
    },
    filterStatus()
    {
      return this.status.filter(s => s.id !== this.user.status_id[0]);
    },
    userStatus() {
      return this.status.find(e => e.id === this.user.status_id[0]).color;
    },
    checkBirthday() {
      return this.user.birthday.slice(5, 10) === new Date().toISOString().substr(5, 5);
    },
    selectedStatus()
    {
      return this.status.find(s => s.id === this.user.status_id[0]).title;
    },
    returnDate()
    {
      let date = new Date(this.user.dates[1]);
      date.setDate(date.getDate() + 1);

      if (date.getDay() === 0)
      {
        date.setDate(date.getDate() + 1);
      }
      else if (date.getDay() === 6)
      {
        date.setDate(date.getDate() + 2);
      }

      return date.toISOString().slice(0, 10).split('-').reverse().join('.').slice(0, 5);
    },
    statusNumber()
    {
      if (this.user.number_ids) {
        const numbers = this.$store.getters["dataManagement/numbers"].filter(e => this.user.number_ids.some(id => id === e.id));

        if (numbers.find(e => e.location_id[0] === this.user.location[0] && e.status_id.some(x => x === this.user.status_number[0])))
        {
          return numbers.find(e => e.location_id[0] === this.user.location[0] && e.status_id.some(x => x === this.user.status_number[0])).title;
        }
        else if (numbers.find(e => e.status_id.some(x => x === this.user.status_number[0])))
        {
          return numbers.find(e => e.status_id.some(x => x === this.user.status_number[0])).title;
        }
        else if (numbers.length > 0)
        {
          return numbers[0].title;
        }

        return "";
      }

      return "";
    },
    speedDial() {
      const [num, sd] = this.statusNumber.split('-');
      if (num === "Empty") {
        return "";
      }

      return sd;
    },
    checkSubTitle() {
      return this.user.dates[1] !== '' ||
          this.$store.state.layout.showNumber === 2 ||
          (this.$store.state.layout.showNumber === 1 &&
              this.statusNumber.split('-')[1] === undefined &&
              this.user.number_ids !== undefined);
    },
    event() {
      const e = this.currentEvent(this.user);
      const filter_status = this.$store.getters["dataManagement/status"].filter(e =>
          e.gsid === "sdevphpweb_sixsixcmsphp73paul.c.289.de" ||
          e.gsid === "sdevphpweb_sixsixcmsphp73paul.c.288.de" ||
          e.gsid === "sdevphpweb_sixsixcmsphp73paul.c.284.de" ||
          e.gsid === "sdevphpweb_sixsixcmsphp73paul.c.285.de")

      if (e && !filter_status.some(e => e.id === this.user.status_id[0]))
      {
        return e.category;
      }

      return false;
    }
  },
  methods: {
    selectStatus(id) {
      this.userBackUp = JSON.parse(JSON.stringify(this.user));

      if (this.user.dates !== ['', ''])
      {
        this.user.dates = ['', ''];
      }

      if (this.user.number_ids)
      {
        const numbers = this.$store.getters["dataManagement/numbers"].filter(e => this.user.number_ids.some(x => x === e.id));

        if (numbers?.some(e => e.status_id.some(x => x ===  id.toString()))) {
          this.user.status_number = [id.toString()];
        }
      }

      this.user.status_id = [id.toString()];

      const status_gsid = this.$store.getters["dataManagement/status"].find(e => e.id === id.toString()).gsid;
      if (status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.288.de" || status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.289.de")
      {
        this.openDatesDialog = true;
      }
      else
      {
        this.$store.dispatch("request/changeInfo", this.user);
        this.userBackUp = null;
      }

      this.clickedID = null;
    },
    updateInfo()
    {
      this.$store.dispatch("request/changeInfoText", this.user);
    },
    checkOverflowText()
    {
      let uElem = document.getElementById(this.groupId + '_' + this.user.id + '_infoText');

      if (0 > uElem.clientWidth - uElem.scrollWidth)
      {
        this.showOverflowText = this.user.id;
      }
      else
      {
        this.showOverflowText = null;
      }

    },

    focusText()
    {
      this.blockShowOverflowText = true;
      this.showOverflowText = null;
      setTimeout(() =>
      {
        this.$refs['infoText_' + this.user.id].focus();
      }, 100);
    },
    loadBackUpUser() {
      this.user = this.userBackUp;
      this.userBackUp = null;
    }
  }
}
</script>

<style lang="scss">
.userItem {
  height: 42px;
  padding: 0 8px;
}

.v-list-item {
  min-height: 0;
}

#user__list-content {
  margin-left: -0.5rem;
}

.user__infoText {
  padding: 0 0 2px;
  margin: 0;
  .v-input__control .v-input__slot .v-text-field__slot input {
    text-overflow: ellipsis;
    padding-bottom: 0;
  }
}
</style>