<template>
  <div id="general">
    <h5 class="general__header">Allgemein</h5>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="4">
        <h5>Benachrichtigung</h5>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                :disabled="!$store.state.notification.notifyAllow"
                color="secondary"
                v-model="$store.state.notification.notifyWebPush"
                @change="changeSettings('subscribePush', $store.state.notification.notifyWebPush), updateNotification()"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Web Push Benachrichtigungen</v-list-item-title>
            <v-list-item-subtitle>
              Benachrichtigungen erhalten, auch wenn das Fenster geschlossen ist
              (Benachrichtigungen müssen aktiviert sein)
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
                color="secondary"
                v-model="$store.state.notification.notifyAllow"
                @change="changeSettings('checkedNoti', $store.state.notification.notifyAllow), updateNotification()"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Benachrichtigungen</v-list-item-title>
            <v-list-item-subtitle>Benachrichtige mich über Updates</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div v-if="$store.state.notification.notifyAllow" style="padding-left: 4.25em;">
          <v-checkbox
              color="secondary"
              v-model="$store.state.notification.notifyBday"
              label="Geburtstage"
              @change="changeSettings('checkedGeburtstag', $store.state.notification.notifyBday), notBday(), updateNotificationSub()"
              hide-details
          ></v-checkbox>
          <v-checkbox
              color="secondary"
              v-model="$store.state.notification.notifyStatus"
              label="Statusänderungen"
              @change="changeSettings('checkedStatus', $store.state.notification.notifyStatus), updateNotificationSub()"
              hide-details
          ></v-checkbox>
          <v-checkbox
              style="display: none"
              disabled
              color="secondary"
              v-model="$store.state.notification.notifyEvent"
              label="Events"
              @change="changeSettings('checkedEvents', $store.state.notification.notifyEvent)"
          ></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4">
        <h5>System</h5>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                color="secondary"
                v-model="$vuetify.theme.dark"
                @change="changeSettings('darkMode', $vuetify.theme.dark)"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Darkmode</v-list-item-title>
            <v-list-item-subtitle>Dunklermodus aktivieren</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                color="secondary"
                v-model="$store.state.layout.sidebar_backdrop"
                @change="changeSettings('sidebarBackdrop', $store.state.layout.sidebar_backdrop)"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sidebar-Hintergrund</v-list-item-title>
            <v-list-item-subtitle>
              Verhindert ungewollte Klicks außerhalb der Sidebar
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                color="secondary"
                v-model="$store.state.layout.groupAction"
                @change="changeSettings('groupAction', $store.state.layout.groupAction)"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Gruppendetails</v-list-item-title>
            <v-list-item-subtitle>Eröffnet neue Möglichkeiten für Gruppen
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                color="secondary"
                v-model="$store.state.layout.showFullNames"
                @change="changeSettings('showFullNames', $store.state.layout.showFullNames)"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Vollständige Namen</v-list-item-title>
            <v-list-item-subtitle>Vollständige Namen anzeigen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Vollständige Telefonnummer anzeigen</v-list-item-title>
            <v-list-item-subtitle>Vollständige Telefonnummer unterhalb des Namens anzeigen</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action style="width: 150px">
            <v-select
                :items="showNumbers"
                v-model="$store.state.layout.showNumber"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
                :item-color="$vuetify.theme.dark ? 'secondary' : ''"
                :color="$vuetify.theme.dark ? 'secondary' : ''"
                single-line
                hide-details
                @change="changeSettings('showNumber', $store.state.layout.showNumber)"
            ></v-select>
          </v-list-item-action>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4">
        <h5 class="pt-2">Log</h5>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                color="secondary"
                v-model="$store.state.protocol.record"
                @change="changeSettings('pProtocolRecord', $store.state.protocol.record)"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Privater Log</v-list-item-title>
            <v-list-item-subtitle>Notiert sich temporär Änderungen an deinem Status/Infotext
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar v-if="$store.state.protocol.record" style="margin: 0;"
                              height="88" width="60">
            <v-badge color="secondary"
                     :content="$store.state.protocol.list.length">
              <v-btn icon @click="$store.state.protocol.showProtocol = true"
                     :disabled="!$store.state.protocol.list.length > 0">
                <v-icon>mdi-message-bulleted</v-icon>
              </v-btn>
            </v-badge>
          </v-list-item-avatar>
        </v-list-item>
        <h5 class="pt-2">Webhook</h5>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
                v-model="webHook_url"
                placeholder="POST-URL"
                hide-details
                :color="$vuetify.theme.dark ? 'secondary' : ''"
                outlined>
              <template v-slot:append>
                <v-btn icon @click="addWebHook()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(el, index) in $store.getters['webhook/webHooks']"
                     style="line-height: 55px" :key="index">
          <v-list-item-content>
            <span>{{ el.title }}</span>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-btn icon @click="deleteWebHook(el.title)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
      </v-col>
    </v-row>
    <ProtocolDialog />
  </div>
</template>

<script>
import ProtocolDialog from "@/components/dialogs/profile/ProtocolDialog";

export default {
  name: "General",
  components: {
    ProtocolDialog
  },
  data: () => ({
    showNumbers: [
      { id: 0, name: 'Niemals' },
      { id: 1, name: 'Keinem Kürzel' },
      { id: 2, name: 'Immer' },
    ],
    webHook_url: "",
  }),
  methods: {
    changeSettings(name, value)
    {
      try
      {
          localStorage.setItem(name, value);
      } catch (e)
      {
        console.log(e);
      }
    },
    addWebHook()
    {
      if (this.webHook_url !== '')
      {
        this.$store.dispatch("webhook/addWebhook", {url: this.webHook_url})
            .then(() =>
            {
              this.webHook_url = '';
            });
      }
    },

    updateWebHook(url, status)
    {
      this.$store.dispatch("webhook/updateWebhook", {url: url, status: status})
    },

    deleteWebHook(url)
    {
      this.$store.dispatch("webhook/deleteWebhook", url)
    },
    updateNotification()
    {
      let perm = false;

      if (Notification.permission !== "granted")
      {
        Notification.requestPermission(permission =>
        {
          if (permission === 'denied')
          {
            this.$store.state.notification.notifyAllow = false;
          }
          else
          {
            perm = true;
          }
        });
      }
      else
      {
        perm = true;
      }

      if (perm)
      {
        if (this.$store.state.notification.notifyBday)
        {
          this.notBday();
        }

        if (this.$store.state.notification.notifyWebPush && this.$store.state.notification.notifyAllow)
        {
          if (this.$store.state.subscribe.webPush_ServerKey_Public === null)
          {
            this.$store.dispatch('subscribe/getSubscribeKey').then((res) =>
            {
              if (res === "done")
              {
                this.$store.dispatch('subscribe/subscribePush');
              }
            })
          }
          else
          {
            this.$store.dispatch('subscribe/subscribePush');
          }
        }
        else
        {
          if (this.$store.state.subscribe.subscriptionObject !== null)
          {
            this.$store.dispatch('subscribe/unsubscribePush');
          }
        }
      }

    },

    updateNotificationSub()
    {
      if (this.$store.state.subscribe.subscriptionObject)
      {
        this.$store.dispatch("subscribe/updateSubscription");
      }
    },

    notBday()
    {
      if (this.$store.state.notification.notifyBday && this.$store.state.notification.notifyAllow)
      {
        this.$store.getters["dataManagement/users"].forEach(user =>
        {
          if (user.birthday.slice(5, 10) === new Date().toISOString().substr(5, 5))
          {
            try
            {
              new Notification("Geburtstag!", {body: user.title + ' hat heute Geburtstag!'});
            } catch (error)
            {
              navigator.serviceWorker.ready.then((reg) =>
              {
                reg.showNotification("Geburtstag!", {body: user.title + ' hat heute Geburtstag!'});
              })
            }
          }
        });
      }
    },
  }
}
</script>

<style lang="scss">
#general {
  padding: 1rem 0;

  .general__header {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>