'use strict';

const DB_NAME = 'dbIO';
const DB_Version = 2;
let DB;

export default {
    async openDB()
    {
        return new Promise((resolve, reject) =>
        {

            if (DB)
            {
                return resolve(DB);
            }
            let dbRequest = indexedDB.open(DB_NAME, DB_Version);

            //Bei einem Fehler
            dbRequest.onerror = (e) =>
            {
                console.log("openDB: " + e.target.errorCode);
            };

            dbRequest.onsuccess = (e) =>
            {
                DB = e.target.result;
                resolve(DB);
            };

            //Datenbank upgraden
            dbRequest.onupgradeneeded = (e) =>
            {
                let db = e.target.result;

                if (!db.objectStoreNames.contains("users")) {
                    db.createObjectStore('users', {
                        keyPath: 'gsid',
                    });
                }

                if (!db.objectStoreNames.contains("groupsUser")) {
                    db.createObjectStore('groupsUser', {
                        keyPath: 'gsid',
                    });
                }

                if (!db.objectStoreNames.contains("locations")) {
                    db.createObjectStore('locations', {
                        keyPath: 'gsid',
                    });
                }
            }
        })
    },

    async getUser()
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(["users"], "readwrite")
            trans.oncomplete = () =>
            {
                resolve(users);
            };

            let store = trans.objectStore('users');
            let users = [];

            store.openCursor().onsuccess = (e) =>
            {
                let cursor = e.target.result;
                if (cursor)
                {
                    users.push(cursor.value);
                    cursor.continue();
                }
            }
        });
    },

    async addUserDB(user)
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(['users'], 'readwrite');
            trans.oncomplete = () =>
            {
                resolve();
            };

            let store = trans.objectStore('users');
            store.put(user);

        });

    },

    //Groups auslesen und abspeichern
    async getGroups()
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(["groupsUser"], "readwrite")
            trans.oncomplete = () =>
            {
                resolve(groups);
            };

            let store = trans.objectStore('groupsUser');
            let groups = [];

            store.openCursor().onsuccess = (e) =>
            {
                let cursor = e.target.result;
                if (cursor)
                {
                    groups.push(cursor.value);
                    cursor.continue();
                }
            }
        });
    },

    async addGroupDB(group)
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(['groupsUser'], 'readwrite');
            trans.oncomplete = () =>
            {
                resolve();
            };

            let store = trans.objectStore('groupsUser');
            store.put(group);

        });

    },

    async deleteGroupDB(groupID)
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(['groupsUser'], 'readwrite');
            trans.oncomplete = () =>
            {
                resolve();
            };

            let store = trans.objectStore('groupsUser');
            store.delete(groupID);

        });
    },

    //Locations auslesen und abspeichern
    async getLocations()
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(["locations"], "readwrite")
            trans.oncomplete = () =>
            {
                resolve(locations);
            };

            let store = trans.objectStore('locations');
            let locations = [];

            store.openCursor().onsuccess = (e) =>
            {
                let cursor = e.target.result;
                if (cursor)
                {
                    locations.push(cursor.value);
                    cursor.continue();
                }
            }
        });
    },

    async addLocationDB(location)
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(['locations'], 'readwrite');
            trans.oncomplete = () =>
            {
                resolve();
            };

            let store = trans.objectStore('locations');
            store.put(location);

        });

    },

    async deleteLocationDB(locationID)
    {
        let db = await this.openDB();

        return new Promise(resolve =>
        {

            let trans = db.transaction(['locations'], 'readwrite');
            trans.oncomplete = () =>
            {
                resolve();
            };

            let store = trans.objectStore('locations');
            store.delete(locationID);

        });
    },

}