<template>
  <div id="parking">
    <p>{{ name }}</p>
    <div v-for="event in eventToday" class="parking__avatar-wrapper">
      <span>{{ userName(event) }}: {{ title(event) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Parking",
  props: {
    name: String,
    eventToday: Array
  },
  methods: {
    title(event) {
      const start = new Date(event.start);
      const startHours = (start.getHours() < 10 ? '0' : '') + start.getHours();
      const startMinutes = (start.getMinutes() < 10 ? '0' : '') + start.getMinutes();

      const end = new Date(event.end);
      const endHours = (end.getHours() < 10 ? '0' : '') + end.getHours();
      const endMinutes = (end.getMinutes() < 10 ? '0' : '') + end.getMinutes();

      return `${startHours}:${startMinutes} Uhr - ${endHours}:${endMinutes} Uhr`
    },
    userName(event) {
      const user = this.$store.getters["dataManagement/users"].find(user => user.id === event.uid[0]);

      return this.$store.state.layout.showFullNames ? user.name + " " + user.surname : user.title
    }
  }
}
</script>

<style lang="scss">
#parking {
  width: 250px;
  height: 350px;
  border: #e0e0e0 1px solid;
  text-align: center;
  cursor: pointer;

  p {
    padding-top: 125px;
  }

  .parking__avatar-wrapper {
    text-align: start;
    margin: 0 0.5rem;
  }
}
</style>