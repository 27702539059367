const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
};

const getters = {
};

const actions = {
	error500: ({ commit }) =>
	{
		commit("snackbar/OPEN_SNACKBAR", {
			text: "Datenbankfehler, Änderungen konnte nicht übernommen werden",
			color: "error"
		}, { root: true });
	},

	save_retry_request: ({ state }, payload) =>
	{
		localStorage.setItem('retry_request', JSON.stringify(payload));
	},

	retry_request: ({ commit, rootState }) =>
	{
		if (localStorage.getItem('retry_request') !== null)
		{
			const el = JSON.parse(localStorage.getItem('retry_request'));
			el.data.token = rootState.auth.token;

			fetch(apiBaseUrl + "/" + el.url,
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(el.data)
				})
				.then(res =>
				{
					if (res.status === 200)
					{
						localStorage.removeItem('retry_request');
					}
					else
					{
						commit("snackbar/OPEN_SNACKBAR", {
							text: "Anfrage konnte nicht erneut gesendet werden",
							color: "error"
						}, { root: true });

						localStorage.removeItem('retry_request');
					}
				})
		}
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
