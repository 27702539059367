/**
 * Module for webhooks
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	webHooks: [],
};

const getters = {
	webHooks: state => state.webHooks,
};

const actions = {
	getWebhook: ({ commit, rootState }) =>
	{
		let log = {token: rootState.auth.token, gsid: rootState.auth.loggedInUser.gsid}

		fetch(apiBaseUrl + "/getWebHook",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(log)
			})
			.then(res =>
			{
				if (res.status === 200) {
					return res.json();
				} else {
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Webhooks konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				}
			})
			.then(data =>
			{
				commit('SET_WEBHOOKS', data);
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Webhooks konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});
	},
	addWebhook: ({ rootState, dispatch, commit }, data) => {
		const body = {
			token: rootState.auth.token,
			title: data.url,
			active: false,
			hookid: rootState.auth.loggedInUser.gsid
		};

		fetch(apiBaseUrl + "/setWebHook",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 200)
				{
					return res.json();
				}
				else if (res.status === 400)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Die Webhook-URL wird bereits verwendet",
						color: "info"
					}, { root: true });
				}
				else
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Webhook konnte nicht hinzugefügt werden",
						color: "error"
					}, { root: true });
				}
			})
			.then(data =>
			{
				commit("ADD_WEBHOOK", data);
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Webhook konnte nicht hinzugefügt werden",
					color: "error"
				}, { root: true });
			});
	},
	updateWebhook: ({ rootState, commit }, data) => {
		let object = {
			token: rootState.auth.token,
			title: data.url,
			active: data.status,
		};

		fetch(apiBaseUrl + "/updateWebHook",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object)
			})
			.then(res =>
			{
				if (res.status !== 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Webhookänderungen konnten nicht gespeichert werden",
						color: "error"
					}, { root: true });
				}
			})
			.catch(error => {
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Webhookänderungen konnten nicht gespeichert werden",
					color: "error"
				}, { root: true });
			});
	},
	deleteWebhook: ({ commit, rootState }, url) => {
		const body = {
			token: rootState.auth.token,
			url: url,
		};

		fetch(apiBaseUrl + "/deleteWebHook",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 200)
				{
					commit("DELETE_WEBHOOK", url);
					return;
				}

				commit("snackbar/OPEN_SNACKBAR", {
					text: "Webhook konnten nicht gelöscht werden",
					color: "error"
				}, { root: true });
			})
			.catch(error => {
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Webhook konnten nicht gelöscht werden",
					color: "error"
				}, { root: true });
			});
	}
};

const mutations = {
	SET_WEBHOOKS(state, payload)
	{
		state.webHooks = payload;
	},
	ADD_WEBHOOK(state, payload) {
		state.webHooks.push(payload);
	},
	DELETE_WEBHOOK(state, payload) {
		const index = state.webHooks.findIndex(e => e.title === payload);
		state.webHooks.splice(index, 1);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
