import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from 'vuetify/es5/locale/de'
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                primary: '#275F74',
                secondary: '#74275f',

            },
            dark: {
                primary: '#272727',
                secondary: '#BB86FC',
                error: '#CF6679'
            }
        }
    },
    lang: {
        locales: {de},
        current: 'de',
    }
});
