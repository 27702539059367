<template>
  <v-dialog
      v-model="openDialog"
      :width="item === 'Von/Bis' ? 685 : 360"
      :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="elevation-12">
      <v-toolbar
          dark
          flat
          color="primary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="padding-bottom: 0">
        <v-select
            v-model="item"
            :items="items"
            solo
            single-line
            label="Select"
            :style="item === 'Von/Bis' ? 'padding-top: 10px; padding-left: 12px; width: 615px' : 'padding-top: 10px; width: 302px; padding-left: 12px'"
            hide-details
            :item-color="$vuetify.theme.dark ? 'secondary' : ''"
            :color="$vuetify.theme.dark ? 'secondary' : ''"
        ></v-select>
        <v-row
            style="padding: 0; margin-left: 0">
          <v-col style="flex-grow: 0">
            <v-time-picker
                v-model="time"
                format="24hr"
                scrollable
                :min="today"
                max="22:00"
                :color="$vuetify.theme.dark ? 'secondary' : ''"
            ></v-time-picker>
            <v-radio-group
                row
                v-model="vonRadio"
                dense
                hide-details
            >
              <v-radio v-for="status in status"
                       :key="status.id"
                       :label="status.title"
                       :value="status.color"
                       :color="status.color"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="item === 'Von/Bis'" style="flex-grow: 0">
            <v-time-picker
                v-model="time2"
                format="24hr"
                scrollable
                :min="today"
                max="22:00"
                :color="$vuetify.theme.dark ? 'secondary' : ''"
            ></v-time-picker>
            <v-radio-group row v-model="bisRadio" dense hide-details>
              <v-radio v-for="status in status"
                       :key="status.id"
                       :label="status.title"
                       :value="status.color"
                       :color="status.color"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="openDialog = false"
               color="primary">
          Abbruch
        </v-btn>
        <v-btn @click="einreichen"
               color="primary">
          Festlegen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GroupActionDialog",
  data: () => ({
    items: ['Von', 'Von/Bis'],
    item: 'Von',
    time: null,
    time2: null,
    bisRadio: null,
    vonRadio: null,
  }),
  props: {
    open: Boolean,
    group: Object
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    },
    status()
    {
      if (this.$store.getters["dataManagement/status"].length > 0)
      {
        return this.$store.getters["dataManagement/status"];
      }

      return [];
    },
    title()
    {
      if (this.group)
      {
        return this.group.title
      }

      return "";
    },
    today()
    {
      return new Date()
          .toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })
          .substr(12, 5);
    },
  },
  methods: {
    einreichen()
    {
      let object = null;
      const users = this.$store.getters["dataManagement/users"].filter(user => user.divisions.some(division => division === this.group.id));

      if (this.item === 'Von' ? (this.time !== null && this.vonRadio !== null) :
          (this.time !== null && this.vonRadio !== null && this.time2 !== null && this.bisRadio !== null))
      {
        if (this.item === 'Von/Bis')
        {

          object = {
            token: this.$store.state.auth.token,
            changedBy: {
              name: this.$store.state.auth.loggedInUser.title,
              id: this.$store.state.auth.loggedInUser.id,
            },
            color: this.vonRadio,
            groupId: this.group.id,
            time: this.time,
            users: users,
            colorBis: this.bisRadio,
            timeBis: this.time2,
          };

        }
        else
        {
          object = {
            token: this.$store.state.auth.token,
            changedBy: {
              name: this.$store.state.auth.loggedInUser.title,
              id: this.$store.state.auth.loggedInUser.id,
            },
            color: this.vonRadio,
            groupId: this.group.id,
            time: this.time,
            users: users,
          };

        }

        if (this.$store.state.offline.connected)
        {
          this.$store.dispatch("request/setGroupTimer", object);
        }
        else
        {
          if (this.$store.state["offline/fetchQueueGroup"].some(group => group.groupId === object.groupId))
          {
            const index = this.$store.state["offline/fetchQueueGroup"].findIndex(group => group.groupId === object.groupId);
            this.$store.state["offline/fetchQueueGroup"].splice(index, 1);
            this.$store.state["offline/fetchQueueGroup"].push(object);
          }
          else
          {
            this.$store.state["offline/fetchQueueGroup"].push(object);
          }
          //TODO: Refactor -> Mutations
        }

        this.time = null;
        this.time2 = null;
        this.bisRadio = null;
        this.vonRadio = null;

        this.$store.state.layout.displayAction = false;
      }
      else
      {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          text: "Keine Uhrezeit oder Status ausgwählt",
          color: "error"
        }, { root: true });
      }
    }
  }
}
</script>

<style>
.v-radio {
  margin-right: 10px;
}
</style>