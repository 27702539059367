<template>
  <div id="parkingLayout" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
    <template v-for="(data, index) in parkingSpaces">
      <Parking
          :name="data.name"
          :eventToday="filterEvents(data.name)"
          :key="index"
          class="parking__wrapper"
          @click.native="openEvent(data.name)"
      />
    </template>
  </div>
</template>

<script>
import Parking from "@/components/templates/booking/Parking";
export default {
  name: "ParkingLayout",
  components: { Parking },
  props: {
    parkingSpaces: Array,
    events: Array
  },
  methods: {
    filterEvents(category) {
      return this.events.filter(e => e.category === category);
    },
    openEvent(category) {
      this.$emit("openEvent", { category: category });
    }
  }
}
</script>

<style lang="scss">
#parkingLayout {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem 0.5rem;

  &.theme--light {
    background-color: white;
  }

  .parking__wrapper {
    margin-top: 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>