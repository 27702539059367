<template>
  <div id="booking" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
    <Sidebar @opened="active = $event">
      <div id="booking__sidebar" :style="style">
        <div id="booking__sidebar_content" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
          <div class="booking__sidebar-locations">
            <v-list>
              <v-subheader><h5 class="booking__sidebar-list-header">Standorte</h5></v-subheader>
              <v-list-item-group v-model="selectedLocation">
                <v-list-item
                    v-for="(location, index) in locations"
                    :key="index"
                    class="booking__sidebar-list-item"
                    selectable
                >
                  {{ location.title }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="booking__sidebar-calendar">
            <v-sheet height="64">
              <v-toolbar
                  flat
                  style="background: inherit"
              >
                <v-btn
                    outlined
                    @click="setTodayMonth"
                    small
                    class="mr-2"
                >
                  Heute
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="prev"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="next"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title class="booking__sidebar-calendar-title">
                  {{ title }}
                </v-toolbar-title>
              </v-toolbar>
            </v-sheet>
            <v-calendar
                ref="calendar"
                :now="today"
                v-model="focus"
                @click:date="setDate"
            >
            </v-calendar>
          </div>
          <div class="booking__sidebar-news">
            <v-list>
              <v-subheader><h5 class="booking__sidebar-list-header">Zuletzt hinzugefügt</h5></v-subheader>
              <v-list-item
                  v-for="(event, index) in sortedLastAddedEvents"
                  :key="index"
                  class="booking__sidebar-list-item"
              >
                {{ event.category }}: {{ eventTimeStart(event) }} {{ eventTimeEnd(event) }}
                <v-row no-gutters justify="end">
                  <v-icon
                      small
                      class="booking__sidebar-news-icon"
                      @click.native="editEvent({ event: event })"
                  >mdi-pencil</v-icon>
                  <v-icon
                      small
                      class="booking__sidebar-news-icon"
                      @click.native="deleteEvent(event)"
                  >mdi-delete</v-icon>
                </v-row>
              </v-list-item>
              <v-list-item v-if="sortedLastAddedEvents.length === 0">
                Kein Termin gefunden.
                <v-btn
                    small
                    class="ml-4"
                    @click="openEvent(false)"
                >Neuer Termin</v-btn>
              </v-list-item>
              <div style="text-align: center" v-if="lastAddedEvents.length > 5">
                <v-btn
                    text
                    outlined
                    x-small
                    @click="$refs.BookingOverviewDialog.open()"
                >
                  Alle anzeigen
                </v-btn>
              </div>
            </v-list>
          </div>
        </div>
      </div>
    </Sidebar>
    <div class="booking__content">
      <div class="booking__content-rooms">
        <v-sheet>
          <v-toolbar
              flat
          >
            <div class="booking__content-calendar-navigation">
              <div class="booking__content-calendar-navigation-actions">
                <v-btn
                    outlined
                    class="mr-4"
                    @click="setTodayRoom"
                >
                  Heute
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="prevDayRoom"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="nextDayRoom"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </div>
              <v-toolbar-title class="booking__content-calendar-title">
                {{ titleRoom }}
              </v-toolbar-title>
            </div>

            <div class="booking__content-calendar-actions">
              <v-btn
                  outlined
                  @click="switchOfficeView"
                  class="booking__content-calendar-switch"
              >
                {{ showOfficeTable ? "Grafisch" : "Kalender" }}
              </v-btn>
              <v-btn
                  outlined
                  @click="openEvent(false)"
              >
                Termin
              </v-btn>
            </div>
          </v-toolbar>
        </v-sheet>
        <div v-if="showOfficeTable" class="booking__content-calendar-wrapper">
          <BookingCalendar
              ref="roomBookingCalendar"
              :events="filterEvents"
              :categories="getCategories"
              :selected-location="selectedLocation"
              :focus.sync="focusRoom"
              office
              @deleteEvent="deleteEvent"
              @setCalendarFocus="setCalendarFocus"
              @editEvent="editEvent"
              @openEvent="openPreEvent($event, false)"
          />
        </div>
        <div v-else class="booking__content-gui-wrapper">
          <div :class="$vuetify.theme.dark ? 'booking__content-gui' : 'booking__content-gui--light'">
            <Office
                :categories="getCategories"
                :location="getLocation"
                :events="currentEventsRooms"
                @openEvent="openEventWithCategory($event, false)"
                @editEvent="editEvent"
                @deleteEvent="deleteEvent"
            />
          </div>
        </div>
      </div>
      <div class="booking__content-parking">
        <v-sheet>
          <v-toolbar
              flat
          >
            <div class="booking__content-calendar-navigation">
              <div class="booking__content-calendar-navigation-actions">
                <v-btn
                    outlined
                    class="mr-4"
                    @click="setTodayParking"
                >
                  Heute
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="prevDayParking"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    @click="nextDayParking"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </div>
              <v-toolbar-title class="booking__content-calendar-title">
                {{ titleParking }}
              </v-toolbar-title>
            </div>
            <div class="booking__content-calendar-actions">
              <v-btn
                  outlined
                  @click="switchParkingView"
                  class="booking__content-calendar-switch"
              >
                {{ showParkingTable ? "Grafisch" : "Kalender" }}
              </v-btn>
              <v-btn
                  outlined
                  @click="openEvent(true)"
              >
                Termin
              </v-btn>
            </div>
          </v-toolbar>
        </v-sheet>
        <div v-if="showParkingTable" class="booking__content-calendar-wrapper">
          <BookingCalendar
              ref="parkingBookingCalendar"
              :events="filterEvents"
              :categories="getParking"
              :selected-location="selectedLocation"
              :focus.sync="focusParking"
              @deleteEvent="deleteEvent"
              @setCalendarFocus="setCalendarFocus"
              @editEvent="editEvent"
              @openEvent="openPreEvent($event, true)"
          />
        </div>
        <div v-else class="booking__content-gui-wrapper">
          <div :class="$vuetify.theme.dark ? 'booking__content-gui' : 'booking__content-gui--light'">
            <ParkingLayout
                :parking-spaces="getParking"
                :events="currentEventsParking"
                @openEvent="openEventWithCategory($event, true)"/>
          </div>
        </div>
      </div>
    </div>
    <BookingDialog
        :index="selectedLocation"
        :categories="combineCategories"
        @closed="opened = false"
        ref="bookingDialog"
    />
    <ConfirmDialog
        ref="confirmDelete"
    />
    <BookingOverviewDialog
        :events="lastAddedEvents"
        :categoriesRoom="getCategories"
        :categoriesParking="getParking"
        @editEvent="editEvent"
        @deleteEvent="deleteEvent"
        ref="BookingOverviewDialog"
    />
  </div>
</template>

<script>
import BookingDialog from "@/components/dialogs/booking/BookingDialog"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog"
import BookingCalendar from "@/components/templates/booking/BookingCalendar";
import ParkingLayout from "@/components/templates/booking/ParkingLayout";
import BookingOverviewDialog from "@/components/dialogs/booking/BookingOverviewDialog";
import Office from "@/components/templates/booking/Office";
import Sidebar from "@/components/layout/Sidebar";
import sidebarPosition from "@/mixins/sidebarPosition";

export default {
  name: "Booking",
  components: {
    Sidebar,
    Office,
    ParkingLayout,
    BookingCalendar,
    BookingDialog,
    ConfirmDialog,
    BookingOverviewDialog
  },
  mixins: [sidebarPosition],
  data: () => ({
    focus: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
    selectedLocation: 0,
    showOfficeTable: true,
    showParkingTable: true,
    focusRoom: new Date().toISOString().substr(0, 10),
    focusParking: new Date().toISOString().substr(0, 10),
    opened: false,
    lastValue: 0,
    active: false
  }),
  computed: {
    locations() {
      return this.$store.getters["dataManagement/locations"];
    },
    getLocation() {
      if (this.locations.length > 0) {
        return this.locations[this.selectedLocation];
      }
      return {};
    },
    getCategories() {
      if (this.locations.length > 0) {
        return this.locations[this.selectedLocation].categories;
      }
      return [];
    },
    getParking() {
      if (this.locations.length > 0) {
        return this.locations[this.selectedLocation].parking;
      }
      return [];
    },
    combineCategories() {
      return this.getCategories.concat(this.getParking);
    },
    filterEvents() {
      if (this.locations.length > 0) {
        return this.locations[this.selectedLocation].events;
      }
      return [];
    },
    currentEventsParking() {
      if (this.filterEvents?.length > 0) {
        return this.filterEvents.filter(e => this.compareDate(e, this.focusParking));
      }
      return [];
    },
    currentEventsRooms() {
      if (this.filterEvents?.length > 0) {
        return this.filterEvents.filter(e => this.compareDate(e, this.focusRoom));
      }
      return [];
    },
    lastAddedEvents() {
      if (this.filterEvents) {
        return this.filterEvents.filter(e => e.uid[0] === this.$store.state.auth.loggedInUser.id);
      }
      return [];
    },
    sortedLastAddedEvents() {
      return this.lastAddedEvents
          .sort((a, b) => {
            return new Date(b.start) - new Date(a.start);
          }).slice(0, 5);
    },
    title () {
      const date = this.focus !== "" ? new Date(this.focus) : new Date();
      const month = date.toLocaleDateString("de-DE", { month: 'long' });
      const year = date.getFullYear();

      return month + ' ' + year;
    },
    titleRoom() {
      const date = this.focusRoom !== "" ? new Date(this.focusRoom) : new Date();
      const weekday = date.toLocaleDateString("de-DE", { weekday: 'long' });
      const day = date.getDate();
      const month = date.toLocaleDateString("de-DE", { month: 'long' });
      const year = date.getFullYear();
      return weekday + ', ' + day + '. ' + month + ' ' + year;
    },
    titleParking() {
      const date = this.focusParking !== "" ? new Date(this.focusParking) : new Date();
      const weekday = date.toLocaleDateString("de-DE", { weekday: 'long' });
      const day = date.getDate();
      const month = date.toLocaleDateString("de-DE", { month: 'long' });
      const year = date.getFullYear();
      return weekday + ', ' + day + '. ' + month + ' ' + year;
    },
    style() {
      if (this.active) {
        return this.sidebarStyle(document.getElementById("booking__sidebar_content"));
      }
    }
  },
  methods: {
    compareDate(e, focus) {
      const [year, month, day] = focus.split("-");
      const dateStamp = new Date(year, month - 1, day, 0, 0, 0).getTime();
      const dateEnd = new Date(year, month - 1, day, 23, 59, 59).getTime();
      const startStamp = e.start;
      const endStamp = e.end;

      return (startStamp >= dateStamp && startStamp <= dateEnd) ||
          (endStamp <= dateEnd && endStamp > dateStamp) ||
          (startStamp < dateStamp && endStamp >= dateEnd);
    },
    setTodayRoom () {
      const date = new Date();
      this.focusRoom = date.toISOString().substr(0, 10);
    },
    nextDayRoom () {
      const date = new Date(this.focusRoom);
      date.setDate(date.getDate() + 1);
      this.focusRoom = date.toISOString().substr(0, 10);
    },
    prevDayRoom () {
      const date = new Date(this.focusRoom);
      date.setDate(date.getDate() - 1);
      this.focusRoom = date.toISOString().substr(0, 10);
    },
    setTodayParking () {
      const date = new Date();
      this.focusParking = date.toISOString().substr(0, 10);
    },
    nextDayParking () {
      const date = new Date(this.focusParking);
      date.setDate(date.getDate() + 1);
      this.focusParking = date.toISOString().substr(0, 10);
    },
    prevDayParking () {
      const date = new Date(this.focusParking);
      date.setDate(date.getDate() - 1);
      this.focusParking = date.toISOString().substr(0, 10);
    },
    setCalendarFocus (focus) {
      this.focus = focus;
    },
    prev () {
      this.$refs.calendar.prev();
    },
    next () {
      this.$refs.calendar.next();
    },
    setDate ({ date }) {
      this.focus = date;
      this.focusRoom = date;
      this.focusParking = date;
    },
    setTodayMonth() {
      const date = new Date();
      this.focus = date.toISOString().substr(0, 10);
    },
    deleteEvent(event) {
      this.$refs.confirmDelete.open("Termin löschen?", "Wollen Sie den Termin wirklich löschen?", 275)
          .then(confirm => {
            if (confirm) {
              this.$store.dispatch("booking/deleteEvent", event);
            }
          });
    },
    openEvent(parking) {
      parking ? this.$refs.bookingDialog.open(this.focusParking) : this.$refs.bookingDialog.open(this.focusRoom);
    },
    openEventWithCategory(data, parking) {
      if (!this.opened) {
        const table = data.table || null;
        const date = parking ? this.focusParking : this.focusRoom;
        this.$refs.bookingDialog.openCategory(data.category, table, date);
        this.opened = true;
      }
    },
    editEvent({ event }) {
      if (!this.opened) {
        this.$refs.bookingDialog.open(null, event, true);
        this.opened = true;
      }
    },
    openPreEvent(event, parking) {
      if (!this.opened) {
        const date = parking ? this.focusParking : this.focusRoom;
        this.$refs.bookingDialog.open(date, event, false);
        this.opened = true;
      }
    },
    eventTimeStart(event) {
      const start = new Date(event.start);

      const startHours = (start.getHours() < 10 ? '0' : '') + start.getHours();
      const startMinutes = (start.getMinutes() < 10 ? '0' : '') + start.getMinutes();

      return `${startHours}:${startMinutes} Uhr`
    },
    eventTimeEnd(event) {
      const end = new Date(event.end);

      const endHours = (end.getHours() < 10 ? '0' : '') + end.getHours();
      const endMinutes = (end.getMinutes() < 10 ? '0' : '') + end.getMinutes();

      return `- ${endHours}:${endMinutes} Uhr`
    },
    switchParkingView() {
      this.showParkingTable = !this.showParkingTable;
      localStorage.setItem("parkingView", this.showParkingTable);
    },
    switchOfficeView() {
      this.showOfficeTable = !this.showOfficeTable;
      localStorage.setItem("officeView", this.showOfficeTable);
    }
  },
  mounted()
  {
    this.showOfficeTable = JSON.parse(localStorage.getItem("officeView"));
    this.showParkingTable = JSON.parse(localStorage.getItem("parkingView"));
    this.selectedLocation = this.$store.getters["dataManagement/locations"].findIndex(e => e.id === this.$store.state.auth.loggedInUser.location[0]);
  }
}
</script>

<style lang="scss">
#booking {
  min-height: 100%;
  width: 100%;

  &.theme--light {
    background-color: #F5F5F5;
  }

  .booking__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 0;
    padding: 1rem;
  }
}

#booking__sidebar {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  transition: all 0.6s ease-in;
  padding-top: var(--offset-sidebar-content, 10px);
}

#booking__sidebar_content {
  .booking__sidebar-calendar, .booking__sidebar-locations, .booking__sidebar-news {
    border-radius: 4px;
    border-color: #fff;
    border-style: solid;
  }

  &.theme--dark {
    .booking__sidebar-calendar, .booking__sidebar-locations, .booking__sidebar-news {
      border-radius: 4px;
      border-color: #1E1E1E;
      border-style: solid;
    }
  }
}

.booking__sidebar-locations, .booking__sidebar-calendar {
  margin-bottom: 1rem;
}

.booking__sidebar-calendar {
  color: whitesmoke;
  display: flex;
  flex-direction: column;

  //Fix
  .v-calendar-monthly.theme--dark .v-calendar-weekly__head {
    border-bottom: #9e9e9e 1px solid;
  }

  .v-calendar-monthly.theme--light .v-calendar-weekly__head {
    border-bottom: #e0e0e0 1px solid;
  }
}

.booking__sidebar-list-header {
  font-weight: 700;
  font-size: 28px;
}

.booking__sidebar-list-item {
  line-height: 44px;
}

.booking__sidebar-news-icon {
  cursor: pointer;

  &:not(:first-child) {
    padding-left: 0.5rem;
  }
}

.booking__content-rooms, .booking__content-parking {
  display: flex;
  flex-direction: column;
  width: 100%;

  .v-sheet {
    background-color: unset;
  }

  &:not(:first-child) {
    padding-top: 1.5rem;
  }

  .booking__content-calendar-wrapper {
    overflow: auto;
  }

  .v-toolbar__content {
    flex-direction: column;
    flex: 1;
    height: 150px !important;
  }

  .booking__content-calendar-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;

    .booking__content-calendar-navigation-actions {
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  .booking__content-calendar-actions {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    align-self: flex-end;
  }

  .v-sheet {
    display: flex;

    header {
      height: unset !important;
    }
  }
}

@media screen and (min-width: 500px){
  .booking__content-rooms .booking__content-calendar-navigation, .booking__content-parking .booking__content-calendar-navigation {
    flex-direction: row;

    .booking__content-calendar-title {
      margin-left: 0.5rem;
    }
  }
}

@media screen and (min-width: 900px){
  .booking__content-rooms, .booking__content-parking {
    .v-toolbar__content {
      flex-direction: row;
      justify-content: space-between;
      height: 64px !important;
    }

    .booking__content-calendar-actions, .booking__content-calendar-navigation {
      margin-top: 0;
      align-self: unset;
    }

    .v-sheet header {
      height: 64px !important;
    }
  }
}

.booking__content-gui-wrapper {
  .booking__content-gui {
    background-color: #1E1E1E;
  }

  .booking__content-gui--light {
    background-color: unset;
  }

  .booking__content-gui, .booking__content-gui--light {
    overflow: auto;
  }
}

.booking__content-calendar-switch {
  margin-right: 0.5rem;
}

.booking__sidebar-calendar-title {
  width: 100%;
  text-align: right;
}
</style>
