<template>
  <div id="profile" v-scroll="onScroll">
    <div class="profile__content">
      <Overview @updateProfile="saveChangeSettings = $event" />
      <v-divider></v-divider>
      <Groups />
      <v-divider></v-divider>
      <General />
      <v-divider></v-divider>
      <Api />
    </div>
    <v-btn
        v-show="$vuetify.breakpoint.smAndDown"
        style="margin-right: 15px"
        fab
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
        width="40"
        height="40"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <ConfirmDialog ref="confirmExit" />
  </div>
</template>

<script>
import Overview from "@/components/templates/profile/Overview";
import Groups from "@/components/templates/profile/Groups";
import General from "@/components/templates/profile/General";
import Api from "@/components/templates/profile/Api";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";

export default {
  name: "Profile",
  components: {
    ConfirmDialog,
    Api,
    General,
    Groups,
    Overview,
  },
  beforeRouteLeave (to, from, next) {
    if (this.saveChangeSettings) {
      this.$refs.confirmExit.open("Änderungen verwerfen?", "Wollen Sie ihre Änderungen verwerfen?", 275)
          .then(confirm => {
            if (confirm) {
              this.$store.commit("auth/BACKUP_PROFILE");
              next();
            }
          });
    } else {
      next();
    }
  },
  data: () => ({
    auto_scroll_Top: false,
    saveChangeSettings: false
  }),
  methods: {
    onScroll()
    {
      //let frame = document.querySelector("#scroll-target");
      this.auto_scroll_Top = true;
    },
    toTop()
    {
      document.querySelector('#profile').scrollIntoView({behavior: "smooth"});
      this.auto_scroll_Top = false;
    },
  }
};
</script>

<style scoped lang="scss">
#profile {
  display: flex;
  padding: 1rem;
  max-width: 100%;

  .profile__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}
</style>