<template>
    <v-footer padless>
        <v-card flat tile width="100%" color="primary">
          <div class="footer__content">
            <span>&copy; {{ new Date().getFullYear() }} - <strong>Six</strong></span>
            <div>
              <span
                  v-if="$store.state.auth.loggedInUser"
                  class="footer__content-pointer"
                  @click="openSession"
              >Session</span>
              <span
                  class="footer__content-pointer"
                  @click="openAbout"
              >Impressum</span>
            </div>
          </div>
        </v-card>
    </v-footer>
</template>

<script>
export default {
  name: 'FooterPage',
  methods: {
    openAbout() {
      this.$store.state.layout.displayAbout = true;
    },
    openSession() {
      this.$store.dispatch('session/getSessions')
          .then(() =>
          {
            this.$store.state.layout.displaySessions = true;
          });
    },
  }
}
</script>

<style lang="scss">
.v-footer {
  z-index: 25;
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.footer__content-pointer {
  cursor: pointer;

  &:not(:last-child) {
    padding-right: 0.75rem;
  }
}
</style>

