const state = {
	//Krank- / Urlaub- / Changelog- / Geburtstagscards anzeigen
	showUrlaub: JSON.parse(localStorage.getItem("showUrlaub")) || true,
	showKrank: JSON.parse(localStorage.getItem("showKrank")) || true,
	showGeburtstag: JSON.parse(localStorage.getItem("showGeburtstag")) || true,
	showNews: JSON.parse(localStorage.getItem("showNews")),

	//Gruppenaktionen
	groupAction: JSON.parse(localStorage.getItem("groupAction")),
	showFullNames: JSON.parse(localStorage.getItem("showFullNames")),
	showNumber: JSON.parse(localStorage.getItem("showNumber")) || 1,

	//Sortieren
	sort_group: JSON.parse(localStorage.getItem("sort_group")),
	sort_location: JSON.parse(localStorage.getItem("sort_location")),

	//Sidebar
	sidebar_backdrop: JSON.parse(localStorage.getItem("sidebarBackdrop")) || true,

	//Fenster anzeigen
	setting: false,
	displayAction: false,
	displayLogin: false,
	displayAbout: false,
	displaySessions: false,
	showLogin: true,

	//Filter
	show: JSON.parse(localStorage.getItem("show")) || 0,
	searchText: '',

	//Layout
	windowTop: 0,
	sidebar: false
};

const getters = {

};

const actions = {
	sortGroups: ({ rootState, commit }, data) => {
		const sort = data.map(e => e.id);
		const custom_groups = rootState.dataManagement.groups.filter(e => e.text_type === "custom");
		rootState.dataManagement.groups = data.concat(custom_groups);
		commit("SET_GROUP_DATA", sort);
	},
	sortLocation: ({ rootState, commit }, data) => {
		const sort = data.map(e => e.id);
		rootState.dataManagement.locations = data;
		commit("SET_LOCATION_DATA", sort);
	}
};

const mutations = {
	SET_GROUP_DATA(state, payload)
	{
		localStorage.setItem('sort_group', JSON.stringify(payload));
		state.sort_group = payload;
	},

	SET_LOCATION_DATA(state, payload)
	{
		localStorage.setItem('sort_location', JSON.stringify(payload));
		state.sort_location = payload;
	},
	TOGGLE_MENU(state) {
		state.sidebar = !state.sidebar;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};