const state = {
	record: JSON.parse(localStorage.getItem("pProtocolRecord")),
	list: [],
	showProtocol: false,
};

const getters = {
};

const actions = {
	addToProtocol: ({ commit }, data) =>
	{
		let date = new Date();
		let time = ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);

		let p = {
			name: data.name,
			action: data.info,
			time: time
		};

		commit("ADD_INFO", p);
	},
};

const mutations = {
	ADD_INFO(state, payload) {
		state.list.push(payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};