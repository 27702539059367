<template>
  <div id="overview">
    <h5 class="overview__header">Profil</h5>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6" lg="2"
             @drop.prevent="addProfileImage"
             @dragover.prevent="uploadDrag = true"
             @dragleave="uploadDrag = false">
        <div :style="(uploadDrag || $store.state.auth.pImage === '') ? uploadDragOver  : ''" class="overview__image-content">
          <template v-if="$store.state.auth.pImage !== ''">
            <div style="display: flex; flex-direction: column">
              <v-img height="275"
                     width="200"
                     :style="uploadDrag ? 'opacity: 0.3;' : ''"
                     :src="$store.state.auth.pImage"
              ></v-img>
              <v-file-input
                  id="FileInput"
                  height="0"
                  style="display: none"
                  v-model="fileUpload"
                  @change="addProfileImage(fileUpload)"
              ></v-file-input>
              <div class="mt-2">
                <v-btn icon
                       color="secondary"
                       @click="editUpload">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon
                       color="secondary"
                       @click="deleteImage">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-file-input
              v-model="fileUpload"
              height="285"
              full-width
              hide-details
              :background-color="$vuetify.theme.dark ? '#1e1e1e' : '#ffffff'"
              @change="addProfileImage(fileUpload)"
              v-else
              style="cursor: pointer; top: -10px"
              prepend-icon=""
              accept="image/png, image/jpeg"
          ></v-file-input>
          <p v-if="$store.state.auth.pImage === ''"
             style="z-index: 99; position: relative; bottom: 175px; padding-left: 10px; padding-right: 10px; pointer-events: none"
             class="text-center"
          >Ziehen Sie ihr Bild hierher oder klicken Sie in diesen Bereich</p>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="8" style="padding-right: 0.75rem">
        <v-form v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field label="Vorname*"
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.name"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            hide-details
                            :rules="[rules.required]"
                            disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field label="Nachname*"
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.surname"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            hide-details
                            :rules="[rules.required]"
                            disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" style="max-height: 72px">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.birthday"
                      label="Geburtstag (YYYY-MM-DD | DD-MM-YYYY)"
                      clearable
                      :color="$vuetify.theme.dark ? 'secondary' : ''"
                      @input="fixBirthday"
                      @change="changeBirthday">
                    <template v-slot:append>
                      <v-icon v-on="on" @click="setBirthday">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="bday"
                    :max="new Date(new Date().setFullYear(new Date().getFullYear() - 16)).toISOString().substr(0, 10)"
                    min="1940-01-01"
                    @change="save(bday)"
                    :color="$vuetify.theme.dark ? 'secondary' : ''"
                    first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-select
                  :value="userLocation.title"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="$store.getters['dataManagement/locations']"
                  item-text="title"
                  return-object
                  label="Stadt*"
                  :item-color="$vuetify.theme.dark ? 'secondary' : ''"
                  :color="$vuetify.theme.dark ? 'secondary' : ''"
                  @change="changeLocationUser"
                  hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" v-if="$store.state.social.slackTeamID !== ''">
              <v-text-field label="SlackID"
                            append-icon="mdi-help"
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.slack"
                            @click:append="slackID"
                            @keydown="changeProfile"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field label="Skype-Name"
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.skype"
                            @keydown="changeProfile"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field label="Identifikationsfarbe"
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.color_id"
                            @change="changeProfile"
                            :color="$vuetify.theme.dark ? 'secondary' : ''"
                            :rules="[rules.required, isColor, isDuplicated]"
              >
                <template v-slot:append>
                  <v-menu v-model="menuColorPicker" top nudge-bottom="25" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on"></div>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="$store.state.auth.loggedInUser === null ? '' : $store.state.auth.loggedInUser.color_id"
                            flat
                            hide-inputs
                            @update:color="changeProfile"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col lg="2" style="padding-left: 0.75rem" class="overview__actions">
        <v-btn
            class="overview__button-logout"
            tile
            outlined
            color="red"
            @click="logout">
          Abmelden
        </v-btn>
        <v-btn
            class="overview__button-addNumber"
            tile
            outlined
            @click="openNumberDialog = true">
          Telefonbuch
        </v-btn>
        <v-btn
            class="overview__button-editPassword"
            tile
            outlined
            @click="openEditPasswordDialog = true">
          Passwort ändern
        </v-btn>
        <v-btn
            class="overview__button-save"
            tile
            outlined
            :disabled="!saveChangeSettings || !valid"
            @click="saveChangeProfile">
          <v-icon left>mdi-content-save</v-icon>
          Speichern
        </v-btn>
      </v-col>
    </v-row>
    <HelpSlackDialog :open.sync="openHelpSlackDialog" />
    <EditPasswordDialog :open.sync="openEditPasswordDialog" />
    <AddNumberDialog :open.sync="openNumberDialog" />
  </div>
</template>

<script>
import HelpSlackDialog from "@/components/dialogs/profile/HelpSlackDialog";
import EditPasswordDialog from "@/components/dialogs/profile/EditPasswordDialog";
import AddNumberDialog from "@/components/dialogs/profile/PhoneBookDialog";

export default {
  name: "Overview",
  components: { AddNumberDialog, EditPasswordDialog, HelpSlackDialog },
  data: () => ({
    //Menüs
    menu: false,
    menuColorPicker: false,

    //Bild hochladen
    fileUpload: null,
    uploadImage: null,
    uploadDrag: false,

    //Dialoge
    openHelpSlackDialog: false,
    openEditPasswordDialog: false,
    openNumberDialog: false,

    //Valide Einstellungen
    saveChangeSettings: false,
    imageBackup: null,
    valid: false,
    bday: '',
    rules: {
      required: value => !!value || 'Erforderlich.',
    },
  }),
  watch: {
    menu(val)
    {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  computed: {
    uploadDragOver()
    {
      if (this.$vuetify.theme.dark)
      {
        return 'border: 2px dashed #fff; height: 300px; ';
      }
      else
      {
        return 'border: 2px dashed #1e1e1e; height: 300px;';
      }
    },
    userLocation()
    {
      return this.$store.getters["dataManagement/locations"].find(e => e.id === this.$store.state.auth.loggedInUser.location[0]);
    },
    swatchStyle() {
      return {
        backgroundColor: this.isColor(this.$store.state.auth.loggedInUser.color_id) ? this.$store.state.auth.loggedInUser.color_id : "#FFF",
        cursor: 'pointer',
        height: '25px',
        width: '25px',
        borderRadius: this.menuColorPicker ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    addProfileImage(e)
    {
      if (e.dataTransfer !== undefined)
      {
        this.fileUpload = e.dataTransfer.files[0];
      }

      const oReader = new window.FileReader();
      const vm = this;
      this.imageBackup = this.$store.state.auth.pImage;

      let userImage = {};
      this.uploadDrag = false;

      oReader.onload = (function (oFile)
      {
        return function (a)
        {
          userImage.type = a.target.result.split(",")[0].replace(/^data:/, "");
          userImage.name = vm.$store.state.auth.loggedInUser.title.toLowerCase() + "." + oFile.name.split(".")[1];
          userImage.data = a.target.result.split(",")[1];
          vm.uploadImage = userImage;
          vm.$store.state.auth.pImage = a.target.result;
        }
      })(this.fileUpload);

      oReader.readAsDataURL(this.fileUpload);

      oReader.onloadend = () =>
      {
        vm.changeProfile();
      };
    },
    editUpload()
    {
      document.getElementById('FileInput').click();
    },

    deleteImage()
    {
      this.imageBackup = this.$store.state.auth.pImage;
      this.$store.state.auth.pImage = '';
      this.uploadImage = "";
      this.fileUpload = null;
      this.changeProfile();
    },
    fixBirthday()
    {
      if (this.$store.state.auth.loggedInUser.birthday !== null)
      {
        this.$store.state.auth.loggedInUser.birthday = this.$store.state.auth.loggedInUser.birthday.split(".").join("-");

        if (this.$store.state.auth.loggedInUser.birthday.length === 10)
        {
          this.changeProfile();
        }
      }
    },

    setBirthday()
    {
      if (this.$store.state.auth.loggedInUser.birthday.length >= 4)
      {
        this.bday = this.$store.state.auth.loggedInUser.birthday;
      }
    },

    changeBirthday()
    {
      if (this.$store.state.auth.loggedInUser.birthday !== null)
      {
        if (this.$store.state.auth.loggedInUser.birthday.split("-")[0].length === 2)
        {
          this.$store.state.auth.loggedInUser.birthday = this.$store.state.auth.loggedInUser.birthday.split('-').reverse().join('-');
        }
      }

      this.changeProfile();
    },
    changeLocationUser(input)
    {
      this.$store.state.auth.loggedInUser.location = [input.id.toString()];
      this.changeProfile();
    },
    changeProfile()
    {
      this.saveChangeSettings = true;
      this.$emit("updateProfile", this.saveChangeSettings);
    },
    saveChangeProfile()
    {
      if (this.$store.state.auth.loggedInUser.birthday === null)
      {
        this.$store.state.auth.loggedInUser.birthday = "";
      }

      this.$store.dispatch("request/changeProfile", this.uploadImage)
          .then(() =>
          {
            this.uploadImage = null;
            this.fileUpload = null;
            this.saveChangeSettings = false;
            this.$emit("updateProfile", this.saveChangeSettings);
          });
    },
    slackID()
    {
      this.openHelpSlackDialog = true;
    },
    save(date)
    {
      this.$refs.menu.save(date);
      this.$store.state.auth.loggedInUser.birthday = date;
      this.changeProfile();
    },
    /**
     * Überprüft einen String, ob er eine valide Farbe ist.
     * @param {String} color - Zu überprüfende Farbe
     * @returns {string|boolean} - Ist der übergebene String eine Farbe?
     */
    isColor(color) {
      if(!color || typeof color !== 'string') return "Farbcode ist fehlerhaft";

      // Validate hex values
      if(color.substring(0, 1) === '#') color = color.substring(1);

      switch(color.length) {
        case 3: return /^[0-9A-F]{3}$/i.test(color);
        case 6: return /^[0-9A-F]{6}$/i.test(color);
        case 8: return /^[0-9A-F]{8}$/i.test(color);
        default: return "Farbcode ist fehlerhaft";
      }
    },
    /**
     * Durchsucht alle User, ob die Farbe bereits verwendet wird.
     * @param {String} color - Zu überprüfende Farbe
     * @returns {string|boolean} - Wird die übergebene Farbe bereits verwendet?
     */
    isDuplicated(color) {
      if(this.$store.getters["dataManagement/users"].find(e => e.color_id === color)) {
        return "Farbcode existiert bereits"
      }

      return true;
    }
  }
}
</script>

<style lang="scss">
#overview {
  padding-bottom: 1rem;

  .overview__header {
    font-weight: 700;
    font-size: 24px;
  }
}

.overview__button-save,
.overview__button-editPassword,
.overview__button-logout,
.overview__button-addNumber {
  width: 100%;
  margin-top: 0.5rem;
}

.overview__actions {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1264px) {
    .overview__button-logout {
      margin-top: 1rem;
    }

    .overview__button-addNumber {
      margin-top: auto;
    }

    .overview__button-save {
      margin-bottom: 2.5rem;
    }
  }
}

.overview__image-content {
  margin-right: 0.5rem;
}
</style>
