<template>
  <div id="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen && $store.state.layout.sidebar_backdrop"></div>
    <transition name="slide">
      <div v-if="isPanelOpen"
           class="sidebar-panel"
           :class="$vuetify.theme.dark ? '--theme-dark' : ''"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    closeSidebarPanel() {
      this.isPanelOpen = false;
    }
  },
  computed: {
    isPanelOpen: {
      get() {
        if (this.$store.state.layout.sidebar) {
          this.$nextTick(() => {
            this.$emit("opened", this.$store.state.layout.sidebar);
          });
        }

        return this.$store.state.layout.sidebar;
      },
      set(value) {
        this.$store.state.layout.sidebar = value;
      }
    }
  }
}
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active
{
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.sidebar-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 400px;
  cursor: pointer;
  z-index: 20;
}

.sidebar-panel {
  background-color: #275f74;
  position: absolute;
  height: 100%;
  z-index: 20;
  padding: 0.5rem 1.5rem;
  width: 400px;
  display: flex;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: whitesmoke;
    border-right: none;
    border-left: none;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 0;
  }

  &.--theme-dark {
    background-color: #272727;
    border-top: solid 1px #1E1E1E;
    border-bottom: solid 1px #1E1E1E;
  }
}
</style>