const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
};

const getters = {
};

const actions = {
	addNumber: ({ rootState, dispatch, commit }, payload) => {
		return new Promise(resolve => {
			const body = {
				token: rootState.auth.token,
				uid: rootState.auth.loggedInUser.gsid,
				title: payload.number,
				status_id: payload.status_id,
				location_id: payload.location_id
			};

			fetch(apiBaseUrl + "/addNumber",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				})
				.then(res =>
				{
					if (res.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
						resolve(false);
					}
					else if (res.status === 500)
					{
						dispatch('error/error500', null, { root: true });
						resolve(false);
					}
					else if (res.status === 200)
					{
						return res.json();
					}
					else
					{
						commit("snackbar/OPEN_SNACKBAR", {
							text: "Nummer konnte nicht hinzugefügt werden",
							color: "error"
						}, { root: true });
						resolve(false);
					}
				})
				.then(data => {
					resolve(data);
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Nummer konnte nicht hinzugefügt werden",
						color: "error"
					}, { root: true });
					resolve(false);
				});
		});
	},

	deleteNumber: ({ rootState, dispatch, commit }, gsid) =>
	{
		const body = {
			token: rootState.auth.token,
			uid: rootState.auth.loggedInUser.gsid,
			gsid: gsid
		};

		fetch(apiBaseUrl + "/deleteNumber",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
				else if (res.status === 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Nummer gelöscht",
						color: "success"
					}, { root: true });
				}
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Nummer konnte nicht gelöscht werden",
					color: "error"
				}, { root: true });
			});
	},

	updateNumber: ({ rootState, dispatch, commit }, payload) =>
	{
		const body = {
			token: rootState.auth.token,
			uid: rootState.auth.loggedInUser.gsid,
			title: payload.number,
			status_id: payload.status_id,
			location_id: payload.location_id,
			gsid: payload.gsid
		};

		fetch(apiBaseUrl + "/updateNumber",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			.then(res =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
				else if (res.status === 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Nummer aktualisiert",
						color: "success"
					}, { root: true });
				}
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Nummer konnte nicht aktualisiert werden",
					color: "error"
				}, { root: true });
			});
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
