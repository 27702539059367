/**
 * Module for social interactions
 */

const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	slackTeamID: '',
};

const getters = {

};

const actions = {
	getTeamSlackID: ({ rootState, commit }) =>
	{
		let log = { token: rootState.auth.token };
		fetch(apiBaseUrl + "/getSlackTeamId",
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(log)
			})
			.then(res =>
			{
				if (res.status === 200) {
					return res.json();
				}
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Team SlackID konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			})
			.then(data =>
			{
				commit("SET_SLACK_ID", data);
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Team SlackID konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});
	},
};

const mutations = {
	SET_SLACK_ID(state, payload) {
		state.slackTeamID = payload;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
