import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"

//Views
import Dashboard from '@/views/Dashboard';
import Login from '@/views/Login';
import Profile from '@/views/Profile';
import Booking from '@/views/Booking';

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "dashboard",
		component: Dashboard,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/booking",
		name: "booking",
		component: Booking,
		meta: {
			requiresAuth: true
		}
	},
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {

	//Always close menu on view change
	if (document.getElementById("mobile_menu")) {
		document.getElementById("mobile_menu").classList.remove("header__mobile-menu--active");
	}

	//Always clear search text
	store.state.layout.searchText = "";

	if (to.matched.some(record => record.meta.requiresAuth)) {
		//This route requires auth, check if token exists or stream is open
		if (!store.state.auth.token && !store.state.stream.evtSource) {
			next({ name: 'login' })
		} else {
			next() //Go to wherever I'm going
		}
	} else {
		next() //Does not require auth, make sure to always call next()!
	}
});

export default router;
