export default {
	methods: {
		sidebarStyle(el) {
			if (el) {
				const height = el.getBoundingClientRect().height;
				const scroll_height =  document.documentElement.scrollHeight;
				const inner_height = window.innerHeight;
				const dif_height = this.$store.state.layout.windowTop;

				if (inner_height + dif_height - 125 > height && !(this.lastValue > dif_height)) {
					this.lastValue = dif_height;

					if (inner_height + dif_height + 150 >= scroll_height) {
						return {
							"--offset-sidebar-content": scroll_height - inner_height - (height - inner_height) - 150 + "px",
						}
					}

					return {
						"--offset-sidebar-content": dif_height - (height - inner_height) - 75 + "px",
					}
				} else if (inner_height + dif_height - 125 > height && this.lastValue > dif_height) {
					this.lastValue = dif_height;

					if (height > scroll_height - dif_height - 125) {
						return {
							"--offset-sidebar-content": scroll_height - inner_height - (height - inner_height) - 150 + "px",
						}
					}

					return {
						"--offset-sidebar-content": dif_height - 50 + "px",
					}
				}

				return {
					"--offset-sidebar-content": "10px",
				}
			}

			return {
				"--offset-sidebar-content": "10px",
			};
		}
	}
}