<template>
  <v-dialog v-model="openDialog" :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="$vuetify.breakpoint.mdAndDown ? '500' : '1000'">
    <v-card>
      <v-row no-gutters>
        <v-col lg="6" md="12" sm="12">
          <v-card width="500" style="border-right: 2px solid rgba(255, 255, 255, 0.12)" height="100%">
            <v-card-title class="addNumber__header">
              Telefonliste
              <v-spacer />
              <v-btn icon @click="openDialog = false" v-if="$vuetify.breakpoint.xsOnly">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <div
                  v-for="data in sortedNumbers"
                  :key="data.header"
                  class="addNumber__list-content"
              >
                <h5 class="addNumber__list-header">{{ data.header }}</h5>
                <div
                    v-for="number in data.numbers"
                    :key="number.gsid"
                    class="addNumber__list-numbers"
                >
                  <span class="addNumber__list-numbers-number">{{ number.title }}</span>
                  <v-avatar
                      v-for="status in number.status_id"
                      :key="status"
                      :color="displayStatus(status)"
                      size="20"
                      class="addNumber__list-numbers-status"
                  >
                  </v-avatar>
                  <v-row no-gutters justify="end">
                    <v-btn icon @click.native="updateNumber(number)">
                      <v-icon
                          small
                      >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click.native="deleteNumber(number)">
                      <v-icon
                          small
                      >mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" md="12" sm="12">
          <v-card width="500" class="addNumber__addCard">
            <v-card-title class="addNumber__header">
              <span class="headline">Neue Telefonnummer</span>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-form v-model="valid" lazy-validation ref="numberForm">
                <v-stepper
                    v-model="stepper"
                    vertical
                    class="addNumber__stepper"
                >
                  <v-stepper-step
                      :complete="stepper > 1"
                      step="1"
                  >
                    Telefonnummer eingeben
                    <small v-if="number.trim() !== ''">{{ number }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-text-field
                        v-model="number"
                        :color="$vuetify.theme.dark ? 'secondary' : ''"
                    ></v-text-field>
                    <v-btn
                        color="primary"
                        @click="stepTel"
                    >
                      Weiter
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-step
                      :complete="stepper > 2"
                      step="2"
                  >
                    Standort auswählen
                    <small v-if="selectedLocation">{{ selectedLocation.title }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-select
                        v-model="selectedLocation"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="$store.getters['dataManagement/locations']"
                        item-text="title"
                        return-object
                        :item-color="$vuetify.theme.dark ? 'secondary' : ''"
                        :color="$vuetify.theme.dark ? 'secondary' : ''"
                        :rules="[rules.required]"
                    ></v-select>
                    <v-btn
                        color="primary"
                        @click="stepLoc"
                    >
                      Weiter
                    </v-btn>
                    <v-btn
                        text
                        @click="stepper = 1"
                    >
                      Zurück
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-step
                      :complete="stepper > 3"
                      step="3"
                  >
                    Status auswählen
                  </v-stepper-step>

                  <v-stepper-content step="3">
                    <div class="addNumber__status-row">
                      <div v-for="status in computedStatus" :key="status.gsid" class="addNumber__status">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-avatar :color="status.color"
                                      size="25"
                                      :class="selected(status) ? 'addNumber__status-avatar--active' : ''"
                                      @click="selectStatus(status)" v-on="on">
                            </v-avatar>
                          </template>
                          <span>{{ status.title }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <v-btn
                        color="primary"
                        @click="addNumber"
                    >
                      Speichern
                    </v-btn>
                    <v-btn
                        text
                        @click="stepper = 2"
                    >
                      Zurück
                    </v-btn>
                  </v-stepper-content>
                </v-stepper>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <ConfirmDialog ref="confirmDeleteNumber"/>
  </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
export default {
  name: "AddNumberDialog",
  components: {ConfirmDialog},
  props: {
    open: Boolean
  },
  data: () => ({
    stepper: 1,
    selectedLocation: null,
    selectedStatus: [],
    number: "",
    rules: {
      required: value => !!value || 'Erforderlich.',
    },
    valid: false,
    editNumber: null
  }),
  watch: {
    openDialog(value) {
      if (value) {
        this.resetDialog();
      }
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    },
    computedStatus() {
      if (this.selectedLocation) {
        return this.$store.getters["dataManagement/status"].filter(e =>
            !this.numbers.some(x =>
                x.gsid !== this.editNumber &&
                x.status_id.some(y => y === e.id) &&
                x.location_id[0] === this.selectedLocation.id
            ));
      }

      return this.$store.getters["dataManagement/status"];
    },
    numbers() {
      if (this.$store.state.auth.loggedInUser.number_ids) {
        return this.$store.getters["dataManagement/numbers"].filter(e =>
            this.$store.state.auth.loggedInUser.number_ids.some(id => id === e.id));
      }

      return [];
    },
    sortedNumbers() {
      const numbers = this.numbers.reduce((r, e) =>
      {
        const header = this.$store.getters["dataManagement/locations"].find(location => location.id === e.location_id[0]).title;

        if (!r[header])
        {
          r[header] = { header: header, numbers: [e] };
        }
        else
        {
          r[header].numbers.push(e);
        }

        return r;
      }, {});

      return Object.values(numbers).sort((a, b) => a.header.localeCompare(b.header));
    }
  },
  methods: {
    selected(status) {
      return this.selectedStatus.some(status_id => status_id === status.id.toString());
    },
    selectStatus(status) {
      if (!this.selected(status)) {
        this.selectedStatus.push(status.id.toString());
      } else {
        const index = this.selectedStatus.findIndex(status_id => status_id === status.id.toString());
        this.selectedStatus.splice(index, 1);
      }
    },
    addNumber() {
      if (this.$refs.numberForm.validate() && this.selectedStatus.length > 0) {

        const fixedNumber = this.number === "Leere Telefonnummer" ?
            "Empty-" + this.$store.state.auth.loggedInUser.gsid :
            this.number;

        if (this.editNumber) {
          this.$store.dispatch("number/updateNumber", {
            number: fixedNumber,
            status_id: this.selectedStatus,
            location_id: [this.selectedLocation.id],
            gsid: this.editNumber
          }).then(() => {
            this.resetDialog();
          });
        } else if (!this.$store.getters["dataManagement/numbers"].some(e => e.title === fixedNumber)) {

          this.$store.dispatch("number/addNumber", {
            number: fixedNumber,
            status_id: this.selectedStatus,
            location_id: [this.selectedLocation.id]
          }).then(number => {
            this.$store.commit("auth/UPDATE_PROFILE_NUMBERS", number.id);
            this.$store.dispatch("request/changeProfile").then(() => {
              this.resetDialog();
            });
          });

        } else {

        }
      } else {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          text: "Es muss mindestens ein Status ausgewählt werden",
          color: "error"
        });
      }
    },
    updateNumber(number) {
      const location = this.$store.getters["dataManagement/locations"].find(e => e.id === number.location_id[0]);
      this.number = number.title;
      this.selectedLocation = location;
      this.selectedStatus = number.status_id;
      this.editNumber = number.gsid;
    },
    displayStatus(status_id) {
      return this.$store.getters["dataManagement/status"].find(e => e.id === status_id).color;
    },
    stepTel() {
      if (!this.number.match(/^\+?([0-9]{2})\)?[-. ]?([0-9]+)[-. ]?([0-9]+)[-. ]?([0-9]+)[-. ]?([0-9]+)$/)) {
        this.$refs.confirmDeleteNumber.open("Leere Telefonnummer?", "Das Format passt nicht, leere Telefonnumer verwenden?", 275)
            .then(confirm => {
              if (confirm) {
                this.number = "Leere Telefonnummer";
                this.checkTel();
              }
            });
      } else {
        this.checkTel();
      }
    },
    checkTel() {
      const fixedNumber = this.number === "Leere Telefonnummer" ?
          "Empty-" + this.$store.state.auth.loggedInUser.gsid :
          this.number;

      if (this.$store.getters["dataManagement/numbers"].some(e => e.title === fixedNumber) && !this.editNumber) {
        this.$refs.confirmDeleteNumber.open(
            "Telefonnummer vorhanden!",
            "Die eingegebene Telefonnummer ist bereits eingetragen, soll diese hinzugefügt werden?",
            275)
            .then(confirm => {
              if (confirm) {
                const number = this.$store.getters["dataManagement/numbers"].find(e => e.title === fixedNumber);

                this.$store.commit("auth/UPDATE_PROFILE_NUMBERS", number.id);
                this.$store.dispatch("request/changeProfile").then(() => {
                  this.resetDialog();
                });
              }
            });
      } else {
        this.stepper = 2;
      }
    },
    stepLoc() {
      if (this.$refs.numberForm.validate()) {
        this.stepper = 3;
      }
    },
    resetDialog() {
      this.number = "";
      this.selectedLocation = null;
      this.selectedStatus = [];
      this.editNumber = null;
      this.stepper = 1;
    },
    deleteNumber(number) {
      this.$refs.confirmDeleteNumber.open(
          "Telefonnummer löschen!",
          "Wollen sie folgende Telefonnummer: " + number.title + " wirklich löschen?",
          350)
          .then(confirm => {
            if (confirm) {
              this.$store.dispatch("number/deleteNumber", number.gsid);
            }
          });
    }
  }
}
</script>

<style scoped lang="scss">
.addNumber__status-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 1rem;

  .addNumber__status {
    padding: 0.25rem;
  }
}

.addNumber__status-avatar--active {
  border: 2px solid black !important;
}

.addNumber__header {
  height: 68px;
}

.addNumber__addCard {
  .addNumber__stepper {
    background-color: inherit;
    box-shadow: unset;
  }
}

.addNumber__list-content {

  &:not(:first-child) {
    padding-top: 0.5rem;
  }

  .addNumber__list-header {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 0.5rem;
  }

  .addNumber__list-numbers {
    padding-top: 0.25rem;
    display: flex;
    flex-direction: row;

    .addNumber__list-numbers-status {
      margin-left: 0.5rem;
    }

    .addNumber__list-numbers-number {
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .addNumber__list-numbers-icon {

      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
  }
}
</style>