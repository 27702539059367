<template>
  <v-toolbar id="header__toolbar" flat color="primary" max-height="64" :style="style">
    <div class="logo hidden-xs-only" @click="openDashboard">
      <v-img
          v-if="$vuetify.theme.dark"
          alt="Six Logo"
          class="shrink mr-2"
          contain
          src="../../../public/img/logos/six_logo_dark.svg"
          transition="scale-transition"
          height="35"
          width="40"/>
      <v-img
          v-else
          alt="Six Logo"
          class="shrink mr-2"
          contain
          src="../../../public/img/logos/six_logo.svg"
          transition="scale-transition"
          height="35"
          width="40"/>
      <v-toolbar-title>
        <span class="title">In/Out</span>
      </v-toolbar-title>
    </div>
    <v-spacer class="hidden-xs-only"/>
    <v-btn v-if="$store.state.auth.loggedInUser" depressed class="mr-2" @click="toggleMenu" :color="$vuetify.theme.dark ? '' : '#275f74'">
      Menü <v-icon class="ml-1">mdi-menu</v-icon>
    </v-btn>
    <v-text-field
        v-if="$store.state.auth.loggedInUser"
        aria-label="Suche nach Name, E-Mail, Telefonnummer..."
        v-model="$store.state.layout.searchText"
        placeholder="Suchen..."
        append-icon="mdi-magnify"
        color="secondary"
        hide-details />
    <v-spacer/>
    <v-toolbar-items class="hidden-sm-and-down" v-if="$store.state.auth.loggedInUser">
      <template v-for="(item, index) in nav">
        <v-menu
            rounded="t-0 b-xm"
            offset-y
            v-if="item.children"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :key="index"
                text
            >
              {{ item.text }}
            </v-btn>
          </template>

          <v-list color="primary">
            <v-list-item
                v-for="child in item.children"
                :key="child.title"
                link
                style="height: 48px"
                @click="child.func"
            >
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            v-else
            :key="index"
            @click="item.func"
            text
        >{{ item.text }}</v-btn>
      </template>
    </v-toolbar-items>
    <v-btn icon class="hidden-md-and-up" @click="openMenu" v-if="$store.state.auth.loggedInUser">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <div v-if="$vuetify.breakpoint.smAndDown && $store.state.auth.loggedInUser" id="mobile_menu" class="header__mobile-menu">
      <v-card>
        <v-list color="primary">
          <template v-for="(item, index) in nav">
            <v-list-group
                v-if="item.children"
                v-model="item.active"
                :key="index"
                no-action
                @input="openSubMenu($event)"
            >
              <v-list-item slot="activator" style="padding: 0">
                <v-list-item-action>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-for="subItem in item.children"
                  :key="subItem.title"
                  @click="subItem.func"
                  class="header__mobile-menu-sub-items"
              >
                <v-list-item-action>
                  <v-icon v-if="subItem.icon">{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
                :key="index"
                @click="item.func"
                v-else
            >
              <v-list-item-action>
                <v-icon v-if="item.icon">{{item.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item inactive style="height: 48px" v-if="user">
            <v-list-item-action>
              <v-avatar :color="userStatus"
                        size="25"
                        @click="showStatus = !showStatus"
              >
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content class="header__mobile-menu-status">
              <template v-if="showStatus">
                <v-row no-gutters>
                  <v-col v-for="(status, index) in filterStatus" :key="index" cols="1" @click="changeStatus(status)">
                    <v-avatar :color="status.color"
                              size="25"
                    >
                    </v-avatar>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col>{{ user.title }}</v-col>
                  <v-col style="padding-bottom: 5px">
                    <v-text-field hide-details v-model="user.info" color="secondary" @change="updateInfo()"></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <v-progress-linear
        :active="!$store.state.offline.connected"
        :indeterminate="!$store.state.offline.connected"
        absolute
        bottom
        color="secondary"
    ></v-progress-linear>
    <v-dialog
				ref="dialog"
				v-model="dialogDates"
				@keydown.esc="dialogDates = false"
				width="290px">
			<v-date-picker v-model="dates"
						   range
						   @change="changeDates()"
						   :min="new Date().toISOString().substr(0, 10)"
						   first-day-of-week="1"
			></v-date-picker>
		</v-dialog>
  </v-toolbar>
</template>

<script>
	export default {
		name: 'HeaderPage',
		data: vm => ({
			dialogDates: false,
			dates: ['', ''],
      nav: [
        {
          icon: 'mdi-view-dashboard',
          text: 'In/Out',
          title: 'Back to Home page',
          func: vm.openDashboard,
          active: true
        },
        {
          icon: 'mdi-calendar',
          text: 'Buchen',
          title: 'About this demo',
          func: vm.openBooking,
          active: false
        },
        {
          icon: 'mdi-help-circle-outline',
          text: 'Hilfe',
          title: 'Some stuff that needs doing',
          active: false,
          children: [
	          {
		          title: 'Sicherheitsvorfall melden',
		          text: 'Sicherheitsvorfall melden',
		          icon: 'mdi-account-question-outline',
		          func: vm.openReport
	          },
	          {
		          title: 'How-To-Six',
		          text: 'How-To-Six',
		          icon: 'mdi-account-question-outline',
		          func: vm.openHowTo
	          },
            {
              title: 'Changelog',
              text: 'Changelog',
              icon: 'mdi-bug-outline',
              func: vm.openChangelog
            }
          ]
        },
        {
          icon: 'mdi-account',
          text: 'Profil',
          title: 'Our Contact info',
          func: vm.openSettings,
          active: false
        }
      ],
      active: false,
      showStatus: false
		}),
		watch: {
			dialogDates(val)
			{
				if (!val
					&& ((this.dates[0] === '' || this.dates[0] === undefined) || (this.dates[1] === '' || this.dates[1] === undefined))
					&& this.$store.state.auth.loggedInUser !== null)
				{
					const user = this.$store.getters["dataManagement/users"].find(e => e.gsid === this.$store.state.auth.loggedInUser.gsid);
					user.status_id = this.$store.state.auth.bLoggedInUser.status_id;
					this.dates = ['', ''];
				}
			}
		},
		computed: {
      user() {
		    return this.$store.state.auth.loggedInUser;
      },
      status() {
        return this.$store.getters["dataManagement/status"];
      },
      userStatus() {
        if (this.status.length > 0) {
          return this.status.find(e => e.id === this.user.status_id[0]).color;
        }

        return "red";
      },
      filterStatus()
			{
				return this.status.filter(e => e.id !== this.user.status_id[0]);
			},
      style() {
			  return {
			    "--mobile-menu-height": (this.active ? "384" : "240") + "px",
          "--mobile-menu-transform": (this.active ? "192" : "120") + "px"
        }
      }
		},
		methods: {
      openSubMenu(event) {
        this.active = event;
      },
			logout() {
			  this.$store.dispatch("auth/logout").then(() => {
          this.$router.push("login");
        });
			},

			changeStatus(status)
			{
				if (this.user.dates !== ['', ''])
				{
          this.user.dates = ['', ''];
				}

        if (this.user.number_ids)
        {
          const userNumber = this.user.number_ids.find(e => e.status_id === this.user.status_id[0] && e.location_id === this.user.location[0]);
          if (userNumber)
          {
            this.user.status_number = [userNumber.id.toString()];
          }
        }

        this.user.status_id = [status.id.toString()];

        const status_gsid = this.$store.getters["dataManagement/status"].find(e => e.id === status.id.toString()).gsid;
        if (status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.288.de" || status_gsid === "sdevphpweb_sixsixcmsphp73paul.c.289.de")
				{
					this.dialogDates = true;
				}
				else
				{
					this.$store.dispatch("request/changeInfo", this.user).then(() => {
					  this.showStatus = false;
          })
				}
			},

			changeDates()
			{
				if (this.dates[0] !== '' && this.dates[1] !== '')
				{
					if (this.dates[0] > this.dates[1])
					{
						this.dates[1] = [this.dates[0], this.dates[0] = this.dates[1]][0];
					}

					this.user.dates = this.dates;
					this.$store.dispatch("request/changeInfo", this.user).then(() => {
            this.dates = ['', ''];
            this.dialogDates = false;
            this.showStatus = false;
          })
				}
			},

			updateInfo()
			{
				this.$store.dispatch("request/changeInfoText", this.user);
			},

      /*
       * Funktionen zum navigieren
       */
      openSettings()
      {
        this.$router.push("profile");
      },

      openMenu() {
			  document.getElementById("mobile_menu").classList.toggle("header__mobile-menu--active");
      },

			openDoku()
			{
				window.open('https://confluence.six.de/pages/viewpage.action?pageId=53546706');
			},

			openChangelog()
			{
				this.$store.state.changelog.displayChangelog = true;
			},

      openDashboard() {
			  if (this.$route.path !== "/") {
          this.$router.push("/");
        }
      },

      openBooking() {
        if (this.$route.path !== "/booking") {
          this.$router.push("booking");
        }
      },

      openHelpdesk() {
        window.open('https://jira.six.de/servicedesk/customer/portal/3');
      },
			openHowTo() {
				window.open('https://six-offene-systeme.atlassian.net/wiki/spaces/SIX/pages/27133781/How-To-Six');
			},
			openReport() {
				window.open('https://six-offene-systeme.atlassian.net/wiki/spaces/UTS/pages/427229269/So+meldest+du+einen+Sicherheitsvorfall');
			},
      toggleMenu() {
        this.$store.commit("layout/TOGGLE_MENU")
      }
		},
	}
</script>

<style lang="scss">
#header__toolbar  {
  position: relative;
  z-index: 25;

  .v-toolbar__content {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    z-index: unset;
    background-color: inherit;
  }
}

.logo {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 16px;
}

.v-badge--icon .v-badge__badge {
  padding: 0;
}

.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 0;
}

.header__mobile-menu {
  width: 100%;
  position: absolute;
  margin-top: 56px;
  z-index: -1;
  transform: translateY(-198px);
  height: 0;
  transition: all .5s ease-out;
  overflow: hidden;

  &.header__mobile-menu--active {
    transition: all 0.5s ease-out;
    height: var(--mobile-menu-height);
    transform: translateY(var(--mobile-menu-transform));
  }

  .v-card {
    border-radius: unset;
  }

  .v-card .v-list {
    padding: 0;

    .v-list-group--active .v-list-group__items .header__mobile-menu-sub-items {
      padding-left: 30px;
    }

    //Fix icon color
    .v-list-group .v-list-group__header.theme--dark .v-list-item__icon {
      color: white;
    }
  }
}

.header__mobile-menu-status {
  padding: 0 !important;

  .row {
    align-items: center;
  }

  .row .col {
    padding-top: 0;
    padding-bottom: 0;
    flex: unset;
    width: unset;

    &:not(:first-child) {
      padding-left: 0.25rem;
    }
  }
}
</style>
