<template>
  <v-dialog v-model="openDialog" v-if="currentArticle !== null">
    <v-card>
      <v-card-title>
        {{ currentArticle.title }}
      </v-card-title>
      <v-card-text>
        <div>{{ currentArticle.body }}</div>
        <div class="text-right">{{ currentArticle.datetime.slice(0, 10).split('-').reverse().join('.') }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewsDialog",
  props: {
    open: Boolean,
    currentArticle: Object
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    }
  }
}
</script>

<style scoped>

</style>