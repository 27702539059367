<template>
  <v-dialog v-model="open" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :style="$vuetify.breakpoint.xsOnly ? 'border-radius: unset' : ''">
      <v-card-title>
        Changelog
        <v-spacer/>
        <v-btn icon @click="$store.state.changelog.displayChangelog = false" v-if="$vuetify.breakpoint.xsOnly" style="margin-right: 10px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="padding: 0">
        <v-expansion-panels
            v-model="panel"
            multiple
        >
          <v-expansion-panel v-for="version in versions" :key="version.title">
            <v-expansion-panel-header>Version: {{ version.title }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels accordion>
                <v-expansion-panel
                    v-for="(el ,i) in version.content"
                    :key="i"
                >
                  <v-expansion-panel-header v-slot="{ open }">
                    <v-row no-gutters>
                      <v-col cols="8">{{ el.title }}</v-col>
                      <v-col
                          v-if="el.chip"
                          cols="4"
                          class="text--secondary"
                      >
                        <v-chip
                            x-small
                            :color="el.chip.color"
                            text-color="white"
                        >
                          {{ el.chip.text }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ el.text }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
	export default {
		name: "Changelog",
		computed: {
		  open: {
		    get() {
          return this.$store.state.changelog.displayChangelog;
        },
        set(value) {
          this.$store.state.changelog.displayChangelog = value;
        }
      },
			versions()
			{
				let arr = [];
				this.$store.getters["changelog/changelog"].forEach(log =>
				{
					arr.push(log.title);
				});
				arr = arr.map(a => a.split('.').map(n => +n+100000).join('.')).sort()
					.map(a => a.split('.').map(n => +n-100000).join('.'));

				return this.$store.getters["changelog/changelog"].sort((a, b) =>
				{
					return arr.indexOf(b.title) - arr.indexOf(a.title);
				});
			}
		},
		data: () => ({
			panel: [0],
		}),
	}
</script>