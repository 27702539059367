<template>
  <div>
    <v-layout
        v-if="$store.state.layout.show === 0"
        v-masonry
        origin-left="true"
        transition-duration="1s"
        item-selector=".item"
        class="masonary">
      <v-flex v-masonry-tile
              class="item pa-1"
              v-for="(group, id) in filterGroup" v-bind:key="id.id"
              style="width: 375px">
        <GroupTemplate :groupHeader=group @updateDom="updateDom"></GroupTemplate>
      </v-flex>
    </v-layout>
    <v-layout
        v-else-if="$store.state.layout.show === 1"
        v-masonry
        origin-left="true"
        transition-duration="1s"
        item-selector=".item"
        class="masonary">
      <v-flex v-masonry-tile
              class="item pa-1"
              v-for="(group, id) in groupLocation" v-bind:key="id.id"
              style="width: 375px">
        <GroupTemplate :groupHeader=group @updateDom="updateDom"></GroupTemplate>
      </v-flex>
    </v-layout>
    <v-layout
        v-else-if="$store.state.layout.show === 2"
        v-masonry
        origin-left="true"
        transition-duration="1s"
        item-selector=".item"
        class="masonary">
      <v-flex v-masonry-tile
              class="item pa-1"
              v-for="(group, id) in groupCustom" v-bind:key="id.id"
              style="width: 375px">
        <GroupTemplate :groupHeader=group @updateDom="updateDom"></GroupTemplate>
      </v-flex>
    </v-layout>
    <v-btn
        v-scroll="onScroll"
        v-show="auto_scroll_Top && $vuetify.breakpoint.xsOnly"
        fab
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
        width="40"
        height="40"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import GroupTemplate from './GroupTemplate'

export default {
  name: 'Home',
  components: {
    GroupTemplate,
  },
  created()
  {

  },
  computed: {
    //Filtert die Gruppen, ob sie angezeigt werden sollen oder nicht
    groupLocation()
    {
      if (this.$store.state.layout.sort_location)
      {
        return this.$store.getters["dataManagement/locations"].filter(c => c.show).sort((a, b) =>
        {
          return this.$store.state.layout.sort_location.indexOf(a.id) - this.$store.state.layout.sort_location.indexOf(b.id);
        })
      }
      else
      {
        return this.$store.getters["dataManagement/locations"].filter(c => c.show);
      }
    },
    filterGroup()
    {
      if (this.$store.state.layout.sort_group)
      {
        return this.$store.getters["dataManagement/groups"].filter(c => c.show && c.text_type === "general").sort((a, b) =>
        {
          return this.$store.state.layout.sort_group.indexOf(a.id) - this.$store.state.layout.sort_group.indexOf(b.id);
        }).concat(this.$store.getters["dataManagement/groups"].filter(c => c.show && c.text_type === "custom"));
      }
      else
      {
        return this.$store.getters["dataManagement/groups"].filter(c => c.show);
      }
    },
    groupCustom()
    {
      return this.$store.getters["dataManagement/groups"].filter(c => c.text_type === "custom");
    }
  },
  data()
  {
    return {
      timer: null,
      //FAB
      auto_scroll_Top: false,
    }
  },
  methods: {
    updateDom()
    {
      clearTimeout(this.timer);
      this.timer = setTimeout(() =>
      {
        this.$nextTick
        (
            this.$redrawVueMasonry(),
        );
      }, 100);
    },

    onScroll(e)
    {
      if (typeof window === 'undefined')
      {
        return
      }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.auto_scroll_Top = top > 50

      if (this.$store.state.currentUser !== null || this.$store.state.showUserInfo !== null)
      {
        this.$store.state.currentUser = null;
        this.$store.state.showUserInfo = null;
        this.$store.state.showUserInfoBlock = true;
      }
    },
    toTop()
    {
      this.$vuetify.goTo(0)
    },
  }
}
</script>