<template>
  <v-dialog v-model="dialogHelpSlack" width="300">
    <v-card>
      <v-card-text style="padding: 0">
        <v-img src="https://i.ibb.co/Fzr14vg/info-Slack-ID.png">
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelpSlackDialog",
  props: {
    open: Boolean
  },
  computed: {
    dialogHelpSlack: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    }
  }
}
</script>

<style scoped>

</style>