import idb from "@/dbUser";

const state = {
	iDBUsers: [],
	iDBGroups: [],
};

const getters = {

};

const actions = {
	//Add User + Group from Serverdatabase to iDB
	addData: ({ dispatch, state, rootState }) =>
	{
		idb.getUser().then(users => {
			state.iDBUsers = users;

			rootState.dataManagement.users.forEach(user =>
			{
				if (!state.iDBUsers.some(u => u.gsid === user.gsid))
				{
					dispatch("saveUser", user);
				}
				else
				{
					state.iDBUsers.forEach(b =>
						{
							if (user.gsid === b.gsid && b.divisions)
							{
								user.divisions = user.divisions.concat(b.divisions.filter((i) => user.divisions.indexOf(i) < 0
									&& state.iDBGroups.find(e => e.text_type === 'custom' && i === e.title) !== undefined));

								dispatch("saveUser", user);
							} else if (!b.divisions || !b.mails) {
								dispatch("saveUser", user);
							}
						}
					);
				}
			});
		})
	},

	addDataGroup: ({ dispatch, commit, state, rootState }) =>
	{
		idb.getGroups().then(groups => {
			state.iDBGroups = groups;
			rootState.dataManagement.groups.forEach(group =>
			{
				if (!state.iDBGroups.some(g => g.gsid === group.gsid))
				{
					dispatch("saveGroup", group);
				}
				else
				{
					if (state.iDBGroups.find((i) => i.gsid === group.gsid).change_date !== group.change_date)
					{
						dispatch("saveGroup", group);
					}

					state.iDBGroups.forEach(b =>
						{
							if (group.gsid === b.gsid)
							{
								group.show = b.show;
							}
						}
					);
				}
			});

			//Custom Gruppen hinzufügen
			const custom_groups = state.iDBGroups.filter(c => c.text_type === "custom");
			custom_groups.forEach(element =>
			{
				commit("dataManagement/ADD_GROUP", element, { root: true });
			});
		});
	},

	//Vorerst deaktiviert
	addDataLocation: ({ state, rootState }) =>
	{
		idb.getLocations().then(locations => {
			rootState.dataManagement.locations.forEach(location =>
			{

				if (locations.find((i) => i.gsid === location.gsid) === undefined)
				{
					delete location.lsid;
					delete location.extid;
					delete location.creation_date;
					delete location.online_date;
					delete location.offline_date;
					delete location.deadline_date;
					delete location.status;
					delete location.published;
					delete location.workcopy;
					delete location.user_change;
					delete location.template_id;
					delete location.change_sysid;
					delete location.change_stamp;
					delete location.env_key;
					delete location.deleted;
					delete location.language_iso;
					delete location.user;

					idb.addLocationDB(location);
				}
				else
				{
					if (locations.find((i) => i.gsid === location.gsid).change_date !== location.change_date)
					{
						delete location.lsid;
						delete location.extid;
						delete location.creation_date;
						delete location.online_date;
						delete location.offline_date;
						delete location.deadline_date;
						delete location.status;
						delete location.published;
						delete location.workcopy;
						delete location.user_change;
						delete location.template_id;
						delete location.change_sysid;
						delete location.change_stamp;
						delete location.env_key;
						delete location.deleted;
						delete location.language_iso;
						delete location.user;

						idb.addGroupDB(location);
					}

					locations.forEach(locationIDB =>
						{
							if (location.gsid === locationIDB.gsid)
							{
								location.show = locationIDB.show;
							}
						}
					);
				}
			});
		});
	},
	//User IDB
	getUsers: ({ commit }) =>
	{
		const users = idb.getUser();
		commit("dataManagement/ADD_USERS", users, { root: true });
	},

	saveUser(context, user)
	{
		delete user.lsid;
		delete user.extid;
		delete user.creation_date;
		delete user.online_date;
		delete user.offline_date;
		delete user.deadline_date;
		delete user.status;
		delete user.published;
		delete user.workcopy;
		delete user.user_change;
		delete user.group_id;
		delete user.template_id;
		delete user.change_sysid;
		delete user.change_stamp;
		delete user.env_key;
		delete user.deleted;
		delete user.language_iso;
		delete user.user;
		delete user.password;

		idb.addUserDB(user);
	},

	getGroups: ({ commit }) =>
	{
		const groups = idb.getGroups();
		commit("dataManagement/ADD_GROUP_DATA", groups, { root: true });
	},

	saveGroup(context, group)
	{
		delete group.lsid;
		delete group.extid;
		delete group.creation_date;
		delete group.online_date;
		delete group.offline_date;
		delete group.deadline_date;
		delete group.status;
		delete group.published;
		delete group.workcopy;
		delete group.user_change;
		delete group.template_id;
		delete group.change_sysid;
		delete group.change_stamp;
		delete group.env_key;
		delete group.deleted;
		delete group.language_iso;
		delete group.user;

		idb.addGroupDB(group);
	},

	deleteGroup: ({ commit }, groupID) =>
	{
		idb.deleteGroupDB(groupID).then(() => {
			commit('dataManagement/DELETE_GROUP', groupID, { root: true });
		});
	},

	//Location IDB
	getLocations: ({ commit }) =>
	{
		const locations = idb.getLocations();
		commit("dataManagement/ADD_LOCATION_DATA", locations, { root: true });
	},

	saveLocation(context, location)
	{
		idb.addLocationDB(location);
	},

	deleteLocation(context, locationID)
	{
		idb.deleteLocationDB(locationID);
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};