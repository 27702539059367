<template>
  <v-dialog
      ref="dialog"
      v-model="openDialog"
      @keydown.esc="openDialog = false"
      width="290px">
    <v-date-picker v-model="dates"
                   range
                   @change="changeDates()"
                   :min="today"
                   first-day-of-week="1"
    ></v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "DatesDialog",
  props: {
    open: Boolean,
    user: Object
  },
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    dates: ['', ''],
    preventBackUp: false
  }),
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      }
    }
  },
  watch: {
    openDialog(opened)
    {
      if (!opened &&
          ((this.dates[0] === '' || this.dates[0] === undefined) || (this.dates[1] === '' || this.dates[1] === undefined)) &&
          !this.preventBackUp)
      {
        this.$emit("loadBackUp");
        this.dates = ['', ''];
      } else {
        this.preventBackUp = false;
      }
    }
  },
  methods: {
    changeDates()
    {
      if (this.dates[0] !== '' && this.dates[1] !== '')
      {
        if (this.dates[0] > this.dates[1])
        {
          this.dates[1] = [this.dates[0], this.dates[0] = this.dates[1]][0];
        }

        const user = this.$store.getters["dataManagement/users"].find(e => e.gsid === this.user.gsid);

        user.dates = this.dates;
        this.preventBackUp = true;
        this.dates = ['', ''];
        this.$store.dispatch("request/changeInfo", user);
        this.openDialog = false;
      }
    },
  }
}
</script>

<style scoped>

</style>