<template>
  <div id="detailCard" :class="$vuetify.theme.dark ? '--theme-dark' : ''">
    <div class="detailCard__row">
      <div class="detailCard__col-avatar" v-if="user.image">
        <v-avatar
            class="detailCard__avatar"
            color="grey"
            size="115"
            tile>
          <v-img :src="image">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0"
                     align="center"
                     justify="center">
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </div>
      <div class="detailCard__col-content">
        <div class="detailCard__content-header">
          <div class="detailCard__content-header-text">
            <span>{{ user.name }} {{ user.surname }}</span>
          </div>
          <div class="detailCard__content-header-actions">
            <v-btn icon @click="notify">
              <v-icon
                  size="20"
              >{{ bell }}</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="detailCard__content-info">
          <span>{{ location.title }} {{ event ? " - " + event : "" }}</span>
          <span>{{ location.street }}</span>
          <div class="detailCard__content-info-numbers">
            <div v-for="(number, index) in userNumbers" :key="number.gsid" class="detailCard__content-info-number">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                <span v-on="on">{{ checkNumber(number) }}
									<v-btn
                      v-if="user.number_ids.length > 1 && index === 0"
                      icon
                      @click="show_all_numbers = !show_all_numbers"
                      x-small
                      style="margin-left: 0.5rem"
                  >
										<v-icon>{{ show_all_numbers ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
									</v-btn>
								</span>
                </template>
                <span>{{ formatTooltip(number) }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="detailCard__content-info-actions">
            <v-btn
                icon
                :href="`mailto:${user.mails[0]}`"
                target="_blank"
            >
              <v-icon>
                mdi-email
              </v-icon>
            </v-btn>
            <v-btn
                v-if="user.slack !== ''"
                icon
                :href="'slack://user?team=' + $store.state.social.slackTeamID + '&id=' + user.slack">
              <v-icon>
                mdi-slack
              </v-icon>
            </v-btn>
            <v-btn
                v-if="user.skype !== ''"
                icon
                :href="'skype:' + user.skype + '?chat'">
              <v-icon>
                mdi-skype
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import displayEvent from "@/mixins/displayEvent";

export default {
  name: 'UserDetailCard',
  mixins: [displayEvent],
  props: {
    user: Object
  },
  mounted()
  {
    this.getImageCMS();
  },
  data: () => ({
    image: "",
    show_all_numbers: false
  }),
  computed: {
    location() {
      return this.$store.getters["dataManagement/locations"].find(e => e.id === this.user.location[0]);
    },
    userNumbers() {
      if (this.user.number_ids) {
        if (this.show_all_numbers) {
          return this.$store.getters["dataManagement/numbers"].filter(e => this.user.number_ids.some(id => id === e.id));
        }

        return this.$store.getters["dataManagement/numbers"].filter(e => this.user.number_ids.some(id => id === e.id)).slice(0, 1);
      }
      return [];
    },
    bell()
    {
      let bell = 'mdi-bell';
      if (this.$store.state.notification.notifyQueue.some(c => c.id === this.user.id))
      {
        bell = 'mdi-bell-ring';
      }
      return bell;
    },
    statusNumber()
    {
      if (this.user.number_ids) {
        const numbers = this.$store.getters["dataManagement/numbers"].filter(e => this.user.number_ids.some(id => id === e.id));

        if (numbers.find(e => e.location_id[0] === this.user.location[0] && e.status_id.some(x => x === this.user.status_number[0])))
        {
          return numbers.find(e => e.location_id[0] === this.user.location[0] && e.status_id.some(x => x === this.user.status_number[0])).title;
        }
        else if (numbers.find(e => e.status_id.some(x => x === this.user.status_number[0])))
        {
          return numbers.find(e => e.status_id.some(x => x === this.user.status_number[0])).title;
        }
        else if (numbers.length > 0)
        {
          return numbers[0].title;
        }

        return "";
      }

      return "";
    },
    event() {
      const e = this.currentEvent(this.user);
      if (e) {
        return e.category;
      }

      return false;
    }
  },
  methods: {
    //Benachrichtigung aktivieren für einen User, der gerade nicht auf "In" steht
    notify()
    {
      let delete_NotifyQueue_Element = false;
      const status_gsid = this.$store.getters["dataManagement/status"].find(e => e.id === this.user.status_id[0]).gsid;
      if (status_gsid !== "sdevphpweb_sixsixcmsphp73paul.c.283.de" &&
          status_gsid !== "sdevphpweb_sixsixcmsphp73paul.c.285.de")
      {
        if (Notification.permission === "granted" && this.$store.state.notification.notifyAllow)
        {
          if (!this.$store.state.notification.notifyQueue.some(c => c.id === this.user.id))
          {
            this.$store.state.notification.notifyQueue.push(this.user);

            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              text: "Sie werden benachrichtigt, sobald " + this.user.name + " online ist",
              color: "success"
            }, { root: true });
          }
          else
          {
            //User wieder aus Queue löschen
            const index = this.$store.state.notification.notifyQueue.findIndex(u => u.id === this.user.id);
            this.$store.state.notification.notifyQueue.splice(index, 1);
            delete_NotifyQueue_Element = true;
          }
        }
        else if (!this.$store.state.notification.notifyAllow)
        {
          this.$store.commit("snackbar/OPEN_SNACKBAR", {
            text: "Benachrichtigungen sind deaktiviert",
            color: "info"
          }, { root: true });
        }
        else
        {
          Notification.requestPermission(permission =>
          {
            if (permission === 'granted')
            {
              if (!this.$store.state.notification.notifyQueue.some(c => c.id === this.user.id))
              {
                this.$store.state.notification.notifyQueue.push(this.user);

                this.$store.commit("snackbar/OPEN_SNACKBAR", {
                  text: "Sie werden benachrichtigt, sobald " + this.user.name + " online ist",
                  color: "success"
                }, { root: true });
              }
              else
              {
                //User wieder aus Queue löschen
                const index = this.$store.state.notification.notifyQueue.findIndex(u => u.id === this.user.id);
                this.$store.state.notification.notifyQueue.splice(index, 1);
                delete_NotifyQueue_Element = true;
              }
            }
            else
            {
              this.$store.commit("snackbar/OPEN_SNACKBAR", {
                text: "Benachrichtigungen wurden nicht erlaubt",
                color: "error"
              }, { root: true });
            }
          });
        }

        if (this.$store.state.notification.notifyWebPush && this.$store.state.notification.notifyAllow)
        {
          const body = {
            token: this.$store.state.auth.token,
            deleteElement: delete_NotifyQueue_Element,
            notifyUser: this.$store.state.auth.loggedInUser.id,
            user: this.user
          };
          this.$store.dispatch("notification/addNotification", body);
        }
      }
      else
      {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          text: this.user.name + " ist bereits erreichbar",
          color: "success"
        }, { root: true });
      }
    },
    getImageCMS() {
      if (this.user.image && this.image === "")
      {
        this.$store.dispatch("request/getImageCMS", this.user.image.filename)
            .then(image =>
            {
              this.image = image;
            });
      }
    },
    formatTooltip(number) {
      const location = this.$store.getters["dataManagement/locations"].find(e => e.id === number.location_id[0]).title;

      let status = [];
      number.status_id.forEach(e => {
        const title = this.$store.getters["dataManagement/status"].find(x => x.id === e).title;
        status.push(title);
      });

      return location + " | " + status.join(", ");
    },
    checkNumber(number) {
      if (number.title.split("-")[0] === "Empty") {
        return "Keine Nummer"
      }

      return number.title
    }
  }
}
</script>

<style lang="scss">
#detailCard {
  display: flex;
  width: 340px;
  background-color: #fff;
  margin-top: 185px;
  position: absolute;
  z-index: 999;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 18px 30px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);

  &.--theme-dark {
    background-color: #1E1E1E;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  }
}
.detailCard__row {
  display: flex;
  flex-direction: row;
  margin: 0.25rem;
  flex: 1;
  align-items: center;

  .detailCard__col-avatar {
    padding-top: 0.5rem;
  }

  .detailCard__col-content {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.detailCard__content-header {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .detailCard__content-header-text {
    padding-top: 0.5rem;
  }

  .detailCard__content-header-actions {

  }
}

.detailCard__content-info {
  display: flex;
  flex-direction: column;

  span {
    display: block;
    float: left;

    &:not(:first-child) {
      //padding-top: 0.25rem;
    }
  }

  .detailCard__content-info-numbers {
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;

    .detailCard__content-info-number {
      padding-top: 0.25rem;
    }
  }

  .detailCard__content-info-actions {
    display: flex;
    padding-top: 0.5rem;
  }
}
</style>