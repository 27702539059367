export default {
	methods: {
		/**
		 * Überprüft alle Termine an einem Standort, ob diese sich überschneiden und gibt dementsprechend ein Boolean zurück.
		 * @param {Object} createEvent - Termin der hinzugefügt werden soll
		 * @param {Number} index - Standort index
		 * @param {Boolean} parking - Ist der hinzugefügte Termin ein Parkplatz
		 * @returns {boolean} - Gibt es Überschneidungen bei den Terminen?
		 */
		checkDuplicated(createEvent, index, parking = false) {
			//Filtert alle Events, die vom neu erstellten Event überlappt werden.
			const events = this.$store.getters["dataManagement/locations"][index].events.filter(e => this.overlap(e, createEvent, parking));
			let maxLength = 1;

			if (!parking) {
				const tables = this.$store.getters["dataManagement/locations"][index].categories.find(e => e.name === createEvent.category).tables;
				const table = tables.find(e => e.name === createEvent.tableid);
				maxLength = table.seats;
			}

			return events.length < maxLength;
		},
		/**
		 * Vergleich von zwei Objekten (Terminen), ob sie sich zeitlich
		 * @param {Object} compare - Termin der verglichen wird
		 * @param {Object} createEvent - Termin der hinzugefügt werden soll
		 * @param {Boolean} parking - Ist der hinzugefügte Termin ein Parkplatz
		 * @returns {boolean} - Überschneiden sich die Termine?
		 */
		overlap(compare, createEvent, parking) {
			const compareStart = compare.start;
			const eventStart = createEvent.start;
			const compareEnd = compare.end;
			const eventEnd = createEvent.end;

			//Vergleicht beide Events, ob die Zeiten sich überlagern und in der gleichen Kategorie sind.
			return compare.category === createEvent.category &&
				(compare.tableid === createEvent.tableid || parking) &&
				compare.gsid !== createEvent.gsid &&
				((eventStart >= compareStart && eventStart < compareEnd) ||
					(eventEnd > compareStart && eventEnd <= compareEnd) ||
					(eventStart < compareStart && eventEnd > compareEnd)
				);
		}
	}
};
