<template>
  <v-dialog v-model="openDialog" width="950" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ edit ? "Termin bearbeiten" : "Termin hinzufügen" }}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-form v-model="valid" lazy-validation ref="bookingForm">
          <v-row>
            <v-col :cols="tables.length > 0 ? '6' : '12'">
              <v-select
                  v-model="event"
                  :items="categories"
                  item-text="name"
                  label="Auswählen..."
                  color="secondary"
                  outlined
                  hide-details
                  hide-selected
                  :rules="[rules.required]"
                  :menu-props="{ offsetY: true }"
                  :disabled="disabledCategory"
              ></v-select>
            </v-col>
            <v-col cols="6" v-if="tables.length > 0">
              <v-select
                  v-model="table"
                  :items="tables"
                  item-text="name"
                  color="secondary"
                  outlined
                  hide-details
                  hide-selected
                  :rules="[rules.required]"
                  :menu-props="{ offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                  v-model="openMenuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="formatDate(dates.startDate)"
                      label="Startdatum"
                      color="secondary"
                      prepend-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates.startDate"
                    @input="openMenuStartDate = false"
                    @change="updateEndDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                  ref="menuStartTime"
                  v-model="openMenuStartTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dates.startTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dates.startTime"
                      label="Startzeit"
                      color="secondary"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      :rules="wholeDay ? [] : [rules.required]"
                      :disabled="wholeDay"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="openMenuStartTime"
                    v-model="dates.startTime"
                    format="24hr"
                    :allowed-minutes="allowedMinutes"
                    full-width
                    @click:minute="$refs.menuStartTime.save(dates.startTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                  v-model="openMenuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="formatDate(dates.endDate)"
                      label="Enddatum"
                      color="secondary"
                      prepend-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates.endDate"
                    :min="dates.startDate"
                    @input="openMenuEndDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                  ref="menuEndTime"
                  v-model="openMenuEndTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dates.endTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dates.endTime"
                      label="Endzeit"
                      color="secondary"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      :rules="wholeDay ? [] : [rules.required]"
                      :disabled="wholeDay"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="openMenuEndTime"
                    v-model="dates.endTime"
                    format="24hr"
                    :allowed-minutes="allowedMinutes"
                    full-width
                    @click:minute="$refs.menuEndTime.save(dates.endTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
            v-model="wholeDay"
            label="Ganzer Tag"
            color="secondary"
            hide-details
            style="margin: 0; padding-top: 0; padding-left: 8px"
        ></v-checkbox>
        <v-spacer/>
        <v-btn @click.native="addEvent()" :disabled="!valid">Ok</v-btn>
        <v-btn @click.native="close()">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import checkEvents from "@/mixins/checkEvents";

export default {
  name: "BookingDialog",
  mixins: [ checkEvents ],
  props: {
    index: Number,
    categories: Array
  },
  data: () => ({
    edit: null,
    openDialog: false,
    event: null,
    table: null,
    dates: {
      startDate: new Date().toISOString().substr(0, 10),
      startTime: "",
      endDate: new Date().toISOString().substr(0, 10),
      endTime: ""
    },
    openMenuStartDate: false,
    openMenuStartTime: false,
    openMenuEndDate: false,
    openMenuEndTime: false,
    rules: {
      required: value => !!value || 'Required.',
    },
    valid: false,
    disabledCategory: false,
    wholeDay: false
  }),
  computed: {
    tables() {
      const index = this.categories.findIndex(e => e.name === this.event);
      if (this.categories[index]) {
        return this.categories[index].tables ? this.categories[index].tables : [];
      }
      return [];
    }
  },
  methods: {
    allowedMinutes: v => v % 15 === 0,
    close() {
      this.$emit("closed");
      this.event = null;
      this.dates = {
        startDate: new Date().toISOString().substr(0, 10),
        startTime: "",
        endDate: new Date().toISOString().substr(0, 10),
        endTime: ""
      };

      this.$refs.bookingForm.resetValidation();
      this.openDialog = false;
      this.edit = null;
      this.disabledCategory = false;
      this.table = null;
    },
    open(date, event = null, edit = false) {
      if (event) {
        this.event = event.category;
        this.table = event.tableid;

        this.dates.startDate = new Date(event.start).toISOString().substr(0, 10);
        this.dates.startTime = new Date(event.start).toLocaleTimeString("de-DE").substr(0, 5);
        this.dates.endDate = new Date(event.end).toISOString().substr(0, 10);
        this.dates.endTime = new Date(event.end).toLocaleTimeString("de-DE").substr(0, 5);

        if (edit) {
          this.edit = event;
        }
      } else {
        this.dates.startDate = date;
        this.dates.endDate = date;
      }

      this.openDialog = true;
    },
    openCategory(category, table, date) {
      this.event = category;
      this.table = table;
      this.dates.startDate = date;
      this.dates.endDate = date;

      this.openDialog = true;
    },
    formatDate(date) {
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    addEvent() {
      if (this.$refs.bookingForm.validate()) {
        const [startYear, startMonth, startDay] = this.dates.startDate.split('-');
        const [startHour, startMinute] = this.dates.startTime.split(':');
        const start = this.toTime({
          year: startYear,
          month: startMonth,
          day: startDay,
          hour: this.wholeDay ? '' : startHour,
          minute: this.wholeDay ? '' : startMinute
        });

        const [endYear, endMonth, endDay] = this.dates.endDate.split('-');
        const [endHour, endMinute] = this.dates.endTime.split(':');
        const end = this.toTime({
          year: endYear,
          month: endMonth,
          day: endDay,
          hour: this.wholeDay ? '23' : endHour,
          minute: this.wholeDay ? '59' : endMinute
        });

        const createEvent = {
          title: this.$store.state.auth.loggedInUser.title,
          uid: [this.$store.state.auth.loggedInUser.id],
          location: [this.$store.getters["dataManagement/locations"][this.index].id],
          start: start,
          end: end,
          timed: true,
          category: this.event,
          tableid: this.table
        };

        if (this.edit) {
          const updateEvent = {
            gsid: this.edit.gsid,
            location_id: createEvent.location[0],
            start: createEvent.start,
            end: createEvent.end,
            category: createEvent.category,
            tableid: createEvent.tableid
          };

          if (this.checkDuplicated(updateEvent, this.index, this.tables.length === 0)) {
            this.$store.dispatch("booking/updateEvent", updateEvent);
            this.close();
          } else {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              text: "Der Platz ist bereits belegt",
              color: "info"
            }, { root: true });
          }
        } else {
          if (this.checkDuplicated(createEvent, this.index, this.tables.length === 0)) {
            this.$store.dispatch("booking/setEvent", {
              event: createEvent
            });
            this.close();
          } else {
            this.$store.commit("snackbar/OPEN_SNACKBAR", {
              text: "Der Platz ist bereits belegt",
              color: "info"
            }, { root: true });
          }
        }
      }
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    updateEndDate() {
      const start = new Date(this.dates.startDate).getTime();
      const end = new Date(this.dates.endDate).getTime();

      if (start > end) {
        this.dates.endDate = JSON.parse(JSON.stringify(this.dates.startDate));
      }
    }
  }
}
</script>

<style scoped lang="scss">
.col {
  padding-bottom: 0;
}
</style>
