const apiBaseUrl = process.env.VUE_APP_URL || "";

function urlBase64ToUint8Array(base64String)
{
	let padding = '='.repeat((4 - base64String.length % 4) % 4);
	let base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	let rawData = window.atob(base64);
	let outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i)
	{
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

const state = {
	subscriptionObject: null,
	webPush_ServerKey_Public: JSON.parse(localStorage.getItem("webPush_ServerKey_Public")),
};

const getters = {
};

const actions = {
	getSubscribeKey: ({ rootState, state }) =>
	{
		return new Promise(resolve =>
		{
			const token = { token: rootState.auth.token };

			fetch(apiBaseUrl + "/getSubscribeKey",
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(token)
				})
				.then(res =>
				{
					if (res.status === 200)
					{
						return res.json();
					}
					else
					{
						resolve("failed");
					}
				})
				.then((key) =>
				{
					state.webPush_ServerKey_Public = key;
					window.localStorage.setItem("webPush_ServerKey_Public", JSON.stringify(key));
					resolve("done");
				});
		});
	},
	subscribePush: ({ state, dispatch, commit }) =>
	{
		navigator.serviceWorker.ready.then((reg) =>
		{
			reg.pushManager.getSubscription()
				.then((sub) =>
				{
					if (sub === null)
					{
						console.log("No Subscription found");
					}
				})
				.catch((err) =>
				{
					console.log(err);
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Fehler bei laden der Push-Benachrichtigung",
						color: "error"
					}, { root: true });
				});

			reg.pushManager.subscribe(
				{
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(state.webPush_ServerKey_Public)
				})
				.then((sub) =>
				{
					state.subscriptionObject = sub;
					dispatch('sendSubscription');
				})
				.catch((err) =>
				{
					console.log(err);
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Push-Benachrichtigung fehlgeschlagen, Seite bitte neu laden",
						color: "error"
					}, { root: true });
				});
		});
	},

	sendSubscription: ({ rootState, dispatch, state, commit }) =>
	{
		const wPTokenPush = {
			token: rootState.auth.token,
			title: rootState.auth.loggedInUser.id,
			key: state.subscriptionObject,
			settings: {
				bday: rootState.notification.notifyBday ? '1' : '0',
				status: rootState.notification.notifyStatus ? '1' : '0',
				event: rootState.notification.notifyEvent ? '1' : '0'
			}
		};

		fetch(apiBaseUrl + "/push-subscription",
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(wPTokenPush)
			})
			.then((res) =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
			})
			.catch(err =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Benachrichtung konnten nicht gespeichert werden",
					color: "error"
				}, { root: true });
			})
	},

	unsubscribePush: ({ dispatch, rootState, commit }) =>
	{
		const wPTokenPush = {
			token: rootState.auth.token,
			id: rootState.auth.loggedInUser.id
		};

		fetch(apiBaseUrl + "/push-unsubscribe",
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(wPTokenPush)
			})
			.then((res) =>
			{
				if (res.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else if (res.status === 500)
				{
					dispatch('error/error500', null, { root: true });
				}
				else if (res.status === 200)
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Benachrichtung deaktiviert",
						color: "success"
					}, { root: true });
				}
			})
			.catch(err =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Benachrichtungseinstellungen konnten nicht geändert werden",
					color: "error"
				}, { root: true });
			})
	},
	updateSubscription: ({ rootState, state, dispatch, commit }) => {
		let sub = {
			token: rootState.auth.token,
			id: rootState.auth.loggedInUser.id,
			endpoint: state.subscriptionObject.endpoint,
			settings: {
				bday: rootState.notification.notifyBday ? '1' : '0',
				status: rootState.notification.notifyStatus ? '1' : '0',
				event: rootState.notification.notifyEvent ? '1' : '0'
			}
		};

		fetch(apiBaseUrl + "/updateSubscribe",
			{
				method: 'POST',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(sub)
			}).then(error =>
		{
			if (error.status === 401)
			{
				dispatch('auth/reLogin', null, { root: true });
			}
			else if (error.status === 500)
			{
				dispatch('error/error500', null, { root: true });
			}
			else if (error.status === 200)
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Benachrichtungseinstellungen aktualisiert",
					color: "success"
				}, { root: true });
			}
		}).catch(error =>
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Benachrichtungseinstellungen konnten nicht aktualisiert werden",
				color: "error"
			}, { root: true });
		});
	}
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
