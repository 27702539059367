/**
 * Module for offline use
 */

const state = {
	connected: navigator.onLine,
	loadedOffline: false,
	fetchQueue: [],
	fetchQueueText: [],
	fetchQueueGroup: [],
	fetchQueueProfile: null,
	fetchQueue_New_Location: null,
	fetchQueue_Delete_Location: null,
};

const getters = {};

const actions = {
	toggleConnection: ({ dispatch }, connected) =>
	{
		if (connected) {
			dispatch("online");
		} else {
			dispatch("offline");
		}
	},
	online: ({ commit, state, dispatch }) => {
		commit('SET_CONNECTION', true);

		commit("snackbar/OPEN_SNACKBAR", {
			text: "Internetverbindung wiederhergestellt",
			color: "success"
		}, { root: true });

		//Offline Loaded
		if (state.loadedOffline)
		{
			dispatch('auth/login', null, { root: true });
			state.loadedOffline = false;
		}

		if (state.fetchQueue.length > 0)
		{
			state.fetchQueue.forEach((req) =>
			{
				dispatch("request/changeInfo", req, { root: true });
			});
		}

		if (state.fetchQueueText.length > 0)
		{
			state.fetchQueueText.forEach((req) =>
			{
				dispatch("request/changeInfoText", req, { root: true });
			});
		}

		if (state.fetchQueueGroup.length > 0)
		{
			state.fetchQueueGroup.forEach((req) =>
			{
				dispatch("request/setGroupTimer", req, { root: true });
			});
		}

		if (state.fetchQueueProfile !== null)
		{
			dispatch("request/changeProfile", state.fetchQueueProfile, { root: true });
		}

		if (state.fetchQueue_New_Location !== null)
		{
			dispatch("location/newLocationCMS", state.fetchQueue_New_Location, { root: true });
		}

		if (state.fetchQueue_Delete_Location !== null)
		{
			dispatch("location/deleteLocationCMS", state.fetchQueue_Delete_Location, { root: true });
		}

		state.fetchQueueProfile = null;
		state.fetchQueue_Delete_Location = null;
		state.fetchQueue_New_Location = null;

		state.fetchQueueGroup = [];
		state.fetchQueueText = [];
		state.fetchQueue = [];
	},
	offline: ({ commit, rootState }) => {
		commit('SET_CONNECTION', false);

		commit("snackbar/OPEN_SNACKBAR", {
			text: "Verbindung zum Server wurde unterbrochen",
			color: "info"
		}, { root: true });
	},
	loadOffline: ({ state, rootState, dispatch, commit }) => {
		state.loadedOffline = true;

		if (rootState.dataManagement.groups.length === 0 || rootState.dataManagement.locations.length === 0)
		{
			//Benachrichtigung
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Der Login wird aktiviert, sobald eine Internetverbindung besteht",
				color: "info"
			}, { root: true });
			dispatch("auth/reLogin", null, { root: true });
		}
		else
		{
			commit("snackbar/OPEN_SNACKBAR", {
				text: "Internetverbindung ist unterbrochen, das IO ist auf dem letzten aktualisierten Stand",
				color: "info"
			}, { root: true });
		}
	}
};

const mutations = {
	//Wird ausgeführt, sobald eine Internetverbindung besteht
	SET_CONNECTION(state, payload)
	{
		state.connected = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};