<template>
  <div id="groups">
    <h5 class="groups__header">Gruppen</h5>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="2">
        <h5>Abteilung</h5>
        <draggable
            v-model="groups"
            handle=".handle"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-switch
                v-for="group in groups" :key="group.gsid"
                v-model="group.show"
                @change="showUpdate(group)"
                :label="group.title"
                hide-details
                color="secondary"
            >
              <template v-slot:append>
                <v-icon class="handle" style="cursor: grab">mdi-drag</v-icon>
              </template>
            </v-switch>
          </transition-group>
        </draggable>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <h5>Standort</h5>
        <draggable
            v-model="locations"
            handle=".handle_loc"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-switch
                v-for="location in locations" :key="location.gsid"
                v-model="location.show"
                @change="showUpdate(location)"
                :label="location.title"
                hide-details
                color="secondary"
            >
              <template v-slot:append>
                <v-icon class="handle_loc" style="cursor: grab">mdi-drag</v-icon>
              </template>
            </v-switch>
          </transition-group>
        </draggable>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="3">
        <h5>Gruppen</h5>
        <v-text-field v-model="groupName"
                      label="Gruppenname"
                      :color="$vuetify.theme.dark ? 'secondary' : ''"
                      @keydown.enter="saveGroup()"
                      style="max-width: 290px">
          <v-icon slot="append" color="green" @click="saveGroup()">mdi-plus</v-icon>
        </v-text-field>
        <v-row no-gutters v-for="(group, id) in customGroups" :key="id"
               align="start" style="max-width: 300px">
          <template v-if="custom_edit !== id">
            <v-switch v-model="group.show"
                      @change="showUpdate(group)"
                      :label="group.title"
                      class="text-truncate"
                      hide-details
                      :style="$vuetify.breakpoint.xsOnly ? 'width: 170px!important;' : 'width: 190px!important;'"
                      color="secondary"/>
            <div style="padding-top: 13px; height: 44px">
              <v-btn icon color="secondary"
                     @click="edit_CustomGroup_User(group.title)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn icon color="secondary"
                     @click="custom_edit = id, custom_newTitle = group.title">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="secondary"
                     @click="deleteGroup(group)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-else>
            <v-text-field outlined
                          hide-details
                          v-model="custom_newTitle"
                          placeholder="Neuer Titel"
                          style="padding-top: 15px; width: 226px!important;"
                          :color="$vuetify.theme.dark ? 'secondary' : ''"
            >

            </v-text-field>
            <div style="padding-top: 13px; height: 44px">
              <v-btn icon color="secondary"
                     @click="edit_CustomGroup_Title(group)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon color="secondary"
                     @click="custom_edit = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <h5>Seitenleiste</h5>
        <v-switch
            color="secondary"
            v-model="$store.state.layout.showUrlaub"
            label="Urlaub"
            hide-details
            @change="changeSettings('showUrlaub', $store.state.layout.showUrlaub)"
        ></v-switch>
        <v-switch
            color="secondary"
            v-model="$store.state.layout.showKrank"
            label="Krank"
            hide-details
            @change="changeSettings('showKrank', $store.state.layout.showKrank)"
        ></v-switch>
        <v-switch
            color="secondary"
            v-model="$store.state.layout.showGeburtstag"
            label="Geburtstag"
            hide-details
            @change="changeSettings('showGeburtstag', $store.state.layout.showGeburtstag)"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <h5>Informationsgruppen</h5>
        <v-switch
            v-if="$store.state.layout.showNews !== null || $store.getters['dataManagement/news'].length > 0"
            color="secondary"
            v-model="$store.state.layout.showNews"
            label="Newsticker"
            hide-details
            @change="changeSettings('showNews', $store.state.layout.showNews)"
        ></v-switch>
        <v-switch
            color="secondary"
            v-model="$store.state.changelog.showChangelog"
            label="Changelog"
            hide-details
            @change="changeSettings('showChangelog', $store.state.changelog.showChangelog)"
        ></v-switch>
      </v-col>
    </v-row>
    <UserDialog :selected-group="selectedGroup" :open.sync="openUserDialog" @addUser="addUsersToGroup" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import UserDialog from "@/components/dialogs/profile/UserDialog";

export default {
  name: "Groups",
  components: {
    UserDialog,
    draggable,
  },
  data: () => ({
    groupName: "",
    selectedGroup: null,
    openUserDialog: false,
    drag: false,
    dragOptions: {
      animation: 200,
      disabled: false,
      ghostClass: "ghost"
    },
    custom_edit: null
  }),
  computed: {
    groups: {
          get() {
            if (this.$store.state.layout.sort_group)
            {
              return this.$store.getters["dataManagement/groups"].filter(c => c.text_type === "general").sort((a, b) =>
              {
                return this.$store.state.layout.sort_group.indexOf(a.id) - this.$store.state.layout.sort_group.indexOf(b.id);
              })
            }
            else
            {
              return this.$store.getters["dataManagement/groups"].filter(c => c.text_type === "general");
            }
          },
          set(value) {
            this.$store.dispatch("layout/sortGroups", value);
          }
        },
    locations: {
      get() {
        if (this.$store.state.layout.sort_location)
        {
          return this.$store.getters["dataManagement/locations"].sort((a, b) =>
          {
            return this.$store.state.layout.sort_location.indexOf(a.id) - this.$store.state.layout.sort_location.indexOf(b.id);
          })
        }
        else
        {
          return this.$store.getters["dataManagement/locations"];
        }
      },
      set(value) {
        this.$store.dispatch("layout/sortLocation", value);
      }
    },
    customGroups()
    {
      return this.$store.getters["dataManagement/groups"].filter(c => c.text_type === "custom");
    },
  },
  methods: {
    changeSettings(name, value)
    {
      try
      {
        localStorage.setItem(name, value);
      } catch (e)
      {
        console.log(e);
      }
    },
    saveGroup()
    {
      if (this.groupName != null
          && this.groupName.trim() !== ''
          && !this.$store.getters["dataManagement/groups"].some((e) => e.title.toLowerCase() === this.groupName.toLowerCase().trim()))
      {
        const group = {
          gsid: this.groupName,
          id: this.groupName,
          title: this.groupName,
          show: true,
          text_type: "custom"
        };

        this.groupName = null;

        //Speichern und hinzufügen der neuen Gruppe
        this.$store.dispatch("db/saveGroup", group);
        this.$store.state.dataManagement.groups.push(group);
      }
      else
      {
        //this.snackText = 'Es wurde kein Name eingegeben';
        //this.snackColor = 'error';
        //this.snackbar = true;
      }

    },

    //User einer Customgruppe zuweisen
    async addUsersToGroup(user)
    {
      if (!(user.divisions.indexOf(this.selectedGroup) > -1))
      {
        user.divisions.push(this.selectedGroup);
      }
      else
      {
        const index = user.divisions.findIndex(g => g === this.selectedGroup);
        user.divisions.splice(index, 1);
      }

      await this.$store.dispatch("db/saveUser", user);
    },

    //Gruppe löschen + User in der Gruppe
    async deleteGroup(group)
    {
      this.$store.getters["dataManagement/users"].forEach(user =>
      {
        if (user.divisions.indexOf(group.title) > -1)
        {
          const index = user.divisions.findIndex(g => g === group.title);
          user.divisions.splice(index, 1);

          this.$store.dispatch("db/saveUser", user);
        }
      });

      await this.$store.dispatch('db/deleteGroup', group.gsid);
    },

    showUpdate(groupi)
    {
      if (groupi.text_type === "general" || groupi.text_type === "custom")
      {
        const group = this.$store.getters["dataManagement/groups"].find(e => e.gsid === groupi.gsid);
        group.show = groupi.show;
        this.$store.dispatch("db/saveGroup", group);
      }
      else
      {
        const groupLoc = this.$store.getters["dataManagement/locations"].find(e => e.gsid === groupi.gsid);
        groupLoc.show = groupi.show;
        this.$store.dispatch("db/saveLocation", groupLoc);
      }
    },
    edit_CustomGroup_User(title)
    {
      this.selectedGroup = title;
      this.openUserDialog = true;
    },
    edit_CustomGroup_Title(group)
    {
      this.$store.getters["dataManagement/users"].forEach(user =>
      {
        if (user.divisions.indexOf(group.title) > -1)
        {
          const index = user.divisions.findIndex(g => g === group.title);
          user.divisions.splice(index, 1);

          user.divisions.push(this.custom_newTitle);
          this.$store.dispatch("db/saveUser", user);
        }
      });

      group.title = this.custom_newTitle;

      this.$store.dispatch("db/saveGroup", group);
      this.custom_edit = null;
    },
  }
}
</script>

<style lang="scss">
#groups {
  padding: 1rem 0;

  .groups__header {
    font-weight: 700;
    font-size: 24px;
  }
}

.ghost {
  opacity: 0.5;
}
</style>