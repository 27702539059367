<template>
  <v-app id="app">
    <Header/>
    <v-main>
      <v-container fill-height fluid class="app__container">
        <router-view v-if="ready" />
        <v-row justify="center" align="center" v-else no-gutters>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-row>
        <v-snackbar
            v-model="$store.state.snackbar.display"
            bottom
            rounded="pill"
            :color="$store.state.snackbar.color"
            content-class="snackbar__content"
            :timeout="$store.state.snackbar.timeout">
          {{ $store.state.snackbar.text }}
        </v-snackbar>
        <div class="app__dialogs">
          <About />
          <Sessions />
          <Changelog />
        </div>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import About from "@/components/dialogs/About";
import Sessions from "@/components/dialogs/Sessions";
import Changelog from "@/components/dialogs/Changelog";

export default {
  name: 'App',
  components: {
    Changelog,
    Sessions,
    About,
    Footer,
    Header
  },
  computed: mapState({
    authStatus: state => state.auth.status,
    data: state => state.dataManagement,
    busy() {
      let notReadyStatus = ["loading", "initial", "failed"];
      return notReadyStatus.includes(this.authStatus);
    },
    ready() {
      return (!this.busy &&
          this.data.locations.length !== 0 &&
          this.data.groups.length !== 0 &&
          this.data.users.length !== 0) || this.authStatus === "noAuth";
    }
  }),
  beforeCreate() {
    this.$vuetify.theme.dark = JSON.parse(localStorage.getItem("darkMode"));
    this.$store.commit("notification/UPDATE_NOTIFICATIONS");

    if (navigator.onLine) {
      this.$store.dispatch('auth/login').then(check => {
        if (check === "failed") {
          if (this.$route.path !== "/login") {
            this.$router.push("login");
          }
        }
      });
    } else {
      this.$store.dispatch('db/getUsers');
      this.$store.dispatch('db/getGroups');
      this.$store.dispatch('db/getLocations');
      this.$store.dispatch('offline/loadOffline');
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      this.$store.state.layout.windowTop = e.target.documentElement.scrollTop;
    },
  }
};
</script>

<style lang="scss">
html {
  overflow: auto;
}

html, body, #app {
  min-height: 100%;
}

#app .app__container {
  padding: 0;
}

#app .app__dialogs {
  position: absolute;
}

.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

.snackbar__content {
  text-align: center !important;
}
</style>