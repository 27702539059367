<template>
  <v-dialog v-model="$store.state.protocol.showProtocol" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Privater Log</span>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            :color="$vuetify.theme.dark ? 'secondary' : ''"
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="$store.state.protocol.list"
          :search="search"
      ></v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        data()
        {
            return {
                search: '',
                headers: [
                    {
                        text: "Uhrzeit",
                        align: "left",
                        value: "time",
                    },
                    {
                        text: "Name",
                        value: "name",
                    },
                    {
                        text: "Info",
                        value: "action",
                    },

                ]
            }
        },
        methods: {}
    }
</script>