import Vue from "vue";
const apiBaseUrl = process.env.VUE_APP_URL || "";

const state = {
	users: [],
	groups: [],
	locations: [],
	news: [],
	status: [],
	numbers: []
};

const getters = {
	groups: state => state.groups,
	users: state => state.users,
	locations: state => state.locations,
	news: state => state.news,
	status: state => state.status,
	numbers: state => state.numbers
};

const actions = {
	getData: ({ state, rootState, dispatch, commit }) =>
	{
		let object = { token: rootState.auth.token };

		fetch(apiBaseUrl + '/dataStatus',
			{
				method: 'post',
				headers: {
					'Accept': 'text/plain',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(object) //object
			})

			.then(data =>
			{
				if (data.status === 401)
				{
					dispatch('auth/reLogin', null, { root: true });
				}
				else
				{
					groups();
					location();
					users();
					numbers();
					news();
					return data.json();
				}
			})
			.then(res =>
			{
				commit('ADD_STATUS_DATA', res);
			})
			.catch(error =>
			{
				commit("snackbar/OPEN_SNACKBAR", {
					text: "Status konnte nicht abgerufen werden",
					color: "error"
				}, { root: true });
			});

		function users()
		{
			fetch(apiBaseUrl + '/dataUser',
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object) //object
				})
				.then(data =>
				{
					if (data.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
					}
					else
					{
						return data.json();
					}
				})
				.then(res =>
				{
					commit('ADD_USERS', res);
					dispatch('fixUser');
					dispatch('db/addData', null, { root: true }).then(() => {

					})
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Mitarbeiter konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});


		}

		function location()
		{
			fetch(apiBaseUrl + '/dataLocation',
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object) //object
				})
				.then(data =>
				{
					if (data.status === 401)
					{
						dispatch('auth/reLogin');
					}
					else
					{
						return data.json();
					}
				})
				.then(res =>
				{
					let loc = res;
					loc.forEach(group =>
					{
						group.show = true;
					});
					commit('ADD_LOCATION_DATA', loc);
					//dispatch('db/addDataLocation', null, { root: true });


					state.locations.forEach(location => {
						dispatch('booking/getEvents', location.id, { root: true });
					});
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Standorte konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});
		}

		function news()
		{
			fetch(apiBaseUrl + '/dataNews',
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object) //object
				})
				.then(data =>
				{
					if (data.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
					}
					else
					{
						return data.json();
					}
				})
				.then(res =>
				{
					if (res === "Falling")
					{
						state.showNews = null;
					}
					else
					{
						commit('ADD_NEWS_DATA', res);
					}
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Nachrichten konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});
		}

		function groups()
		{
			fetch(apiBaseUrl + '/dataGroup',
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object) //object
				})
				.then(data =>
				{
					if (data.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
					}
					else
					{
						return data.json();
					}
				})
				.then(res =>
				{
					let gr = res;
					gr.forEach(group =>
					{
						group.show = true;
					});
					commit('ADD_GROUP_DATA', gr);
					dispatch('db/addDataGroup', null, { root: true });
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Abteilungen konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});
		}

		function numbers()
		{
			fetch(apiBaseUrl + '/dataNumbers',
				{
					method: 'post',
					headers: {
						'Accept': 'text/plain',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(object) //object
				})
				.then(data =>
				{
					if (data.status === 401)
					{
						dispatch('auth/reLogin', null, { root: true });
					}
					else
					{
						return data.json();
					}
				})
				.then(res =>
				{
					commit('ADD_NUMBERS_DATA', res);
				})
				.catch(error =>
				{
					commit("snackbar/OPEN_SNACKBAR", {
						text: "Telefonnummern konnte nicht abgerufen werden",
						color: "error"
					}, { root: true });
				});
		}
	},
	//Fix, damit die Dates richtig convertiert werden
	fixUser: ({ state }) =>
	{
		state.users.forEach(user =>
		{
			user.dates = Object.values(user.dates);
		});
	}
};

const mutations = {
	ADD_USERS(state, payload)
	{
		state.users = payload;
	},
	ADD_GROUP_DATA(state, payload)
	{
		state.groups = payload;
	},
	ADD_LOCATION_DATA(state, payload)
	{
		state.locations = payload;
	},
	ADD_NEWS_DATA(state, payload)
	{
		state.news = payload;
	},
	ADD_STATUS_DATA(state, payload)
	{
		state.status = payload;
	},
	ADD_NUMBERS_DATA(state, payload)
	{
		state.numbers = payload;
	},
	ADD_GROUP(state, group) {
		state.groups.push(group);
	},
	DELETE_GROUP(state, id)
	{
		const index = state.groups.findIndex(group => group.gsid === id);
		state.groups.splice(index, 1);
	},
	SET_LOCATION_EVENTS(state, payload) {
		let location = state.locations.find(location => location.id === payload.id);

		if (!location.events) {
			Vue.set(location, 'events', []);
		}

		location.events = payload.events;
	},
	ADD_LOCATION_EVENT(state, payload) {
		const location = state.locations.find(location => location.id === payload.id);

		payload.event.start = parseInt(payload.event.start);
		payload.event.end = parseInt(payload.event.end);

		location.events.push(payload.event);
	},
	UPDATE_LOCATION_EVENT(state, payload) {
		const location = state.locations.find(location => location.id === payload.location_id);
		const event = location.events.find(e => e.gsid === payload.gsid);

		event.start = payload.start;
		event.end = payload.end;
		event.category = payload.category;
		event.tableid = payload.tableid;
	},
	DELETE_LOCATION_EVENT(state, payload) {
		const location = state.locations.find(location => location.id === payload.id);
		const index = location.events.findIndex(e => e.gsid === payload.event.gsid);
		location.events.splice(index, 1);
	},
	DELETE_PSEUDO_LOCATION_EVENT(state, payload) {
		const location = state.locations.find(location => location.id === payload.id);
		const index = location.events.findIndex(e => e === payload.event);
		location.events.splice(index, 1);
	},
	ADD_NUMBER(state, payload) {
		state.numbers.push(payload);
	},
	UPDATE_NUMBER(state, payload) {
		const number = state.numbers.find(e => e.gsid === payload.gsid);
		number.title = payload.title;
		number.status_id = payload.status_id;
		number.location_id = payload.location_id;
	},
	DELETE_NUMBER(state, payload) {
		const index = state.numbers.findIndex(number => number.gsid === payload.gsid);
		state.numbers.splice(index, 1);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
