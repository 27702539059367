export default {
	methods: {
		checkEnd(event) {
			const start = new Date(event.start).toLocaleDateString();
			const end = new Date(event.end).toLocaleDateString();

			return start !== end;
		},
		eventEnd(event) {
			return new Date(event.end).toLocaleDateString();
		},
		title(event) {
			const start = new Date(event.start);
			const startHours = (start.getHours() < 10 ? '0' : '') + start.getHours();
			const startMinutes = (start.getMinutes() < 10 ? '0' : '') + start.getMinutes();

			const end = new Date(event.end);
			const endHours = (end.getHours() < 10 ? '0' : '') + end.getHours();
			const endMinutes = (end.getMinutes() < 10 ? '0' : '') + end.getMinutes();

			return `${startHours}:${startMinutes} - ${endHours}:${endMinutes} Uhr`;
		},
		userName(event) {
			const user = this.$store.getters["dataManagement/users"].find(user => user.id === event.uid[0]);

			return this.$store.state.layout.showFullNames ? user.name + " " + user.surname : user.title
		},
		currentEvent(user) {
			const location = this.$store.getters["dataManagement/locations"].find(e => e.id === user.location[0]);
			const time = new Date().getTime();

			if (location.events) {
				return location.events.find(e => e.uid[0] === user.id && (e.start <= time && e.end >= time));
			}
			return false;
		}
	}
};
