/**
 * Module Snackbar
 */

const state = {
	display: false,
	color: '',
	text: '',
	timeout: 3000,
};

const getters = {
};

const actions = {
};

const mutations = {
	OPEN_SNACKBAR(state, payload) {
		state.text = payload.text;
		state.color = payload.color;
		state.display = true;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};